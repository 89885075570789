import { Fragenblock } from '@/models/ba/Fragenblock';
import { BestandsaufnahmeFlatJson, BestandsaufnahmeJson, HzbaStatusKey } from '@/models/ba/interfaces/IBestandsaufnahme';
import ErweitertesMedium from '@/models/erweitertes-medium.model';
import Unterschrift from '@/models/hzba/unterschrift.model';
import Localization from '@/models/localization.model';
import User from '@/models/user';
import { Model } from '@vuex-orm/core';
import { APhoto } from '../../photo/a-photo.model';
import StrapiMedia from '../../photo/strapi-media.model';

export default class BestandsaufnahmeModel extends Model implements BestandsaufnahmeFlatJson {
  /**
   * Vuex ORM entity name
   */
  static entity = 'bestandsaufnahmes';

  /**
   * Backend variable definitions
   */
  public id!: number;
  public bilder!: APhoto[];
  public malus!: string;
  public malusColor!: string;
  public kommentar!: string;
  public begehungsdatum!: string;
  public sichtungsdatum!: string;
  public berichts!: ErweitertesMedium[];
  public mangellists!: string;
  public archive!: StrapiMedia;
  public matterportLink?: string;
  public immobilie!: number;
  public status!: HzbaStatusKey;
  public unterschrifts?: Unterschrift[];
  public auftraggeber!: string;
  public technProjektsteuerungName!: string;
  public technProjektsteuerungUnterschrift!: APhoto[];
  public reihenfolge!: string;
  public freigabeDatum!: string;
  public fazit!: string;
  public createdAt!: string;
  public updatedAt!: string;
  public createdBy!: string;
  public gutachter!: any;
  public updatedBy!: User;
  public bearbeitenderNutzer?: User;
  public bearbeitendesGeraetId?: string;
  public fragenblocks?: Fragenblock[];
  public name!: Localization;
  public nameMehrzahl!: Localization;
  public nameBericht!: Localization;
  public identifier!: string;
  public surveyType!: string;
  public isLocal!: boolean; // denotes BA is changed and not synchronized with Backend
  public hasUnsavedChanges!: boolean;
  public hasLocalImages!: boolean; // denotes BA is changed and not synchronized with Backend
  public isDownloaded!: boolean; // denotes user chose BA to be downloaded locally
  public areMinimumFragenblocksSet?: boolean;
  public removedMangelsFromMigrations?: any;

  /**
   * Default values
   */
  static fields() {
    return {
      id: this.attr(null),
      bilder: this.attr(null),
      malus: this.attr(null),
      malusColor: this.attr(null),
      name: this.attr(null),
      nameMehrzahl: this.attr(null),
      nameBericht: this.attr(null),
      identifier: this.attr(null),
      kommentar: this.attr(null),
      begehungsdatum: this.attr(null),
      sichtungsdatum: this.attr(null),
      archive: this.attr(null),
      matterportLink: this.attr(null),
      status: this.attr(null),
      unterschrifts: this.attr([]),
      auftraggeber: this.attr(null),
      technProjektsteuerungName: this.attr(null),
      technProjektsteuerungUnterschrift: this.attr(null),
      reihenfolge: this.attr(null),
      freigabeDatum: this.attr(null),
      isLocal: this.attr(false),
      hasUnsavedChanges: this.attr(false),
      hasLocalImages: this.attr(false),
      isDownloaded: this.attr(false),
      gutachter: this.attr(null),
      fazit: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      createdBy: this.attr(null),
      updatedBy: this.attr(null),
      bearbeitenderNutzer: this.attr(null),
      bearbeitendesGeraetId: this.attr(null),
      berichts: this.attr(null),
      mangellists: this.attr(null),
      immobilie: this.attr(null),
      // immobilie: this.hasMany(Immobilie, "bestandsaufnahme"),
      fragenblocks: this.attr([]),
      removedMangelsFromMigrations: this.attr(null),
      surveyType: this.attr(null),
      areMinimumFragenblocksSet: this.attr(false),
    };
  }

  public copyJson(): BestandsaufnahmeJson {
    return this.$toJson() as BestandsaufnahmeJson;
  }
}

export const bestandsaufnahme = {
  state: {
    loaded: false,
    page: 0,
    pageCount: undefined,
    searchResultsCount: null as number | null,
    totalSearchResultsCount: null as number | null,
    sortBy: { label: 'Begehungsdatum', fieldName: 'begehungsdatum', orderBy: 'asc'},
    searchTerm: undefined,
    stateFilter: [],
    dateFilter: "" as string | string[],
    persistedBestandsaufnahmes: undefined,
    persistedBestandsaufnahmesLoaded: false,
  },
  // mutations defined for completeness
  // however: mutations can also be committed by BestandsaufnahmeModel.commit(state => state.example = 'value')
  mutations: {
    setLoaded (state: any) {
      state.loaded = true;
    },
    setPage (state: any, page: number) {
      state.page = page;
    },
    setPageCount (state: any, pageCount: number) {
      state.pageCount = pageCount;
    },
    setSearchResultsCount (state: any, count: number) {
      state.searchResultsCount = count;
    },
    setTotalSearchResultsCount (state: any, totalCount: number) {
      state.totalSearchResultsCount = totalCount;
    },
  },
  actions: {
    setSortBy: ({ state }: { state: any }, sortBy: string) => {
      state.sortBy = sortBy;
    },
    setSearchTerm: ({ state }: { state: any }, searchTerm: string) => {
      state.searchTerm = searchTerm;
    },
    setStateFilter: ({ state }: { state: any }, stateFilter: string) => {
      state.stateFilter = stateFilter;
    },
    setDateFilter: ({ state }: { state: any }, dateFilter: string) => {
      state.dateFilter = dateFilter;
    },

    loadPersistedBestandsaufnahmes: async ({ state }: { state: any}) => {
      const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch("$fetchFromLocal");
      return bestandsaufnahmes || [];
      // if (!state.persistedBestandsaufnahmesLoaded) {
      //   const { bestandsaufnahmes } = await BestandsaufnahmeModel.dispatch("$fetchFromLocal");
      //   console.log("loadPersistedBestandsaufnahmes", bestandsaufnahmes);
      //   // state.persistedBestandsaufnahmes = bestandsaufnahmes;
      //   state.persistedBestandsaufnahmesLoaded = true;
      //   return bestandsaufnahmes;
      // } else {
      //   return state.persistedBestandsaufnahmes || [];
      // }
    },
    // addToPersistedBestandsaufnahmes: async({ state }: { state: any}, ba: BestandsaufnahmeModel) => {
      // if (state.persistedBestandsaufnahmesLoaded) {

      //   if (!state.persistedBestandsaufnahmes) { 
      //     state.persistedBestandsaufnahmes = []
      //   }
      //   const existingPersistedBaIndex = state.persistedBestandsaufnahmes.findIndex((el: BestandsaufnahmeModel) => el.id === ba.id);

      //   if ( existingPersistedBaIndex !== -1 ) {
      //     state.persistedBestandsaufnahmes[existingPersistedBaIndex] = ba;
      //   } else {
      //     state.persistedBestandsaufnahmes.push(ba);
      //   }
      // }
    // },
    // removeFromPersistedBestandsaufnahmes: async({ state }: { state: any}, ba: BestandsaufnahmeModel) => {
      // if (state.persistedBestandsaufnahmesLoaded && state.persistedBestandsaufnahmes) {
      //   const index = state.persistedBestandsaufnahmes.findIndex((el: BestandsaufnahmeModel) => el.id === ba.id);
      //   if (index >= 0) {
      //     state.persistedBestandsaufnahmes.splice(index, 1);
      //   } else {
      //     console.warn("Could not remove property from persisted Bestandsaufnahmes, index is ", index);
      //   }
      // }
    // }
  },
  getters: {
    loaded: (state: any) => state.loaded,
    page: (state: any) => state.page,
    pageCount: (state: any) => state.pageCount,
    sortBy: (state: any) => state.sortBy,
    searchTerm: (state: any) => state.searchTerm,
    stateFilter: (state: any) => state.stateFilter,
    dateFilter: (state: any) => state.dateFilter,
    persistedBestandsaufnahmes: (state: any) => BestandsaufnahmeModel.query().where('isDownloaded', true).get(),
  },
};
