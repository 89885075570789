<template>
  <div class="geoDataListModal">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-icon
            :icon="closeOutline"
            class="mr-4 w-5 h-5 cursor-pointer"
            @click="modalController.dismiss()"
          />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light">
      <div class="geoDataListModalContainer px-6 pb-5 pt-0">
        <div class="geoDataListModalContent">
          <ion-list
            :inset="true"
            class="p-0 mt-0"
          >
            <template
              v-for="item in props.data"
              :key="item.id"
            >
              <ion-item
                class="cursor-pointer"
                @click="modalController.dismiss({ clickedFeature: item })"
              >
                <ion-label>{{ item.frages![0].eingabeText }}</ion-label>

                <ion-icon
                  v-if="!item.config?.userId || !users[item.config.userId]?.name"
                  :icon="helpOutline"
                  class="mr-4 w-5 h-5 opacity-50 rounded-full overflow-hidden bg-transparent"
                />

                <ion-img
                  v-else
                  :src="item.config?.userId ? users[item.config.userId]?.picture : avatarPlaceholderUrl"
                  :alt="$t('einstellungen.profilbild')"
                  class="mr-2 w-8 h-8 rounded-full overflow-hidden bg-transparent"
                />

                <ion-label v-if="item.config?.userId && users[item.config.userId]?.name">
                  {{ users[item.config.userId].name }}
                </ion-label>
                <ion-label
                  v-else
                  class="opacity-75"
                >
                  {{ $t("hzba.geoDataList.unknownUser") }}
                </ion-label>

                <!-- Date display for very small screens -->
                <span
                  v-if="isMaxXSmall"
                  class="flex flex-col items-center"
                >
                  <span class="text-xs">{{ moment(item.createdAt).format("DD.MM.YY") }}</span>
                  <span class="text-xs">{{ moment(item.createdAt).format("HH:mm") }}</span>
                </span>

                <!-- Date display for medium and large screens -->
                <span
                  v-else-if="isMinMedium"
                  class="flex flex-row items-center w-44"
                >
                  <ion-icon
                    :icon="calendarClearOutline"
                    class="mr-1"
                    :title="$t('hzba.geoDataList.createdAt')"
                  />
                  {{ moment(item.createdAt).format("DD.MM.YYYY") }}

                  <ion-icon
                    :icon="timeOutline"
                    class="ml-1 mr-0.5"
                  />
                  {{ moment(item.createdAt).format("HH:mm") }}
                </span>

                <!-- Date display for small screens -->
                <span
                  v-else
                  class="flex flex-col mr-2"
                >
                  <span class="flex items-center">
                    <ion-icon
                      :icon="calendarClearOutline"
                      class="mr-1"
                      :title="$t('hzba.geoDataList.createdAt')"
                    />
                    {{ moment(item.createdAt).format("DD.MM.YY") }}
                  </span>
                  <span class="flex items-center">
                    <ion-icon
                      :icon="timeOutline"
                      class="ml-2 mr-1"
                    />
                    {{ moment(item.createdAt).format("HH:mm") }}
                  </span>
                </span>

                <ion-button
                  v-if="user?.id && item.config?.userId === user.id"
                  class="ml-2"
                >
                  <ion-icon
                    slot="icon-only"
                    class="w-4 h-4"
                    :icon="createOutline"
                  />
                </ion-button>

                <ion-button
                  v-else
                  class="ml-2"
                >
                  <ion-icon
                    slot="icon-only"
                    class="w-4 h-4"
                    :icon="eyeOutline"
                  />
                </ion-button>
              </ion-item>
            </template>
          </ion-list>
        </div>
      </div>
    </ion-content>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { strapiUrl } from '@/api/api';
import useScreenSize from '@/composables/useScreenSize';
import { useStore } from "@/composables/useTypedStore";
import useUser from '@/composables/useUser';
import { Fragenblock } from '@/models/ba/Fragenblock';
import StrapiMedia from '@/models/photo/strapi-media.model';
import User from '@/models/user';
import { IonContent, IonIcon, IonImg, IonItem, IonLabel, IonList, modalController } from '@ionic/vue';
import {
  calendarClearOutline,
  createOutline,
  eyeOutline,
  timeOutline,
  closeOutline,
  helpOutline
} from "ionicons/icons";
import moment from "moment";
import QueryString from 'qs';
import { computed, onMounted, PropType, reactive, watch } from 'vue';

const props = defineProps({
  data: {
    type: Array as PropType<Array<Fragenblock>>,
    default: () => [],
    required: false
  }
});

const { user } = useUser();
const { isMinMedium, isMaxXSmall } = useScreenSize();
const store = useStore();

const avatarPlaceholderUrl = "./assets/img/profiles/placeholder_profile_image.png";

const users = reactive<Record<number, any>>({});

const isOnline = computed(() => {
  return store.getters["app/isOnline"];
});

const fetchUsers = async () => {
  const query = {
    filters: {
      $or: props.data
        .filter((item: Fragenblock) => item.config?.userId)
        .map((item: Fragenblock) => {
          return { id: { $eq: item.config.userId } };
        })
    }
  };

  const response = (await User.api().get(`/users/?${QueryString.stringify(query)}`, { save: false })).response;
  if (response?.data) {
    response.data.forEach((fetchedUser: User) => {
      const profilePhoto = (fetchedUser?.profilbild as StrapiMedia)?.formats?.thumbnail?.url ?? null;
      const isSameOrganisation = fetchedUser.organisation?.id === user.value?.organisation?.id;
      users[fetchedUser.id] = {
        name: fetchedUser?.username && isSameOrganisation ? fetchedUser.username : null,
        picture: profilePhoto && isSameOrganisation ? `${strapiUrl}${profilePhoto}` : avatarPlaceholderUrl
      };
    });
  }
};

const addCurrentUserInfo = () => {
  if (user.value) {
    const profilePhoto = (user.value.profilbild as StrapiMedia)?.formats?.thumbnail?.url ?? null;
    users[user.value.id] = {
      name: user.value.username ?? null,
      picture: user.value.profilbild ? `${strapiUrl}${profilePhoto}` : avatarPlaceholderUrl
    };
  }
};

watch(isOnline, async (value) => {
  if (value) {
    fetchUsers();
  }
});

onMounted(() => {
  if (isOnline.value) {
    fetchUsers();
  } else {
    addCurrentUserInfo();
  }
});

</script>

<style
  scoped
  lang="scss"
>
.geoDataListModal {
  height: 100%;

  .geoDataListModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;
    height: 100%;
  }

  .header-md::after {
    position: relative; /* "after" creates box with box-shadow, position:relative removes it */
  }

  ion-content {
    --offset-bottom: -56px !important; /* 56px is the height of the toolbar */
  }
}

@media (max-width: 450px) {
  ion-label {
    font-size: 0.75rem !important;
    line-height: 1rem !important;
  }
}

ion-button {
  --background: transparent;
  --border-radius: 9999px;
  --box-shadow: 0 0 3px #ccc;
}
</style>