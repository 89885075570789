import { Mangelzuordnung } from '@/models/ba/Mangelzuordnung';
import { ExtendedMSERow } from '@/models/ba/interfaces/IGenericPdfDataRow';

export class MaengelReport {
  maengel: Mangelzuordnung[];

  constructor(maengel: Mangelzuordnung[]) {
    this.maengel = maengel;
  }

  public getCalculatedMalus() {
    let add = 0;
    this.maengel.forEach((el) => {
      add += el.maluspunkte();
    });
    return add;
  }

  public getCalculatedRelevantMalus() {
    let add = 0;
    this.maengel.forEach(el => {
      if (el.eingabeRelevant) {
        add += el.maluspunkte();
      }
    });
    return add;
  }

  public getCalculatedNotRelevantMalus() {
    let add = 0;
    this.maengel.forEach(el => {
      if (!el.eingabeRelevant) {
        add += el.maluspunkte();
      }
    });
    return add;

  }

  public getCalculatedMalusByProduktTyp(produktTyp: string | undefined) {
    let add = 0;
    this.maengel.forEach((el) => {
      el.mangelvorlage?.produktTyp === produktTyp ? (add += el.maluspunkte()) : (add += 0);
      if (el.isCustom()) {
        el.custom?.produktTyp === produktTyp ? (add += el.maluspunkte()) : (add += 0);
      }
    });
    return add;
  }

  public getColorByProduktTyp(produktTyp: string | undefined) {
    const isVersorungsgefaehrdend = !!this.maengel.find((mangel) => {
      if (mangel.isCustom()) {
        return mangel.custom?.produktTyp === produktTyp && mangel.getColor() === 'yellow';
      } else return mangel.mangelvorlage?.produktTyp === produktTyp && mangel.getColor() === 'yellow';
    });
    const isSicherheitsrelevant = !!this.maengel.find((mangel) => {
      if (mangel.isCustom()) {
        return mangel.custom?.produktTyp === produktTyp && mangel.getColor() === 'red';
      } else return mangel.mangelvorlage?.produktTyp === produktTyp && mangel.getColor() === 'red';
    });

    const otherMangels = !!this.maengel.find((mangel) => {
      if (mangel.isCustom()) {
        return mangel.custom?.produktTyp === produktTyp && mangel.getColor() === 'blue';
      }
      return mangel.mangelvorlage?.produktTyp === produktTyp && mangel.getColor() === 'blue';
    });

    if (isVersorungsgefaehrdend) {
      return 'yellow';
    }
    if (isSicherheitsrelevant) {
      return 'red';
    }
    if (otherMangels) {
      return 'blue';
    }
    return 'green';
  }

  public countedMaengel() {
    return this.maengel.length;
  }

  /**
   * Based on "What is the worst mangel within current maengels?
   * @param mergedMaengel
   */
  public getColorByMaengel() {
    const isSicherheitsrelevant = !!this.maengel.find((mangel) => {
      return mangel.getColor() === 'red';
    });
    const isVersorungsgefaehrdend = !!this.maengel.find((mangel) => {
      return mangel.getColor() === 'yellow';
    });
    if (isSicherheitsrelevant) {
      return 'red';
    }
    if (isVersorungsgefaehrdend) {
      return 'yellow';
    }
    if (this.maengel.length > 0) {
      return 'blue';
    }
    return 'green';
  }

  public getWorstCategory() {
    const isSicherheitsrelevant = !!this.maengel.find((mangel) => {
      return mangel.isSicherheitsrelevant();
    });
    const isVersorungsgefaehrdend = !!this.maengel.find((mangel) => {
      return mangel.isVersorgungsgefaehrdend();
    });
    if (isSicherheitsrelevant) {
      return Mangelzuordnung.getSicherheitsrelevantLabel();
    }
    if (isVersorungsgefaehrdend) {
      return Mangelzuordnung.getVersorgungsgefaehrdendLabel();
    }
    if (this.maengel.length > 0) {
      return Mangelzuordnung.getSonstigerLabel();
    }
    return '';
  }

  public getMSESummary(): ExtendedMSERow {
    return {
      malus: {
        value: this.getCalculatedMalus(),
        color: this.getColorByMaengel(),
      },
      hinweis: {
        value: this.getCalculatedMalusByProduktTyp('HINWEIS'),
        color: this.getColorByProduktTyp('HINWEIS'),
      },
      safety: {
        value: this.getCalculatedMalusByProduktTyp('SAFETY'),
        color: this.getColorByProduktTyp('SAFETY'),
      },
      efficiency: {
        value: this.getCalculatedMalusByProduktTyp('EFFICIENCY'),
        color: this.getColorByProduktTyp('EFFICIENCY'),
      },
      description1: {
        value: this.getWorstCategory(),
        color: this.getColorByMaengel(),
      },
    };
  }

  // // TODO use case...? (legacy code)
  // public getMaengelDescriptions() {
  //
  //     const detectedDescriptions: string[] = [];
  //
  //     this.maengel.forEach(maengel => {
  //         const description = maengel.titel();
  //
  //         if (!description) { return; }
  //
  //         if (detectedDescriptions.indexOf(description) === -1) {
  //             detectedDescriptions.push(description)
  //         }
  //     })
  //
  //     // console.log("CATEGORIES", detectedDescriptions, mergedMaengel)
  //
  //     return {
  //         value: detectedDescriptions.toString(),
  //         color: this.getColorByMaengel(),
  //     }
  // }

  // Todo reactivate this legacy code if necessary
  public getMaengelCategories() {
    const detectedCategories: string[] = [];

    this.maengel.forEach((mangel) => {
      const category = mangel.kategorie();

      if (!category) {
        return;
      }

      if (detectedCategories.indexOf(category) === -1) {
        // TODO how to use enums here? MangelKategorie[category] does not work
        detectedCategories.push(category);
      }
    });

    // console.log('CATEGORIES', detectedCategories, this.maengel);

    return {
      value: detectedCategories.toString(),
      color: this.getColorByMaengel(),
    };
  }

  public getMaengelDescription() {
    // console.log(this.maengel);
    return {
      value: this.getWorstCategory(),
      color: this.getColorByMaengel(),
    };
  }
}
