
export interface AnzeigeLogik {
    id: number;
    updatedAt: string;
    createdAt: string;
    pfad: string;
    wert: boolean;
}


export interface ToJsonSettings {
    cleanup?: boolean;
    prepareForSync?: boolean;
}


export interface BaNode {
    toClassJson: Function
}


export interface BaNodeFrageAndFragenblock extends BaNode {

    setShowErrors: Function;

    // isInputValid: Function;

}



export const sortArrayPosition = (a: any, b: any) => {
    // setting -Infinity explicitly because Chrome/Safari and Firefox treat null/0 differently in sort algorithm
    const aPos = !a.arrayPosition ? -Infinity : a.arrayPosition;
    const bPos = !b.arrayPosition ? -Infinity : b.arrayPosition;
    return aPos - bPos;
}