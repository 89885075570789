import { Monitoring } from "@/utilities/monitoring";
import {Func} from "mocha";


// https://stackoverflow.com/questions/68096516/how-to-make-a-gui-to-visually-add-mattertags-into-a-matterport-scene


interface MatterportTag {
    sid: string;
    label: string;
    description: string;
    anchorPosition: any;
    stemVector: any;
    color: any;
    media: any;
}

const sdkKey = "425c955302e74a2680d131bd5293b617"

export default function useMatterportTagManagement(modelSid: string) {

    let iframe: any;
    let mpSdk: any;

    let currentMatterportTag: MatterportTag | undefined = undefined;



    /*
     * Load Matterport Script
     */
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://static.matterport.com/showcase-sdk/latest.js')
    document.head.appendChild(recaptchaScript)


    /**
     * Load Matterport with data
     */
    function setupTags(tags: MatterportTag[], sidCallback?: Function) {
        mpSdk.Mattertag.add(tags).then((sids: any) => {
            sidCallback && sidCallback(sids)
        })
    }

    /**
     * This is called once when triggering the process of adding a tag.
     */
    function startAddTag(options: any) {
        const opt = {
            label: options.label,
            description: options.description,
            media: options.media,
            anchorPosition: {x: 0, y: 0, z: 0},
            stemVector: {x:0, y: 0, z: 0},
            color: {r: 1, g: 0, b: 0},
        }

        if(!currentMatterportTag){
            mpSdk.Mattertag.add([opt])
                .then((sid: any) => {
                    currentMatterportTag = {
                        ...opt,
                        sid: sid[0]
                    };
                })
                .catch((e: any) => {
                    Monitoring.chainError("Error in startAddTag", e);
                });
        }
    }

    /**
     * This function is called while adding a Tag and moving around with the cursor.
     */
    function updateTagPos(newPos: any, newNorm: any = undefined, scale: any = undefined) {
        if (!newPos) return;
        if (!scale) scale = .33;
        if (!newNorm) newNorm = {x: 0, y: 1, z: 0};

        mpSdk.Mattertag.editPosition(currentMatterportTag?.sid, {
            anchorPosition: newPos,
            stemVector: {
                x: scale * newNorm.x,
                y: scale * newNorm.y,
                z: scale * newNorm.z,
            }
        }).then((res: any) => {
            if (!currentMatterportTag) {
                Monitoring.error('updateTagPos: currentMatterportTag is undefined.')
                return;
            }

            currentMatterportTag.anchorPosition = newPos;
            currentMatterportTag.stemVector = {
                x: scale * newNorm.x,
                y: scale * newNorm.y,
                z: scale * newNorm.z,
            }
        }).catch((e: any) => {
            Monitoring.chainError("Error in updateTagPos", e);
        });
    }

    /**
     * onTagSetListener is called when setting a Tag is submitted
     */
    let onTagSetListener: Function;
    const setTagSetListener = (fctn: Function) => {onTagSetListener = fctn;}

    function placeTag() {
        // if (tag) mpSdk.Mattertag.navigateToTag(tag, mpSdk.Mattertag.Transition.INSTANT);
        if (!currentMatterportTag) return;

        onTagSetListener && onTagSetListener(currentMatterportTag);
        currentMatterportTag = undefined;
    }

    function loadedShowcaseHandler() {
        window.addEventListener('blur', function () {
            window.setTimeout(function () {
                if (document.activeElement === iframe) {
                    placeTag();
                    window.focus()
                }
            }, 0);
        });
    }


    function removeTag(tag: MatterportTag) {
        mpSdk.Mattertag.remove(tag);
    }

    const mountMe = (onReadyHook?: Function) => {
        // Iframe & Matterport setup
        iframe = document.querySelector('#showcase');
        iframe?.setAttribute('src', `https://my.matterport.com/show/?m=${modelSid}&help=0&play=1&qs=1&gt=0&hr=0`);
        iframe?.addEventListener('load', () => {
            try {
                // @ts-ignore
                window.MP_SDK.connect(iframe, sdkKey, '3.10')
                    .then((sdk: any) => {
                        mpSdk = sdk;
                        loadedShowcaseHandler()

                        mpSdk.Pointer.intersection.subscribe((intersectionData: any) => {
                            if (currentMatterportTag) {
                                if (intersectionData.object === 'intersectedobject.model' || intersectionData.object === 'intersectedobject.sweep') {
                                    updateTagPos(intersectionData.position, intersectionData.normal);
                                }
                            }
                        });

                        onReadyHook && onReadyHook()

                    })
                    .catch((e: any) => {
                        Monitoring.chainError("Error in mountMe window.MP_SDK.connect", e);
                    });
            } catch (e) {
                Monitoring.chainError("Error in mountMe", e);
            }
        });

    }

    return {
        mountMe,
        startAddTag,
        setTagSetListener,
        setupTags,
        removeTag,
    }
}