const placeholder = "[Filtered]";
const sensitive = ["password", "secret", "api_key", "token"];

export const scrub = (data: string | any) => {
    if (typeof data !== "string") {
        return data;
    }
    const scrubbed = data.substring(0, data.indexOf("api_key"));
    return scrubbed.replace(/(password|secret|token)/gi, placeholder);
};

export const scrubSensitiveFields = (data: { [key: string]: string }): { [key: string]: string } => {
    Object.keys(data).forEach((key) => {
        const value = data[key];
        if (sensitive.includes(key.toLowerCase())) {
            data[key] = placeholder;
        } else if(typeof value === "string" && sensitive.some(keyword => value.toLowerCase().includes(keyword))) {
            data[key] = scrub(value);
        }
    });
    return data;
}

