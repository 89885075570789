<template>
  <ion-item
    lines="none"
    data-cy="base-input-item"
  >
    <ion-grid
      class="p-0"
      :class="separator ? 'border-bottom-1-light' : ''"
    >
      <ion-row
        class="ion-wrap d-flex items-center mb-1 px-0"
        :data-cy="`input-row-${label && label.replace(/\s+/g, '-').toLowerCase()}`"
      >
        <!-- <input-label class="flex-grow-10">
          {{ label }}
        </input-label> -->
        <ion-col class="md">
          <p class="flex-1">
            {{ label }}
          </p>
        </ion-col>


        <ion-col
          class="ion-float-right p-0 pl-0 flex justify-end"
        >
          <ion-input
            v-if="!type || (type.toLowerCase() !== 'date' && type.toLowerCase() !== 'time')"
            ref="templateRefInputField"
            :key="key"
            :value="newlyEnteredValue"
            data-cy="base-input"
            :placeholder="placeholder ? placeholder : (type === 'number' ? '0' : t('hzba.wertPlatzhalter'))"
            class="inline-block text-right"
            mode="md"
            :class="[
              flatMode ? 'flat' : '',
              type === 'integer' || type === 'number' ? 'number-input' : '',
            ]"
            style="min-width: 80px"
            :type="inputType"
            :inputmode="inputMode"
            :pattern="type === 'integer' ? '[0-9]*' : ''"
            :disabled="readonly"
            :maxlength="limit"
            :min="min"
            autocomplete="off"
            @ionBlur="onBlur"
            @input="onChanged"
            @keypress="bouncer"
          />
          <ion-input
            v-else
            :type="type.toLowerCase() === 'date' ? 'date' : 'time'"
            class="inline-block text-right border-none"
            :class="[ flatMode ? 'flat' : '', !modelValue ? 'empty-content' : '' ]"
            style="padding-bottom: 8px; padding-top: 8px; min-width: 80px"
            :value="modelValue"
            :placeholder="type.toLowerCase() === 'date' ? 'TT.MM.YYYY' : 'HH:MM'"
            :disabled="readonly"
            @ionBlur="onBlur"
            @input="(e) => onChanged(e)"
            @keypress="bouncer"
          />
        </ion-col>
        <unit-chip
          v-if="unit"
          :show-unit="true"
          :unit="unit"
          style="text-align: right"
        />
      </ion-row>

      <ion-row
        v-if="dependentReason"
        class="ion-wrap d-flex items-center"
      >
        <ion-col>
          <ion-text class="text-gray-600 text-sm">
            {{ dependentReason }}
          </ion-text>
        </ion-col>
      </ion-row>

      <input-error :errors="errors" />
    </ion-grid>
  </ion-item>
</template>

<script lang="ts">
import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText } from "@ionic/vue";
import { computed, ref, watch } from "vue";
import { defineComponent } from "vue-demi";
import { useI18n } from 'vue-i18n';
//Components
import InputError from "@/components/hzba/Base/Input/InputElements/InputError";
import InputLabel from "@/components/hzba/Base/Input/InputElements/InputLabel";
import UnitChip from "@/components/hzba/Base/Input/InputElements/UnitChip";
//Helpers
import _ from 'lodash';
import { Monitoring } from "@/utilities/monitoring";

export default defineComponent({
  name: "HzbaBaseInput",
  components: {
    InputError,
    InputLabel,
    UnitChip,
    IonItem,
    IonCol,
    IonRow,
    IonGrid,
    IonText,
    IonInput,
    IonLabel
  },
  props: {
    modelValue: { type: [String, Number, Array], default: '' },
    label: { type: String, required: true },
    placeholder: { type: [String, Number], default: '' },
    errors: { type: Array, default: () => [] },
    hideUnitSpace: { type: Boolean, default: false },
    unit: { type: String, default: '' },
    type: { type: String, default: 'text' },
    readonly: { type: Boolean, default: false },
    limit: { type: Number, default: undefined },
    min: { type: Number, default: undefined },
    dependentReason: { type: String, default: undefined },
    flatMode: { type: Boolean, default: false },
    separator: { type: Boolean, default: false }
  },
  emits: ['update:modelValue', 'change', 'updateValue', 'updateSocket'],
  setup(props, { emit }) {
    const key = ref(0);
    const { t, locale } = useI18n({ useScope: 'global' });
    const templateRefInputField = ref();
    
    const inputType = computed(() => props.type === "integer" ? "number" : props.type === "number" ? "text" : props.type); //TODO: resolve types of props.type
    const inputMode = computed(() => props.type === "integer" ? "numeric" : props.type === "number" ? "decimal" : "text");

    const formatNumberValue = (val: string | number | null | undefined) => {
      if (!val) {
        return "0";
      }
      return locale.value === "de" ? val.toString().replace(".", ",") : val.toString().replace(",", ".");
    }

    // formatNumberValue in case of props.type === number (which is a decimal number)
    const newlyEnteredValue = ref(props.type === "number" ? formatNumberValue(props.modelValue?.toString()): props.modelValue);
    watch(() => props.modelValue, (val) => newlyEnteredValue.value = props.type === "number" ? formatNumberValue(val?.toString()) : val);

    // TODO: make more general? if 4 decimal numbers are the goal this would work too: Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
    const round = (val: string) => {
      return Math.round(Number.parseFloat(val)*10000) / 10000;
    }

   // emit events and make sure UI value is correct after potential custom formattings
   const updateValue = () => {
      const val = newlyEnteredValue.value;
      const decimalSeparator = locale.value === "de" ? "," : ".";
      // only replace value if it has more than 4 decimal numbers, otherwise input value and formatted value are the same
      if (templateRefInputField.value?.$el?.value && new RegExp(`\\${decimalSeparator}\\d{5,}`).test(templateRefInputField.value.$el.value)) {
          templateRefInputField.value.$el.value = val;
      }
      emit('update:modelValue', val);
      emit('updateValue', val);  // For some reason I have to emit a new event instead of using change because change is emitted before the if statement already
    };

    const changeValue = (e: any) => {
      const startLength = e.target.value.length;

      if (startLength !== e.target.value.length) {
        key.value ++;
      }

      let val = e.target.value;
      try {
        if (props.type === 'integer') {
          val = Number.parseInt(val);
        }
        if (props.type === 'number' && val.length > 0) {
          val = formatNumberValue(round(val.replace(",",".")));
        }
      } catch (err: any) {
        Monitoring.withScope((scope) => {
          scope.setContext("Input info", { label: props.label, value: val });
          Monitoring.chainError("Error parsing number in BaseInput", err);
        });
      }


      if (!e.target.value || !props.modelValue || (props.modelValue && (e.target.value.toString() !== props.modelValue.toString()))) {
        newlyEnteredValue.value = val ? val.toString() : val;
        updateValue();
       }
    }

    const onChanged = _.debounce((e: any) => {
      changeValue(e);
    }, 600)

    function onBlur(e: any) {
      changeValue(e);
      emit('updateSocket')
    }

    const bouncer = (e: any) => {
      const rightSeparator = locale.value === "de" ? "," : ".";
      const wrongSeparator = locale.value === "de" ? "." : ",";
      const hasMultipleSeparators = templateRefInputField.value?.$el?.value?.includes(rightSeparator) && e.key === rightSeparator;
      const isWrongSeparator = props.type === 'number' && e.key === wrongSeparator;
      const isNegativeNumber = props.min && props.min >= 0 && e.key === '-'

      if (hasMultipleSeparators || isWrongSeparator || isNegativeNumber) {
        e.preventDefault();
      } else {
        return true;
      }
    }

    return {
      onChanged,
      key,
      onBlur,
      bouncer,
      t,
      templateRefInputField,
      newlyEnteredValue,
      inputMode,
      inputType
    }
  }
})
</script>

<style scoped lang="scss">

ion-label {
  max-width: unset !important;
}

ion-input {
  float: right;
  max-width: 330px;
  //background: $grey-200;
  padding: 4px !important;
  padding-right: 8px !important;
  padding-left: 16px !important;
}

.flat {
  background: none;
}

.error-text {
  color: var(--error100);
}

.info-text {
  color: rgb(82, 81, 81);
  font-size: 14px;
}

.number-input {
  //margin-right: -16px; // Two arrows (lower and upper) will appear if input field is type of number
}
</style>

<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>