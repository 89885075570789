<template>
  <hr class="trenner-l"/>
  <div class="text-xs pageBottom" data-cy="pdf-page-bottom" style="margin-bottom: 2.5px">
    Erstellt am {{ new Date().toLocaleDateString('de-DE') }} mit der MoversSurvey - APP
  </div>
</template>
<script lang="ts">
  import { useI18n } from "vue-i18n";
  import moment from "moment";
  import { computed, defineComponent, PropType } from "vue";
      
  export default defineComponent({
    name: "PageBottom",
    props: {
      lang: {
        type: String,
        default: "de",
      },
    },
    setup(props) {
      const { t } = useI18n({ useScope: "global" });    
      return {
        moment,
        t,
      };
    },
  });
</script>
    
<style scoped lang="scss">
   * {
    color: black;
    background-color: white;
    text-align: center;
  }
  .pageBottom {
    margin-top: 5px;
    margin-bottom: -20px;
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 50px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;  
    width: 740px;
  }
</style>