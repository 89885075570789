<template>
  <div v-if="loginDone">
    <iframe
      :src="urlWithToken"
      frameborder="0"
      :width="width"
      :height="height"
      allow="autoplay; camera; microphone"
    />
  </div>
</template>
<script lang="ts" setup>
import apiClient from "@/api/api";
import useUser from "@/composables/useUser";
import { defineProps, ref } from 'vue';

const props = defineProps({
    width: {
        type: String,
        required: false,
        default: '100%'
    },
    height: {
        type: String,
        required: false,
        default: '500'
    },
    rocketChatUrl: {
        type: String,
        required: true,
    }
});
const user = useUser().user;
const loginDone = ref(false);
const urlWithToken= ref("");
const authToken = ref("");

apiClient.post( `/organisations/log-in-to-chat/?userId=${user?.value?.id}`).then((response: any) => {   
    if (response.data.authToken) {
        authToken.value = response.data.authToken;
        urlWithToken.value = `${props.rocketChatUrl}&resumeToken=${authToken.value}`;
        loginDone.value = true;
    }
}).catch((error: any ) => {
    console.log("login to rocketchat error", error);
    loginDone.value = false;
});

</script>
<style>
</style>