import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e286289"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-1 w-full" }
const _hoisted_2 = { class: "text-left pl-2" }
const _hoisted_3 = { class: "text-left pl-2" }
const _hoisted_4 = { class: "text-center pl-2" }
const _hoisted_5 = { class: "text-center pl-2" }
const _hoisted_6 = { class: "text-center pl-2" }
const _hoisted_7 = { class: "text-center pl-2" }
const _hoisted_8 = { style: {"min-width":"120px","max-width":"200px"} }
const _hoisted_9 = { class: "text-left" }
const _hoisted_10 = { class: "text-center" }
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = { class: "text-center" }
const _hoisted_13 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PdfChip = _resolveComponent("PdfChip")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createElementVNode("tr", null, [
      _createElementVNode("th", _hoisted_2, _toDisplayString(_ctx.t("immobilie.pdf.mangel", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_3, _toDisplayString(_ctx.t("immobilie.pdf.mangelKategorie", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_4, _toDisplayString(_ctx.t("immobilie.pdf.malus", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.t("immobilie.pdf.status", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.t("immobilie.pdf.gewerk", 1, { locale: _ctx.lang})), 1),
      _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.t("immobilie.pdf.kostenprognose", 1, { locale: _ctx.lang})), 1)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dummyData.maengel, (entry, key) => {
      return (_openBlock(), _createElementBlock("tr", {
        key: key,
        class: "data-tr items-center"
      }, [
        _createElementVNode("td", _hoisted_8, _toDisplayString(entry.titel), 1),
        _createElementVNode("td", _hoisted_9, _toDisplayString(entry.kategorie), 1),
        _createElementVNode("td", _hoisted_10, [
          _createVNode(_component_PdfChip, {
            value: entry.malus,
            color: "yellow",
            "pdf-mode": ""
          }, null, 8, ["value"])
        ]),
        _createElementVNode("td", _hoisted_11, _toDisplayString(entry.status), 1),
        _createElementVNode("td", _hoisted_12, _toDisplayString(entry.gewerk), 1),
        _createElementVNode("td", _hoisted_13, _toDisplayString(entry.kostenprognose) + " € ", 1)
      ]))
    }), 128))
  ]))
}