<template>
  <ion-content>
    <div class="documentItemModal p-8 flex flex-col justify-between">
      <div class="documentItemModalContent">
        <div class="sectionItem mb-4">
          <div class="label">
            Name
          </div>
          <div class="value">
            <AppInput
              v-model="data.name"
              :placeholder="'Type...'"
            />
          </div>
        </div>
        <div class="sectionItem mb-4">
          <div class="label">
            Description
          </div>
          <div class="value">
            <AppInput
              v-model="data.description"
              :placeholder="'Type...'"
            />
          </div>
        </div>
      </div>
      <div class="buttonPart flex flex-row gap-4">
        <AButton
          class="p-2 grow"
          :btn-secondary="true"
          @click="deleteDocument"
        >
          Delete
        </AButton>
        <AButton
          class="p-2 grow"
          :btn-primary="true"
          @click="uploadDocument"
        >
          Upload
        </AButton>
      </div>
    </div>
  </ion-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { IonContent, modalController } from '@ionic/vue';
//Components
import AppInput from '@/components/v2/App/AppInput.vue';
import AButton from "@/components/Base/AButton";

export default defineComponent({
  name: 'DocumentItemModal',
  components: {
    IonContent,
    AppInput,
    AButton
  },
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: {
        name: '',
        description: ''
      }
    }
  },
  created() {
    this.data.name = this.file.name
  },
  methods: {
    uploadDocument() {
      modalController.dismiss({
        data: {
          response: 'upload',
          file: this.file,
          details: this.data
        }
      })
    },
    
    deleteDocument() {
      modalController.dismiss('delete')
    }
  }
})
</script>

<style lang="scss">
.documentItemModal {
  height: 100%;
  background: var(--white100);

  .documentItemModalContent {
    .sectionItem {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
  
      .label, .value {
        width: calc(50% - 8px);
      }
  
      .value {
        background: var(--background100);
      }
    }
  }

  .buttonPart {
    button {
      flex-grow: 1;
    }
  }
}
</style>