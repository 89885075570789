<template>
  <div class="documentItem flex flex-col">
    <div>
      <AImage
        v-if="getType(document.dokument) === 'photo'"
        :src="getPhotoUrl(document.dokument)"
        :image-class="'photo object-contain rounded-lg w-full h-200'"
        @click="() => openBaseModal('gallery', { images: [document.dokument], title: getLocalization(document.name) })"
      />
      <APdfThumbnail
        v-if="getType(document.dokument) === 'pdf'"
        :id="`${document.name}_${document.id}`"
        :url="getPdfUrl(document.dokument)"
        :file="document"
        :image-class="'photo object-contain rounded-lg w-full h-200'"
        @click="openPdfViewerModal(document.dokument)"
      />
    </div>
    <div class="text-center pb-4">
      {{ getLocalization(document.name) }}
    </div>
    <div class="text-center pb-4">
      {{ getLocalization(document.beschreibung) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
//Other
import AImage from "@/components/Base/AImage";
import getLocalization from "@/utilities/get-localization";
import APdfThumbnail from "@/components/Base/APdfThumbnail";
import { getPdfUrl, getPhotoUrl } from '@/utilities/get-media-url';
import { openBaseModal } from "@/utilities/modal-helper";
import { openPdfViewerModal } from '@/utilities/pdf-viewer';

export default defineComponent({
  name: 'DocumentItem',
  components: {
    APdfThumbnail,
    AImage
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  setup() {
    const getType = (d: any) => {
      if (!d) {
        return undefined;
      }

      if (d.ext === '.pdf' || d.ext === '.PDF') {
        return 'pdf';
      }

      if (d.ext === 'iframe') {
        return 'iframe-url'
      }

      return 'photo';
    }

    return {
      getLocalization,
      getPdfUrl,
      getPhotoUrl,
      openBaseModal,
      getType,
      openPdfViewerModal
    }
  }
})
</script>

<style lang="scss">
.h-200 {
  height: 200px;
}
</style>
