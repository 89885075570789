import useAuth from "@/composables/useAuth";
import { useStore } from "@/composables/useTypedStore";
import axios from "axios";
import { environment } from "../../environments/environment";
import { Monitoring } from "@/utilities/monitoring";
export const strapiUrl = `${environment.BACKEND_URL}:${environment.BACKEND_PORT}`;
export const strapiApiUrl = `${strapiUrl}/api`;
export const strapiSocketUrl = `${environment.BACKEND_URL}:${environment.SOCKET_PORT}`;

export const rocketChatUrl = "https://movinglayers.echo-dc.eu";
export const rocketChatApiUrl = "https://movinglayers.echo-dc.eu/api/v1";

export const createNewAxios = ( url: string) => {
    return axios.create({
        baseURL: url,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        timeout: 0,
    });
}

const apiClient = createNewAxios( strapiApiUrl );

apiClient.interceptors.response.use(
    function (response) {
        return response;
    },

    function (error: any) {
        Monitoring.chainError("#error response", error);

        if (!error.response) {
            error.status = 'Network Error';
        }

        throw error;
    }
);

apiClient.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");

    if (token && useAuth().isTokenExpired(token)) {
        localStorage.removeItem("token")
    }

    if (token && token !== "undefined") {   // Token is sometimes returned as "undefined" which leads to errors
        config.headers.Authorization = `Bearer ${token}`;
    }
    
    if (config.method === 'get') {
        const store = useStore();
        config.params = config.params || {};
        config.params['projectId'] = store.state.user.currentUserProject.id
    }
    return config;
})


export const vuexORMAxiosSettings = () => {
    return {
        axios,
        persistBy: 'insert',
        headers: {},
        baseURL: `${environment.BACKEND_URL}:${environment.BACKEND_PORT}/api`,
        timeout: 0,
    }
};

axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

export const rocketChatClient = createNewAxios( rocketChatApiUrl );

export default apiClient;
