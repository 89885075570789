import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aa58825"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "filter-container block mt-2 pb-4",
  style: {"overflow-x":"auto"}
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_range_picker = _resolveComponent("date-range-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_date_range_picker, {
      class: "pill inline-block",
      start: $setup.startDate,
      end: $setup.endDate,
      "onUpdate:range": _cache[0] || (_cache[0] = ($event: any) => ($setup.dateFilterAdded($event))),
      "onUpdate:single": _cache[1] || (_cache[1] = ($event: any) => ($setup.dateFilterAdded($event)))
    }, null, 8, ["start", "end"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filterOptions, (option) => {
      return (_openBlock(), _createElementBlock("div", {
        key: option.id,
        class: "inline-block mb-1"
      }, [
        (!option.onlyShowWhenOthersActive || $props.selectedFilters.filter(el => el.id !== option.id).length > 0)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["pill", $props.selectedFilters && $props.selectedFilters.find(el => el.id === option.id) ? 'active' : (option.onlyShowWhenOthersActive?'resetter':'')]),
              onClick: () => $setup.toggleOption(option)
            }, _toDisplayString($setup.translatedHzbaStatus(option.id,$setup.t)), 11, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}