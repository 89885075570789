<template>
  <span class="flex flex-col -mt-1">
    <span>
      <ion-datetime-button
        :datetime="uuid"
        class="inline-flex"
      />
      <ion-popover :keep-contents-mounted="true">
        <ion-datetime
          :id="uuid"
          :done-text="$t('hzba.buttons.select')"
          :cancel-text="$t('hzba.buttons.abbrechen')"
          :value="modelValue"
          :show-default-buttons="true"
          :locale="locale"
          :disabled="loading"
          @ion-change="changeDate($event)"
        />
      </ion-popover>
    </span>
    <span class="mt-2">
      <AButton
        :disabled="loading"
        :btn-tertiary="true"
        @click="$emit('confirm')"
      >
        {{ $t("hzba.buttons.bestaetigen") }}
      </AButton>
      <AButton
        :disabled="loading"
        class="ml-1"
        :btn-tertiary="true"
        @click="$emit('cancel')"
      >
        {{ $t("hzba.buttons.abbrechen") }}
      </AButton>
    </span>
  </span>
</template>

<script
    setup
    lang="ts"
>
import AButton from "@/components/Base/AButton";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import {
    IonDatetime,
    IonDatetimeButton,
    IonPopover
} from "@ionic/vue";
import { useI18n } from 'vue-i18n';

defineProps({
    modelValue: {
        type: String,
        default: ""
    },
    loading: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);

const { locale } = useI18n();

const uuid = generateUUID();

const changeDate = (newDate: any) => {
    emit("update:modelValue", newDate.detail.value);
};

</script>

<style lang="scss" scoped>

// is is tricky to style the ion-datetime component, since the ::part selector did not work
// setting the colors manually, since --ion-color-base derives from --primary
ion-datetime, ion-datetime-button {
  --ion-color-base: #511A3F !important;
  --primary: #852565 !important;
}
</style>