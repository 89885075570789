import { MapSettingsInterface } from "@/types/map/interfaces";
import { Monitoring } from "./monitoring";

const fallbackCenterValues = [16.363449, 48.210033];

export const validateCenterSetting = (settings: MapSettingsInterface) => {
    let vals: null | number[] = null;
    if (settings?.mapCenter) {
        vals = settings.mapCenter;
    } else if ((settings as any)?.mapStyle?.mapCenter) {
        // mapStyle is not defined in MapSettingsInterface, but has been bing validated in last version, moving it here too
        vals = (settings as any).mapStyle.mapCenter;
    } else {
        vals = fallbackCenterValues;
    }
    if (vals && vals.some(val => isNaN(val))) {
        //TODO: send sentry info: current project, is it mapSettings or mapSettings.mapStyle, the lng/lat values
        vals = fallbackCenterValues;
    }
    return vals;
};

export const validateCenterSettingMonitored = Monitoring.decorateWithErrorHandling(validateCenterSetting, "Error in validateCenterSetting", fallbackCenterValues);