import { modalController } from "@ionic/vue";
import PdfViewer from "@/components/v2/App/PdfViewer.vue";
import apiClient, { strapiApiUrl } from "@/api/api";
import StrapiMedia from "@/models/photo/strapi-media.model";

export async function openPdfViewerModal(pdf: StrapiMedia | Blob) {
  const modal = await modalController.create({
    component: PdfViewer,
    cssClass: 'v2Modal pdfViewerModal',
    componentProps: {
      pdf
    },
    canDismiss: true,
  });
  modal.onWillDismiss().then(async (data) => {
    const formData = new FormData();
    formData.append("files", data.data.blob, data.data.pdf.name);
    formData.append("ref", 'api::frage.frage');
    formData.append("Content-Type", 'application/pdf');
    formData.append("field", 'eingabeMedien');

    await apiClient.post(`${strapiApiUrl}/upload?id=${data.data.pdf.id}`, formData);
  });

  return modal.present();
}