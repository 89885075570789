import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45f95138"),n=n(),_popScopeId(),n)
const _hoisted_1 = { "data-cy": "bilder-ansicht" }
const _hoisted_2 = { class: "flex flex-row justify-between" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "flex justify-center items-center h-40",
  style: {"background":"rgb(237, 237, 237)"}
}
const _hoisted_5 = { class: "text-xs text-center text-gray-400 mx-2 w-28" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageObjects, (imgObj) => {
        return (_openBlock(), _createElementBlock("div", { key: imgObj }, [
          (_ctx.getPhotoUrl(imgObj))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.getPhotoUrl(imgObj),
                "data-cy": "image"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (imgObj?.imageNotAvailable)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(imgObj?.imageNotAvailable) + " nicht verfügbar ", 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}