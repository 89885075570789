import { Geolocation } from '@capacitor/geolocation';
import piexif from 'piexifjs';
import Monitoring from "@/utilities/monitoring";

const normalizeString = (inputString) =>
  inputString
    .replace('ä', 'ae')
    .replace('ü', 'ue')
    .replace('ö', 'oe')
    .replace('ß', 'ss');

export async function addMetadataToJpeg(image, exifMetadata) {
  const jpegData = image;
  const dump = await piexif.dump(exifMetadata);
  const newJpegData = piexif.insert(dump, jpegData);
  return newJpegData;
}

function floatToRational(value) {
  const denominator = 1000000; // precision
  const numerator = Math.round(value * denominator);
  return [numerator, denominator];
}

export function addHeading (heading, exifObj) {
  if (heading && heading >= 0) {
    exifObj.GPS[piexif.GPSIFD.GPSImgDirectionRef] = 'M'; // 'M' for Magnetic North
    exifObj.GPS[piexif.GPSIFD.GPSImgDirection] = floatToRational(heading);
  } else {
    throw new Error('Heading is not available or invalid');
  }
}

export async function createExifMetaData( ba ) {
  const date = getTimeForDateTimeOriginal();
  const exifObj = {
    "0th": {
      [piexif.ImageIFD.Artist]: ba?.gutachter?.username ? normalizeString( ba.gutachter.username ): "ERIKA MUSTERMANN",
      [piexif.ImageIFD.ImageDescription]: ba?.name?.de ? "Bestandsaufnahme: " + normalizeString( ba.name.de ) : "Bestandsaufnahme"
    },
    "Exif": {
      [piexif.ExifIFD.DateTimeOriginal]: date,
      [piexif.ExifIFD.DateTimeDigitized]: date
    }
  };
  try {
    const coordinates = await Geolocation.getCurrentPosition({ enableHighAccuracy: true });
    const latitude = coordinates?.coords?.latitude;
    const longitude = coordinates?.coords?.longitude;

    if (latitude && longitude ) {
      exifObj.GPS = {
        [piexif.GPSIFD.GPSLatitudeRef]: latitude < 0 ? 'S' : 'N',
        [piexif.GPSIFD.GPSLatitude]: piexif.GPSHelper.degToDmsRational(
          Math.abs(latitude)
        ),
        [piexif.GPSIFD.GPSLongitudeRef]: longitude < 0 ? 'W' : 'E',
        [piexif.GPSIFD.GPSLongitude]: piexif.GPSHelper.degToDmsRational(
          Math.abs(longitude)
        )
      };
    }
  } catch (error) {
    // it is not a problem if the user does not allow access to the location, so we just ignore the error
  }
  return exifObj;
}

function getTimeForDateTimeOriginal() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  const seconds = now.getSeconds();

  const dateTimeOriginal = `${year}:${month.toString().padStart(2, '0')}:${day
    .toString()
    .padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  return dateTimeOriginal;
}
