<template>
  <section class="pdf-content">
    <page-counter-start />
    <!--<cover-page :ba="ba" :immobilie="immobilie" :lang="lang"></cover-page>--> <!--Currently not needed-->
    <mast-uebersicht
      :ba="ba"
      :immobilie="immobilie"
      :meldungen="meldungen"
      :lang="lang"
    />
    <div
      v-for="(meldung, index) in meldungen"
      :key="index"
    >
      <mast-meldung
        v-if="index != 1"
        :meldung="meldung"
        :immobilie="immobilie"
        :ba="ba"
        :lang="lang"
        :index="index"
      />
    </div>
    <mast-zugaenglichkeit
      :zugaenglichkeiten="zugaenglichkeiten"
      :immobilie="immobilie"
      :ba="ba"
      :lang="lang"
    />
    <mast-notizen
      :notizen="notizen"
      :immobilie="immobilie"
      :ba="ba"
      :lang="lang"
    />
    <page-counter-end />
  </section>
</template>

<script lang="ts">
  import PageCounterEnd from '@/components/Pdf/Components/PageCounterEnd';
import PageCounterStart from '@/components/Pdf/Components/PageCounterStart';
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Fragenblock } from '@/models/ba/Fragenblock';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import PdfA4Page from '../Components/PdfA4Page.vue';
import CoverPage from './CoverPage.vue';
import MastMeldung from './MastMeldung.vue';
import MastNotizen from './MastNotizen.vue';
import MastUebersicht from './MastUebersicht.vue';
import MastZugaenglichkeit from './MastZugaenglichkeit.vue';

  export default defineComponent({
    name: 'PdfNewVationTemplate',
    components: {
      CoverPage,
      MastUebersicht,
      MastMeldung,
      MastNotizen,
      MastZugaenglichkeit,
      PdfA4Page,
      PageCounterEnd,
      PageCounterStart,
    },
    props: {
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
      lang: {
        type: String,
        default: 'de',
      },
    },
    setup(props: any) {
      const { t } = useI18n({ useScope: 'global' });
      const uUser = useUser();
      const notizen: Fragenblock[] = [];
      const zugaenglichkeiten: Fragenblock[] = [];
      const meldungen: Fragenblock[] = [];

      function splitFragenBlocks(fragenblocks: Fragenblock[]) {
        if(fragenblocks != null){
          fragenblocks.forEach((fragenblock) => {
            if (fragenblock.identifier === 'notizbearbeiter-modal')
              notizen.push(fragenblock);
            else if (fragenblock.identifier === 'zugaenglichkeit-modal')
              zugaenglichkeiten.push(fragenblock);
            else if (fragenblock.identifier === 'meldung-group') 
              meldungen.push(fragenblock);
            if (fragenblock.fragenblocks != null) 
              splitFragenBlocks(fragenblock.fragenblocks);
          });
        }
      }
      splitFragenBlocks(props.ba.fragenblocks);
      return {
        user: uUser.user,
        t,
        meldungen,
        zugaenglichkeiten,
        notizen
      };
    },
    mounted() {
      this.InputDefaultVal();
    },
    methods: {
      InputDefaultVal() {
        document.querySelectorAll('[data-cy]').forEach(element => {
          if (element.nodeName === 'IMG' || element.innerHTML.trim() !== "")
            return;
          if (element.textContent?.trim() === '')
            element.textContent = 'Unbekannt';
        });
      },
    },
  });
</script>

<style scoped>
  * {
    color: black;
    background-color:#3c3c3c !important;
    font-size: 12px !important;
  }
  .pdf-content {
    width: 800px !important;
    margin: 0 auto;
    color: var(--white100);
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 800px;
    color: var(--black100);
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1.5px;
    background-color: gray;
    margin: 20px 0;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .pageHeader{
    font-weight: bold;
    font-size: 20px !important;
  }
  .midHeader{
    font-weight: bold;
    font-size: 15px !important;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
  }
  .smallHeader{
    font-weight: bold;
    font-size: 12px !important;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
  .content {
    height: 800px;
  }
  span{
    padding-left: 10px;
  }
</style>