<template>
  <pdf-a4-page
    no-margin
    :pdf-key="lang"
  >
    <div class="report-background w-screen h-screen">
      <page-header
        :ba="ba"
        :immobilie="immobilie"
        :title="'Übersicht'"
      />
      <div class="flex flex-col justify-between">
        <div class="flex justify-between">
          <span class="pageHeader">Übersicht</span>
          <span>
            <img
              class="icon"
              src="../../../../public/assets/img/pdf/newvation/newvation-logo.png"
              width="50"
              style="margin-top: -12px; padding-bottom: 10px;"
            >
          </span>
        </div>
        <hr class="trenner-m">
        <div class="content">
          <div class="title-container stackpanel">
            <div>
              <div class="text-4xl midHeader">
                Allgemein - Mast
              </div>
              <div class="flex mt-1">
                <span class="w-60">Mastnummer:</span>
                <span
                  class="w-60"
                  data-cy="pdf-pole-nr"
                >
                  {{ ba.fragenblocks?.[0].fragenblocks?.[0].frages?.[0].eingabeText }}
                </span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Leitungsanlage:</span>
                <span
                  class="w-60"
                  data-cy="pdf-leitungsanlage"
                >
                  {{ ba.fragenblocks?.[0].fragenblocks?.[0].frages?.[1].eingabeText }}
                </span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Anzahl der Meldungen:</span>
                <span
                  class="w-60"
                  data-cy="pdf-reports-number"
                >
                  {{ ba.fragenblocks?.[0].fragenblocks?.[0].frages?.[2].eingabeText }}
                </span>
              </div>
            </div>
            <div class="mast-view flex w-70 mr-5">
              <img
                :src="getPhotoUrl(immobilie?.vorschaubild)"
                style="max-height: 100px; max-width: 100px"
                data-cy="generalData-vorschaubild"
              >
            </div>
          </div>
          <hr class="trenner">
          <div
            class="title-container"
            style="width: 100%; margin-right:10px;"
          >
            <div class="text-4xl midHeader">
              Schäden - Zusammenfassung
            </div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Status - Schaden</th>
                  <th>Schwere - Schaden</th>
                  <th>Beschreibung - Meldung</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(meldung, index) in meldungen.filter((_, idx) => idx != 1)"
                  :key="index"
                >
                  <td style="width: 12%;">
                    {{ "Schaden " + (index + 1) }}
                  </td>
                  <td
                    v-if="meldung.frages?.[6].eingabeBoolean == true"
                    style="width: 17%;"
                  >
                    Behoben
                  </td>
                  <td
                    v-else
                    style="width: 17%;"
                  >
                    Nicht Behoben
                  </td>
                  <td
                    v-if="meldung.frages?.[4].eingabeAuswahlWert != undefined"
                    style="width: 20%;"
                  >
                    {{ meldung.frages?.[4].eingabeAuswahlWert }} 
                  </td>
                  <td
                    v-else
                    style="width: 15%;"
                  >
                    -
                  </td>
                  <td style="width: 36%;">
                    {{ 
                      meldung.frages?.[2].eingabeText 
                        ? meldung.frages?.[2].eingabeText.length > 50 
                          ? meldung.frages?.[2].eingabeText.substring(0, 50) + '...' 
                          : meldung.frages?.[2].eingabeText 
                        : '-' 
                    }}
                  </td>
                </tr>
              </tbody> 
            </table>
          </div>
          <hr class="trenner">
          <div class="title-container stackpanel">
            <div>
              <div class="text-4xl midHeader">
                Bericht - Zusammenfassung
              </div>
              <div class="flex mt-1">
                <span class="w-60">Gutachter:</span>
                <span
                  class="w-80"
                  data-cy="pdf-report-gutachter"
                >{{ ba.gutachter.username }}</span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Datum der Dokumentation:</span>
                <span
                  class="w-60"
                  data-cy="pdf-sighting-date"
                >
                  {{ moment(ba.sichtungsdatum).isValid() ? moment(ba.sichtungsdatum).format("DD.MM.YYYY") : 'Ungültiges Datum' }}
                </span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Datum der Reperatur - geplant:</span>
                <span
                  class="w-60"
                  data-cy="pdf-action-planned"
                >
                  {{ moment(ba.begehungsdatum).isValid() ? moment(ba.begehungsdatum).format("DD.MM.YYYY") : 'Ungültiges Datum' }}
                </span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Letztes Update:</span>
                <span
                  class="w-80"
                  data-cy="pdf-report-update"
                >{{ moment(ba.updatedAt).isValid() ? moment(ba.updatedAt).format("DD.MM.YYYY") : 'Ungültiges Datum' }}</span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Fazit:</span>
                <span
                  class="w-80 textBlock"
                  data-cy="pdf-report-conclusion"
                >{{ ba.fazit }}</span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Status:</span>
                <span
                  class="w-80"
                  data-cy="pdf-report-status"
                >{{ statusReadable }}</span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Bearbeiter:</span>
                <span
                  class="w-80"
                  data-cy="pdf-report-worker"
                >{{ ba.bearbeitenderNutzer?.username }}</span>
              </div>
              <div class="flex mt-1">
                <span class="w-60">Unterschriften:</span>
                <span class="w-80">
                  <div v-if="ba.unterschrifts && ba.unterschrifts.length > 0">
                    <div
                      v-for="(unterschrift, index) in ba.unterschrifts.slice(0,3)"
                      :key="index"
                      style="margin-bottom: 10px;"
                    >
                      <img 
                        v-if="unterschrift.bild" 
                        :src="getPhotoUrl(unterschrift.bild)" 
                        style="max-height: 200px; max-width: 200px" 
                        :alt="'Unterschrift ' + (index + 1)"
                        data-cy="singatures"
                      >
                      <div>{{ unterschrift.name }}</div>
                    </div>
                  </div>
                  <div v-else>
                    Keine Unterschriften
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-bottom />
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
  import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import { Fragenblock } from '@/models/ba/Fragenblock';
import Immobilie, { immobilie } from '@/models/immobilie.model';
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from 'moment';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import PdfImageCropped from '../Components/PdfImageCropped.vue';
import PageBottom from './PageBottom.vue';
import PageHeader from './PageHeader.vue';

  export default defineComponent({
    name: 'MastUebersicht',
    components: {
      PdfImageCropped,
      getPhotoUrl,
      PdfA4Page,
      PageHeader,
      PageBottom
    },
    props: {
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      meldungen: {
        type: Object as PropType<Fragenblock[]>,
        required: true,
      },
      lang: {
        type: String,
        default: 'de',
      }
    },
    setup(props: any) {
      const { t } = useI18n({ useScope: 'global' });
      return {
        t,
        getPhotoUrl,
        moment,
      };
    },
    computed: {
      statusReadable() {
        switch (this.ba.status) {
          case 'ABGESCHLOSSEN':
            return 'Abgeschlossen';
          case 'ANGELEGT':
            return 'Angelegt';
          case 'GEPLANT':
            return 'Geplant';
          case 'IN_DURCHFUEHRUNG':
            return 'In Durchführung'; 
          case 'FREIGEGEBEN':
            return 'Freigegeben';  
          case 'ARCHIVIERT':
            return 'Archiviert';
          default:
            return this.ba.status;
        }
      }
    },
    methods: {
      getMehrfachAuswahlWerts(wertArray : any[]) {
        if (wertArray)
          return wertArray.join(', ');
        return '';
      },
    }
  });
</script>

<style scoped lang="scss">
  * {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  .pdf-content {
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
    height: 1100px;
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 1050px;
    color: var(--black100);
    margin-bottom: -20px;
  }
  .pageHeader {
    font-weight: bold;
    font-size: 20px !important;;
  }
  .midHeader {
    font-weight: bold;
    font-size: 15px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .smallHeader {
    font-weight: bold;
    font-size: 12px !important;;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .content {
    height: 830px;
  }
  .stackpanel {
    display: flex;
    flex-direction: row; 
  }
  .title-container {
    width: 40%;
  }
  .textBlock {
    padding-right: -20px;
    width: 450px;
  }
  span {
    padding-left: 10px;
  }
  img {
    border-radius: 5px;
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;  
    width: 740px;
  }
  .trenner-m {
    @extend .trenner;
    height: 1.5px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    margin-top: -5px;
    width: 720px;
  }
  .icon {
    margin-right: 20px;
    display: flex;
    padding-top: -20px;
  }
  .tdh{
    padding-left: 10px;
    padding-right: 10px; 
    font-size: 12px;
    text-align: center;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    margin-right: 50px
  }
  td {
    @extend .tdh;
    border-bottom: 0.5px solid black;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  th {
    @extend .tdh;
    border-bottom: 1px solid black;
    padding-bottom: 4px;
    padding-top: 4px;

  }
  tr:last-child td {
    border-bottom: none;
    padding-bottom: 4px;
    padding-top: 4px;
  }
</style>
