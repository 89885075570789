import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "documentItem flex flex-col" }
const _hoisted_2 = { class: "text-center pb-4" }
const _hoisted_3 = { class: "text-center pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AImage = _resolveComponent("AImage")!
  const _component_APdfThumbnail = _resolveComponent("APdfThumbnail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.getType(_ctx.document.dokument) === 'photo')
        ? (_openBlock(), _createBlock(_component_AImage, {
            key: 0,
            src: _ctx.getPhotoUrl(_ctx.document.dokument),
            "image-class": 'photo object-contain rounded-lg w-full h-200',
            onClick: _cache[0] || (_cache[0] = () => _ctx.openBaseModal('gallery', { images: [_ctx.document.dokument], title: _ctx.getLocalization(_ctx.document.name) }))
          }, null, 8, ["src"]))
        : _createCommentVNode("", true),
      (_ctx.getType(_ctx.document.dokument) === 'pdf')
        ? (_openBlock(), _createBlock(_component_APdfThumbnail, {
            key: 1,
            id: `${_ctx.document.name}_${_ctx.document.id}`,
            url: _ctx.getPdfUrl(_ctx.document.dokument),
            file: _ctx.document,
            "image-class": 'photo object-contain rounded-lg w-full h-200',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPdfViewerModal(_ctx.document.dokument)))
          }, null, 8, ["id", "url", "file"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getLocalization(_ctx.document.name)), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getLocalization(_ctx.document.beschreibung)), 1)
  ]))
}