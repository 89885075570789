import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { modalController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

import { isNetworkError } from "@/api/apiErrorHelper";
import { useBauflaechen } from '@/components/Pdf/Bericht_Bauflaechen/useBauflaechen';
import { useSeilzugflaechen } from "@/components/Pdf/Bericht_Seilzugflaechen/useSeilzugflaechen";
import HzbaUploadModal from "@/components/hzba/HzbaUploadModal.vue";
import { useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { useIdentifierFrageFinder } from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import { UploadBaPhotos } from "@/composables/Upload/UploadBaPhotos";
import { LangPdf, PdfBerichtManager } from '@/composables/pdfBerichtManager';
import { useStore } from "@/composables/useTypedStore";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from '@/models/ba/models/bestandsaufnahme.model';
import Immobilie from '@/models/immobilie.model';
import { logger } from "@/utilities/logging";
import { Network } from "@capacitor/network";
import { computed, ref } from 'vue';
import { Monitoring } from "@/utilities/monitoring";

export interface SubmitProgressReport {
  success: boolean;
  message?: string;
}

export default function useBestandsaufnahmeUpload() {
  const ba = computed<Bestandsaufnahme | undefined>(() => {
    return store.state.currentHzba.currentBa;
  });
  const immobilie = computed<Immobilie | undefined>(() => {
    return (ba.value && Immobilie.find(ba.value.immobilie)) || undefined
  })
  const { t } = useI18n({ useScope: 'global' });
  const store = useStore();
  let loadingModal: any;
  let pdfManager: PdfBerichtManager;
  let isBaUploaded = false;
  let generatedPdfs: LangPdf[] = [];
  let modal;
  const currentProject = computed(() => store.state.user.currentUserProject)

  const { summarizeOverall } = useBauflaechen();
  const { summarizeOverallSeilzugflaechen } = useSeilzugflaechen();

  const resetLastAttempt = () => {
    isBaUploaded = false;
    generatedPdfs = [];
  };

  const isUpdating = ref(false)

  /**
   * endSubmittingHzba stops loading alert, shows result alert and resets some variables
   * @param message
   */
  const endSubmittingBestandsaufnahme = async (message: string) => {
    // const alert = useAlert();
    // alert.show(message, '');

    // loadingModal && loadingModal.dismiss();
    resetLastAttempt();
  };

  const showSubmitModal = async () => {

    modal = await modalController.create({
      component: HzbaUploadModal,
      cssClass: 'small-modal',
      backdropDismiss: false
    })
    modal.present();
  }

  /**
   * Upload just generated pdf's. Wait until pdf's are generated AND ba itself is uploaded
   */
  const uploadPdfs = async(): Promise<SubmitProgressReport> => {

    if (!(isBaUploaded && generatedPdfs && generatedPdfs.length > 0) || !ba.value) {
      return { success: false, message: 'Pdf upload not ready yet as we wait for BA being synced and pdf`s being generated.'};
    }

    const immobilie = ba.value && Immobilie.find(ba.value.immobilie);
    if (!immobilie) { return { success: false, message: 'Immobilie nicht gefunden.' }}

    const res = await PdfBerichtManager.uploadPdfsToErweiterteMedien(ba.value, immobilie, generatedPdfs )

    if (res.success) {
      //TODO refactor and remove this block from this function...

      const { removeDownloadedBa  } = useBestandsaufnahmens();
      await removeDownloadedBa(ba.value);

      await store.dispatch('currentHzba/refetchCurrentBa', currentProject.value.id, true);
      await store.commit('currentHzba/setUploadStatus', 'success');
      resetLastAttempt();

      return { success: true }
    } else {

      await store.commit('currentHzba/setUploadStatus', 'errorData');
      return { success: false, message: 'Error while uploading pdfs.'}
    }
  };


  /**
   * Either sync or submit ba
   * @param isFinal determines whether it's a interim upload or final
   */
  const startSubmittingBestandsaufnahme = async (isFinal = false) => {
    let removeMonitoringContext;
    try {
      /** Init **/
      if (!ba.value || !immobilie.value) {
        Monitoring.withScope((scope) => {
          scope.setContext('Initial Values', { "Survey set": !!ba.value, "Immobilie set": !!immobilie.value });
          Monitoring.error('Ba or Immobilie is not initialized during ba submit.', !!ba.value, !!immobilie.value);
        });
        return {success: false, message: 'Ba not initialized!'}
      }
      removeMonitoringContext = Monitoring.setContext('Bestandsaufnahme Upload', { "Survey Id": ba.value.id, "Immobilie Id": immobilie.value.id });

      /** Show modal and inform the user with progress updates **/
      await showSubmitModal();

      await store.dispatch('currentHzba/saveHzbaLocally');
      /** Check for Internet, save locally if there is no connection **/
      const status = await Network.getStatus();
      if (!status.connected) {
        await store.commit('currentHzba/setUploadStatus', 'errorNoInternetConnection');
        return {
          success: false,
          message:
              'Derzeit besteht keine Internetverbindung. Ihre Daten wurden Lokal gespeichert.',
        };
      }
      let malusResult;
      switch (ba.value.identifier) {
        case 'rippistein-bauflaechen':
          malusResult = summarizeOverall( ba.value)
          ba.value.malus = malusResult.amount
          ba.value.malusColor = malusResult.color
          break;
        case 'rippistein-seilzugflaechen':
          malusResult = summarizeOverallSeilzugflaechen( ba.value)
          ba.value.malus = malusResult.amount
          ba.value.malusColor = malusResult.color
          break;
        default:
          break;
      }
      
      /** Prepare some values. **/
      // ba.value.malus = ba.value?.maengelReport({ allRelevantMangels: true })?.getCalculatedMalus()?.toString();
      // ba.value.malusColor = ba.value?.maengelReport({ allRelevantMangels: true })?.getColorByMaengel();

    //   console.log("MALUS", ba.value?.malus, ba.value?.malusColor);


      const maengel = ba.value?.getFiredMaengel();
      
      const mangellists = maengel.map(mangel => {
        return {
          kurztext: mangel.titel(),
          maluspunkte: mangel.maluspunkte(),
          eingabeFreitext: mangel.eingabeFreitext,
          eingabeRelevant: mangel.eingabeRelevant,
          gewerk: mangel.gewerk(),
          handlungsbedarfZeitpunkt: mangel.handlungsbedarfZeitpunkt(),
          handlungsbedarfTaetigkeit: mangel.handlungsbedarfTaetigkeit(),
          kategorie: mangel.kategorie(),
        }
      });

      ba.value.mangellists = JSON.stringify(mangellists);


      /** Prepare Pdf to be ready to upload */
      if (isFinal) {
        if (!pdfManager) {
          Monitoring.error('PDF Manager is not setup while startSubmittingBestandsaufnahme')
          return;
        }
        ba.value.berichts = pdfManager.generateReports(ba.value);
        pdfManager.setGenerationCompletedListener(async (lPdfs) => {
          generatedPdfs = lPdfs;
          console.log("PRE PDF upload 1")
          await uploadPdfs();
        });
      }


      /** Extract all Photos that need to be uploaded **/
      store.commit('currentHzba/setPhotoUploadTasks', new UploadBaPhotos());
      const photoUploadTasks = store.state.currentHzba.photoUploads;
      photoUploadTasks.getPhotoUploadTasks(ba.value, immobilie.value);
      logger.info('Photo upload task looks like this', { data: photoUploadTasks?.toJson() });


      /** Copy BA as a JSON for uploading **/
      const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true});
    //   logger.info('Copied BA JSON before uploading:', {data: copiedBa});
    //   console.log('Copied BA JSON before uploading:', {data: copiedBa});

      /** Upload BA **/
      await store.commit('currentHzba/setUploadStatus', 'uploadingData');
      const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${ba.value?.id}?projectId=${currentProject.value.id}`,
          {data: copiedBa},
          {save: false},
      );
      const updatedBaJsonRes = (baUploadRes.getDataFromResponse() as any).data as BestandsaufnahmeJson;
      const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);

      /** Upload photos that we extracted before **/
      await store.commit('currentHzba/setUploadStatus', 'uploadingImages');
      if (!photoUploadTasks) {
        return {success: false, message: 'Photo upload task is undefined'}
      }



      photoUploadTasks.reassignPhotosToBa(updatedBa);
      updatedBa.isLocal = false;
      updatedBa.hasLocalImages = true;
      store.commit('currentHzba/setCurrentBa', updatedBa);
      await store.dispatch("currentHzba/saveHzbaLocally");

      // console.log('Before photo upload ba:', await updatedBa.toClassJson());
      // console.log('Before photo upload anlagenschema:', useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'))

      const imageUploadRes = await photoUploadTasks.uploadPhotos(updatedBa, store);
      const imageUploadSuccess = imageUploadRes.success;

      if (imageUploadSuccess === 'paused') {
        await store.commit('currentHzba/setUploadStatus', undefined);
        store.commit('currentHzba/setPhotoUploadTasks', undefined);
        return;
      }


      if (!isFinal) {
        // We keep it downloaded after syncing.
        updatedBa.isDownloaded = true;
      } else {
        updatedBa.isDownloaded = false;
      }

      if (imageUploadSuccess === 'failed') {
        console.log('Image upload was not successful:', useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'))

        // photoUploadTasks.reassignPhotosToFrages(updatedBa);
        updatedBa.isLocal = true;

        // TODO logging
        await store.commit('currentHzba/setUploadStatus', 'errorImage');
        return;
      } else {
        /** BA was local before, if it was a success then it's not anymore **/
        updatedBa.isLocal = false;
      }


      /** Overwrite old ba with new, in vuex and localstorage **/
      updatedBa.isLocal = false;
      updatedBa.hasLocalImages = !imageUploadSuccess;
      store.commit('currentHzba/setCurrentBa', updatedBa);
    //   console.log('Updated BA after sync', await updatedBa.toClassJson());
      const updatedBaJson = await updatedBa.toClassJson()
      await BestandsaufnahmeModel.update({where: updatedBa.id, data: updatedBaJson});
      await BestandsaufnahmeModel.dispatch('$updateLocally', { data: updatedBaJson });

      // const all = BestandsaufnahmeModel.all();
      // await BestandsaufnahmeModel.dispatch('$replaceLocally', {
      //   data: [...all.values()],
      // });

      /** If image upload was not successful, we break here. **/
      if (!imageUploadSuccess) {
        resetLastAttempt();
        return;
      }


      isBaUploaded = true; // Todo validate that this is only called when nothing failed.


      /** If it's not final we are done here **/
      if (!isFinal) {
        await store.commit('currentHzba/setUploadStatus', 'success_sync');
        resetLastAttempt();
        return {
          success: true,
          message: 'Erfolgreich synchronisiert!'
        };
      }


      /** Generate Pdf's and upload the files after they are completed. **/
      /** Todo: Refactor Pdf upload to an upper position, maybe after uploading images? */
      await store.commit('currentHzba/setUploadStatus', 'uploadingPdfs');
      await uploadPdfs();

    } catch (error: any) {
      if (isNetworkError(error)) {
        await store.commit('currentHzba/setUploadStatus', 'errorBackendDidNotRespond');
      } else {
        Monitoring.chainError('ERROR SAVING BESTANDSAUFNAME', error);
        await store.commit('currentHzba/setUploadStatus', 'errorData');
      }
    } finally {
      removeMonitoringContext?.();
    }
  };


  /**
   *
   * Syncing with the backend without completing the BA
   *
   */
  const syncBestandsaufnahme = async () => {
    resetLastAttempt();
    await startSubmittingBestandsaufnahme();
  };


  const saveBaLocally = () => {
    return store.dispatch('currentHzba/saveHzbaLocally');
  }

  /**
   *
   * Completing the BA, including uploading PDF's
   *
   */
  const submitBestandsaufnahme = async (pdfM: PdfBerichtManager) => {
    resetLastAttempt();
    if (ba.value) ba.value.status = 'ABGESCHLOSSEN';
    pdfManager = pdfM;
    await startSubmittingBestandsaufnahme(true);
  };

  const updateSurvey =  async () => {
    isUpdating.value = true;
    const networkStatus = await Network.getStatus();
    if ( networkStatus.connected ) {
      const copiedBa = await ba.value?.copyLeanJson({prepareForSync: true});
      // console.log('Copied BA JSON before uploading:', copiedBa);
      const baUploadRes = await BestandsaufnahmeModel.api().put(`/bestandsaufnahmes/${ba.value?.id}?projectId=${currentProject.value.id}`,
          {data: copiedBa},
          {save: false},
      );

      const updatedBaJsonRes = (baUploadRes.getDataFromResponse() as any).data as BestandsaufnahmeJson;
      const updatedBa = new Bestandsaufnahme(updatedBaJsonRes, undefined);

      updatedBa.isLocal = false;
      await store.commit('currentHzba/setCurrentBa', updatedBa);
      
      // console.log('Updated BA after sync', await updatedBa.toClassJson());
    }
    await store.dispatch("currentHzba/saveHzbaLocally");
    isUpdating.value = false;
  }

  return {
    saveBaLocally,
    syncBestandsaufnahme,
    submitBestandsaufnahme,
    updateSurvey,
    isUpdating
  };
}
