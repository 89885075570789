<template>
  <div class="documentItem">
    <div class="file-upload-container">
      <div
        class="dropzone-container rounded-lg flex items-center justify-center"
        :style="isDragging ? 'background:rgb(239, 239, 239)' : ''"
        @dragover.prevent="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          multiple
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          v-if="!filesDropped"
        />
        <label class="file-label flex" for="fileInput">
          <div v-if="!filesDropped" class="flex px-4">
            <div v-if="isDragging" class="flex items-center justify-center">Release to drop files here.</div>
            <div v-else class="flex items-center justify-center">Drop files or click here to upload.</div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { modalController } from '@ionic/vue';
//Endpoints
import apiClient, { strapiApiUrl } from "@/api/api";
//Components
import AButton from "@/components/Base/AButton";
import DocumentItemModal from '@/components/v2/Property/DocumentItemModal.vue'
//Composables
import { useProperties } from "@/composables/Property/useProperties";

export default defineComponent({
  name: 'DragAndDropDocument',
  components: {
    AButton
  },
  props: {
    propertyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      filesDropped: false,
      files: [] as File[],
    };
  },
  methods: {
    onChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        this.files.push(...Array.from(input.files));
        this.openDocumentDetailsModal(this.files[0])
      }
    },

    async openDocumentDetailsModal(file: any) {
      const modal = await modalController.create({
        component: DocumentItemModal,
        cssClass:'v2Modal',
        componentProps: {
          file: file,
          closeClick: () => modalController
        },
        canDismiss: true,
        //@ts-ignore
        presentingElement: this.$root.$refs.ionRouter.ionRouterOutlet
      });
      modal.onWillDismiss().then(async (data) => {
        this.handleModalResponse(data.data)
      })
      return modal.present()
    },

    async handleModalResponse(data: any) {
      if(!data) {
        this.files.shift()
      } else if(data === 'delete') {
        this.files.shift()
      } else {
        await this.uploadFile(data)
        this.files.shift()
      }

      if(this.files.length > 0) {
        this.openDocumentDetailsModal(this.files[0])
      } else {
        const { fetchFullProperty } = useProperties();
        fetchFullProperty(this.propertyId);
      }
    },

    async uploadFile(data: any) {
      const localFile = data.data.file
      const blob = new Blob([localFile], { type: localFile.type });
      const formData = new FormData();
      formData.append("files", blob, localFile.name);
      formData.append("ref", 'api::dokument.dokument');
      formData.append("Content-Type", 'application/pdf');
      formData.append("field", 'dokument');

      try {
        const response = await apiClient.post(`${strapiApiUrl}/upload`, formData);
        const documentId = response.data[0].id
        await apiClient.post(`${strapiApiUrl}/dokuments`, { data: {
          name: {
            de: data.data.details.name
          },
          beschreibung: {
            de: data.data.details.description
          },
          dokument: documentId,
          immobilie: this.propertyId,
          berechtigteRollen: {
            connect: [
              {
                id: 1
              },
              {
                id: 2
              },
              {
                id: 3
              },
              {
                id: 4
              },
              {
                id: 5
              },
              {
                id: 6
              }
            ]
          }
        }});        
      } catch(err: any) {
        console.log(err)
      }
    },

    drop(event: DragEvent) {
      event.preventDefault();
      event.stopPropagation();
      this.isDragging = false;
      const dataTransfer = event.dataTransfer;
      if (dataTransfer && dataTransfer.files) {
        this.files.push(...Array.from(dataTransfer.files));
        this.openDocumentDetailsModal(this.files[0])
      }
    },

    dragover() {
      this.isDragging = true;
    },

    dragleave() {
      this.isDragging = false;
    },
  },
});
</script>

<style lang="scss">
.file-upload-container {
  text-align: center;
  height: 100%;
  min-height: 272px;

  .dropzone-container {
    width: 100%;
    height: 100%;
  }

  .hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
  }

  .file-label {
    cursor: pointer;
    height: 100%;
  }
}
</style>
