<template>
  <ion-page>
    <toolbar
      v-if="routeName !== 'propertiesMap'"
      :title="t(`${organisationPrefix}toolbar.mobile.objectOverview`)"
    >
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>
    <toolbar
      v-if="routeName === 'propertiesMap'"
      class="lg:hidden"
    >
      <template #leading>
        <div class="flex items-center">
          <ion-button
            slot="start"
            fill="clear"
            data-cy="button-goBack"
            @click="toggleView('list')"
          >
            <ion-icon 
              :icon="arrowBack"  
              size="large"
              color="light"
            />
          </ion-button>
          <Searchbar
            v-if="routeName === 'propertiesList' || !isMinLarge"
            v-model:sort-term="sortTerm"
            v-model:search-term="searchTerm"
            :toolbar-mode="true"
            class="w-full pr-4"
            :sort-terms="supportedSortTerms"
          />
        </div>
      </template>
      <!--      <template #trailing>-->
      <!--        <Searchbar-->
      <!--          v-if="routeName === 'propertiesList' || !isMinLarge"-->
      <!--          v-model:sort-term="sortTerm"-->
      <!--          v-model:search-terms="searchTerm"-->
      <!--          class="container"-->
      <!--          :sort-terms="sortTerms"-->
      <!--        />-->
      <!--      </template>-->
    </toolbar>

    <ion-content>
      <div class="h-full flex flex-col lg:px-0">
        <ion-refresher
          v-if="routeName === 'propertiesList'"
          slot="fixed"
          @ionRefresh="doRefresh($event)"
        >
          <ion-refresher-content />
        </ion-refresher>
        <div
          style="background: white"
          class="md:px-4 2xl:px-0 pt-6"
        >
          <div
            v-if="routeName === 'propertiesList'"
            class="row items-center container px-4 md:px-0 my-4"
          >
            <div class="my-2 flex-1">
              <h1 class="text-2xl md:text-3xl lg:mx-auto">
                {{ t(`${organisationPrefix}immobilie.uebersichtImmobilies`) }}
              </h1>
            </div>
            <div class="flex flex-col items-end">
              <div
                class="
                  flex items-center flex-col gap-4
                  md:flex-row"
              >
                <AButton
                  v-if="canCreateUpdateObject && featureFlags?.createObject"
                  :btn-primary="true"
                  class="
                    px-6 py-6 text-base
                    md:px-16
                  "
                  @click="$router.push('/create-property')"
                >
                  {{ t('hzba.create') }}
                </AButton>
                <div class="flex flex-row item-center gap-2">
                  <AIconButton data-cy="button-listView">
                    <ListIcon
                      :active="routeName === 'propertiesList'"
                      @click="toggleView('list')"
                    /> 
                  </AIconButton>
                  <AIconButton data-cy="button-mapView">
                    <MapIcon
                      :active="routeName === 'propertiesMap'"
                      @click="toggleView('map')"
                    />
                  </AIconButton>
                </div>
              </div>
              <div class="flex mt-2">
                <ion-label
                  v-if="displayCountLabel"
                  class="text-lg text-right"
                >
                  {{ displayCountLabel }}
                </ion-label>
                <ion-spinner
                  v-if="totalCount === null"
                  class="ml-2 h-5 w-5"
                  color="light"
                />
              </div>
            </div>
          </div>
          <div class="container px-4 md:px-0">
            <Searchbar
              v-if="routeName === 'propertiesList'"
              v-model:search-term="searchTerm"
              class="mb-4"
            />
            <div
              v-if="routeName === 'propertiesList' && (featureFlags?.statusFilter || featureFlags?.personFilter)"
              class="filtersHolder flex flex-wrap items-center my-4 gap-4"
            >
              <StatusFilter
                v-if="featureFlags?.statusFilter"
                v-model:statusFilter="statusFilter"
                :selected-statuses="statusFilter"
                :options="immobilieStatuses"
              />
              <PersonFilter
                v-if="featureFlags?.personFilter"
                v-model:personFilter="personFilter"
                :selected-persons="personFilter"
                :options="organizationUsers"
              />
              <AButton
                v-if="statusFilter.length > 0 || personFilter.length > 0"
                :btn-primary="true"
                @click="clearFilters"
              >
                {{ t('immobilie.clearFilters') }}
              </AButton>
            </div>
          </div>
        </div>

        <div
          v-if="routeName === 'propertiesList'"
          class="container px-4 md:px-0 md:px-4 2xl:px-0"
          style="flex: 1 1 auto"
        >
          <div class="py-4 block md:flex flex-row-reverse text-xl items-center">
            <div class="flex-1 md:text-right">
              <sort-by
                v-model:sort-term="sortTerm"
                :sort-terms="supportedSortTerms"
              />
            </div>
            <div>
              {{ t('immobilie.lastFetch') }}: {{ lastFetch }}
            </div>
          </div>

          <offline-hint class="mb-4">
            {{ t(`${organisationPrefix}immobilie.offlineHint`) }}
          </offline-hint>

          <Skeleton v-if="!propertiesLoaded" />
          <property-list
            v-else
            :properties="properties"
          />
          <!--          <p style="margin-top: 32px;">Zuletzt aktualisiert am {{ lastFetch }}</p>-->

          <div
            v-if="showLoadMore"
            class="flex flex-row items-center justify-center"
          >
            <AButton
              type="submit"
              :btn-primary="true"
              class="px-16 py-6 mb-2 text-lg"
              data-cy="button-login"
              style="margin-top: 32px !important; margin-left: 0"
              @click="loadMore()"
            >
              {{ t("buttons.loadMore") }}
            </AButton>
          </div>
        </div>

        <div
          v-else-if="routeName === 'propertiesMap'"
          class="container-fluent"
          style="flex: 1 1 auto"
        >
          <div
            v-if="!propertiesLoaded"
            class="container"
          >
            <Skeleton />
          </div>
          <property-map
            v-else
            :properties="properties"
            :is-view-entered="isViewEntered"
            :allow-load-more="showLoadMore"
            @go-back="() => $router.push('/properties')"
            @load-more="loadMore"
          >
            <Searchbar
              v-model:search-term="searchTerm"
              v-model:sort-term="sortTerm"
              :sort-terms="supportedSortTerms"
            />
          </property-map>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import AButton from "@/components/Base/AButton";
import AIconButton from "@/components/Base/AIconButton.vue";
import SortBy from "@/components/Base/SortBy.vue";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import PersonFilter from '@/components/v2/Filters/Property/PersonFilter.vue';
import StatusFilter from '@/components/v2/Filters/Property/StatusFilter.vue';
import { useProperties } from "@/composables/Property/useProperties";
import { SortTermActive } from "@/composables/Sort/SortTerms";
import useFetchData from "@/composables/useFetchData";
import useScreenSize from "@/composables/useScreenSize";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Immobilie from "@/models/immobilie.model";
import { ImmobilieStatus, translatedPropertyStatus } from "@/models/immobilie/interfaces/IImmobilie";
import { getLocalMetaData } from "@/models/local-meta-data";
import User from "@/models/user";
import { popoverHelper } from "@/utilities/popover-helper";
import {
IonBackButton,
IonButton,
IonButtons,
IonContent,
IonIcon,
IonImg,
IonLabel,
IonPage,
IonRefresher,
IonRefresherContent,
IonSearchbar,
IonSpinner,
onIonViewDidEnter,
onIonViewWillEnter
} from "@ionic/vue";
import {
arrowBack,
listOutline,
locationOutline,
swapVerticalOutline
} from "ionicons/icons";
import moment from "moment";
import { ComputedRef, computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ListIcon from "../../public/assets/icon/ListIcon.vue";
import MapIcon from "../../public/assets/icon/MapIcon.vue";
import Toolbar from "../components/Navigation/Toolbar.vue";
import Skeleton from "../components/Skeleton.vue";
import PropertyList from "../components/properties/PropertyList.vue";
import PropertyMap from "../components/properties/PropertyMap.vue";

export default defineComponent({
  name: "PropertiesPage",
  components: {
    OfflineHint,
    PdfChip,
    AIconButton,
    MapIcon,
    ListIcon,
    SortBy,
    Searchbar,
    Toolbar,
    IonContent,
    IonIcon,
    IonButtons,
    PropertyList,
    PropertyMap,
    Skeleton,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    AButton,
    IonBackButton,
    IonButton,
    IonImg,
    IonSearchbar,
    UserDialogButton,
    StatusFilter,
    PersonFilter,
    IonLabel,
    IonSpinner
  },
  setup() {
    const store = useStore();
    const useData = useFetchData();
    const router = useRouter();
    const { user, canCreateUpdateObject, fetchOrganisationUsers } = useUser();
    const { t } = useI18n({ useScope: "global" });
    const { isMinLarge } = useScreenSize();
    const routeName = computed(() => { return router.currentRoute.value.name; });
    const isViewEntered = ref(false)
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });
    /**
     * Filters
     */
     const immobilieStatuses = [
        {
          label: translatedPropertyStatus(ImmobilieStatus.ABGESCHLOSSEN, t),
          value: ImmobilieStatus.ABGESCHLOSSEN,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.ANGELEGT, t),
          value: ImmobilieStatus.ANGELEGT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.ARCHIVIERT, t),
          value: ImmobilieStatus.ARCHIVIERT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.FREIGEGEBEN, t),
          value: ImmobilieStatus.FREIGEGEBEN,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.GEPLANT, t),
          value: ImmobilieStatus.GEPLANT,
        },
        {
          label: translatedPropertyStatus(ImmobilieStatus.IN_DURCHFUEHRUNG, t),
          value: ImmobilieStatus.IN_DURCHFUEHRUNG,
        },
        {
          label: translatedPropertyStatus( "", t),
          value: null,
        },
      ];

    const organizationUsers = ref([] as Array<User>)

    const isNetworkConnected = computed(() => store.state.app.networkConnected);

    /**
     * View mode handling: map/list
     */
    const selectedView = ref("list");
    const toggleView = (selected: string) => {
      selectedView.value = selected;
      if (selected === "map") {
        router.push("/properties/map");
      } else {
        router.push("/properties");
      }
    };

    /**
     * Data handling: Fetch data, sort, search
     */
    const { properties, loadImmobilienPreviews, propertiesLoaded, supportedSortTerms } = useProperties();

    const statusFilter = ref([]);
    const personFilter = ref([]);
    const searchTerm = ref<string>(Immobilie.getters("searchTerm"));
    const sortTerm = ref<SortTermActive | undefined>(Immobilie.getters("sortBy"));

    function clearFilters() {
      statusFilter.value = []
      personFilter.value = []
    }

    const resetSearch = () => {
      Immobilie.dispatch("setPage", 0);
      Immobilie.deleteAll();
      loadImmobilienPreviews();
    }

    watch([searchTerm, sortTerm, statusFilter, personFilter], () => {
      Immobilie.dispatch("setSearchTerm", searchTerm.value);
      Immobilie.dispatch("setSortBy", sortTerm.value);
      Immobilie.dispatch("setStatusFilter", statusFilter);
      Immobilie.dispatch("setPersonFilter", personFilter);
      resetSearch();
    });

    watch(() => Immobilie.getters("searchTerm"), (newValue) => {
      // needed in case of loadAllProperties
      if(newValue === "") {
        searchTerm.value = "";
      }
    });

    const search = (e: any) => { searchTerm.value = e.target.value; };

    const showLoadMore = computed(() => {
      return (
        Immobilie.getters("pageCount") &&
        Immobilie.getters("page") < Immobilie.getters("pageCount")
      );
    });

    const loadMore = () => {
      loadImmobilienPreviews();
    };


    /**
     * Set and Manage bottom navigation bar
     */
    const manageToolbar = (newVal: any) => {
      if (newVal === "propertiesMap") {
        store.commit("app/hideBottomNavigation");
      } else {
        store.commit("app/showBottomNavigation");
      }
    };
    onIonViewWillEnter(() => { manageToolbar(router.currentRoute.value.name); });
    onIonViewDidEnter(() => {
      setTimeout(() => {
        isViewEntered.value = true
      }, 300)
    })
    watch(routeName, (newVal: any) => { manageToolbar(newVal); });


    /**
     * Others
     */
    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    /**
     * Display search results count
     */

     watch(isNetworkConnected, (connected) => {
      if (connected) {
        resetSearch();
      }
    });

    watch(properties, (results: Immobilie[]) => {
        const isOffline = store.getters["app/isOffline"];

        // online case is handled in composable after server call occurs
        if(isOffline) {
            Immobilie.commit((state) => {
                state.searchResultsCount = results.length;
                state.totalSearchResultsCount = results.length;
            });
        }
    });

    const retrievedCount = computed(() => store.state.entities.immobilie.searchResultsCount); 
    const totalCount = computed(() => store.state.entities.immobilie.totalSearchResultsCount);
    
    const displayCountLabel = computed(() => {
      if (totalCount.value === 0) {
        return t("immobilie.searchResults.noResults", { type: t(`${organisationPrefix.value}toolbar.immobilien`) });
      } else if (totalCount.value === 1) {
        return t("immobilie.searchResults.resultsCount.one", { type: t(`${organisationPrefix.value}createSurvey.object`) });
      } else if (retrievedCount.value === null || totalCount.value === null) {
        return t("immobilie.searchResults.loadingLabel");
      } else if (Immobilie.getters("page") >= Immobilie.getters("pageCount")) {
        return t("immobilie.searchResults.resultsCount.other", { count: totalCount.value, type: t(`${organisationPrefix.value}toolbar.immobilien`) });
      } else {
        return t("immobilie.searchResults.resultsCountOf", { retrieved: retrievedCount.value, 
          total: totalCount.value, 
          type: t(`${organisationPrefix.value}toolbar.immobilien`) });
      } 
    });

    return {
      clearFilters,
      organizationUsers,
      statusFilter,
      personFilter,
      immobilieStatuses,
      selectedView,
      properties,
      propertiesLoaded,
      toggleView,
      searchTerm,
      objectCountHeight: 62,
      user,
      search,
      supportedSortTerms,
      sortTerm,
      t,
      showLoadMore,
      loadMore,
      locationOutline,
      listOutline,
      routeName,
      doRefresh: useData.doRefresh,
      openPopover: popoverHelper,
      lastFetch,
      isMinLarge,
      swapVerticalOutline,
      arrowBack,
      isViewEntered,
      featureFlags,
      canCreateUpdateObject,
      organisationPrefix,
      displayCountLabel,
      totalCount,
      store,
      fetchOrganisationUsers
    };
  },

  async created() {
    this.organizationUsers = await this.fetchOrganisationUsers();
    this.store.commit("user/setOrganizationUsers", this.organizationUsers);
  }
});
</script>

<style scoped>
.btn {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
</style>
