export type MSError<T = any> = {
  custom: string;
  error: any;
  fallback?: T;
};

export const createError = (custom: string, error: any, fallback: any = null): MSError => ({ custom, error, fallback });

//Use polyfill as alternative https://github.com/ehmicky/error-cause-polyfill, but this check should be more lightweight
const checkCause = (): boolean => {
  try {
    //@ts-ignore
    const errorWithCause = new Error("Test error", { cause: new Error("Cause error") });

    //@ts-ignore
    return !!errorWithCause.cause; // If the cause is correctly assigned, we have ES2022 support
  } catch (e) {
    return false; // If an error is thrown, the cause option is not supported
  }
}

export const isCauseSupported = checkCause();

export const chainError = (customErrorMessage: string, error: any) => {
  if (isCauseSupported) {
    //@ts-ignore
    return new Error(customErrorMessage, { cause: error }); // "cause" only works with ES2022, careful with tsconfig setup
  } else {
    const chainedError = new Error(customErrorMessage);
    chainedError.stack += `\nCaused by: ${error.stack}`;
    return chainedError;
  }
};