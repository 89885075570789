import { AppPlatform } from '@/composables/useAppInfo';
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import User from '@/models/user';
import router from '@/routes/index';
import { Monitoring } from '@/utilities/monitoring';
import { PushNotifications } from '@capacitor/push-notifications';

const saveFirebaseToken = async (token: string) => {
  const user = useUser().user.value
  console.log('userId', user!.id)

  try {
    await User.api().put(`/users/${user!.id}`,
      { data: { firebaseToken: token }},
      { save: false }
    )
    console.log('Token saved on user object')
  } catch(err: any) {
    console.log(err)
  }

}

export const initializePushNotifications = async () => {
  const store = useStore()
  const platform = store.state.app.appPlatform
  if(platform === AppPlatform.desktop) return 

  try {
    await PushNotifications.requestPermissions();
    await PushNotifications.register();

    addListeners();
  } catch (error: any) {
    Monitoring.chainError('Error initializing push notifications:', error);
  }
};

const addListeners = () => {
  PushNotifications.addListener('registration', async (token: any) => {
    await saveFirebaseToken(token.value)
  });

  PushNotifications.addListener('registrationError', (error: any) => {
    Monitoring.chainError('Error registering for push notifications:', error);
  });

  PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
    console.log('Push notification received:', notification);
    // Handle notification payload as needed
  });

  PushNotifications.addListener('pushNotificationActionPerformed', (action: any) => {
    console.log('Push notification action performed:', action);
    const url = action.notification?.data?.url;

    if ( url ) {
        router.push(url);
    }
  });
};
