import apiClient from "@/api/api";
import LocalMetaData, {setLocalMetaData} from "@/models/local-meta-data";
import { Monitoring } from "@/utilities/monitoring";


export function useMetaData() {

    const fetchMetaData = async () => {
        try {
            const res = await apiClient.get("/other");

            if (res.data && res.data.backendVersion) {
                await setLocalMetaData('backendBuildVersion', res.data.backendVersion);
            }

            return res.data;
        } catch (error: any) {
            Monitoring.chainError("Error while fetching metadata", error);
        }
    }


    return {
        fetchMetaData,
    }

}