<template>
  <div
    class="filter-container block mt-2 pb-4"
    style="overflow-x: auto"
  >
    <date-range-picker
      class="pill inline-block"
      :start="startDate"
      :end="endDate"
      @update:range="dateFilterAdded($event)"
      @update:single="dateFilterAdded($event)"
    />
    <div
      v-for="option in filterOptions"
      :key="option.id"
      class="inline-block mb-1"
    >
      <div
        v-if="!option.onlyShowWhenOthersActive || selectedFilters.filter(el => el.id !== option.id).length > 0"
        class="pill"
        :class="selectedFilters && selectedFilters.find(el => el.id === option.id) ? 'active' : (option.onlyShowWhenOthersActive?'resetter':'')"
        @click="() => toggleOption(option)"
      >
        {{ translatedHzbaStatus(option.id,t) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {translatedHzbaStatus} from "@/models/ba/interfaces/IBestandsaufnahme";
import DateRangePicker from "@/components/Base/DateRangePicker.vue";
import { useI18n } from "vue-i18n";
import { ref } from "vue";
export default {
  name: "Filters",
  components: {
    DateRangePicker
  },
  props: {
    filterOptions: {
      type: Array,
      required: true
    },
    selectedFilters: {
      type: Array,
      required: true
    }
  },
  emits: ["update:selectedFilters", "update:dateFilter"],
  setup(props: any, { emit } : { emit: any }) {
    const { t } = useI18n({ useScope: "global" });
    const startDate = ref<string | null>("");
    const endDate = ref<string | null>("");

    const toggleOption = (option: any) => {
      const copiedFilter = JSON.parse(JSON.stringify(props.selectedFilters));

      const index = copiedFilter.findIndex((el: any) => el.id === option.id)
      if (index >= 0) {
        copiedFilter.splice(index, 1);
      } else {

        if (option.resetOtherFilters) {
          while (copiedFilter.length > 0) {
            copiedFilter.pop();
          }

          startDate.value = "";
          endDate.value = "";
          emit("update:dateFilter", "");
        }

        for (let i = copiedFilter.length-1; i >= 0; i--) {
          const item = copiedFilter[i];
          if (item.resetSelfWhenOthersActive) {
            copiedFilter.splice(i, 1)
          }
        }

        copiedFilter.push(option);
      }

      emit('update:selectedFilters', copiedFilter);
    }

    const dateFilterAdded = (filter: string | string[]) => {
        if (Array.isArray(filter)) {
          startDate.value = filter[0];
          endDate.value = filter[1];
        } else {
          startDate.value = filter;
          endDate.value = "";
        }
        emit("update:dateFilter", filter);
    }

    return {
      toggleOption,
      translatedHzbaStatus,
      t,
      dateFilterAdded,
      startDate,
      endDate
    }
  }
}
</script>

<style scoped lang="scss">

.pill, div.filter-container > :deep(.pill) {
  padding: 4px 8px;
  background: #F8F9FB;
  border-radius: 12px;
  margin-right: 6px;
  font-size: 14px;
  user-select: none;
  border: 1px solid #efefef;
  flex-shrink: 0;

  &:hover {
    cursor: pointer;
  }


  &.active {
    border: 1px solid #505050;
  }
}
.resetter{
  background: var(--secondary);
}

</style>