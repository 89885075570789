<template>
  <component
    :is="foldable ? 'ion-accordion-group' : 'div'"
    :class="foldable ? '' : 'hzba-group-wrapper'"
    class="m-4"
  >
    <component
      :is="foldable ? 'ion-accordion' : 'div'"
      value="hzba-group-accordion"
    >
      <!-- Header Section with Conditional Wrapping -->
      <component
        :is="foldable ? 'ion-item' : 'div'"
        :slot="foldable ? 'header' : ''"
      >
        <div
          class="mx-4 my-4 row items-center text-2xl"
          :class="!title ? 'mt-0' : ''"
        >
          <progress-indicator
            v-if="!hideProgress"
            :progress="20"
            class="mr-4"
          />
          <div class="flex flex-1 items-center">
            <h2
              v-if="title"
              :class="smallerTitle ? 'text-2xl' : ''"
            >
              {{ title }}
            </h2>
            <div
              v-if="suffix"
              class="flex"
            >
              &nbsp;-&nbsp;
              <span v-if="!isEditMode">{{ foldable && suffix === "0" ? "1" : suffix }}</span>
              <ion-input
                v-else
                v-model="currentSuffix"
                class="inline-block h-8 w-32"
              />
              <ion-button
                v-if="editSuffixEnabled && !isEditMode"
                fill="clear"
                color="dark"
                size="small"
                @click="startSuffixChange"
              >
                <ion-icon
                  :icon="pencilOutline"
                  :size="'small'"
                />
              </ion-button>
              <ion-button
                v-if="editSuffixEnabled && isEditMode"
                fill="clear"
                color="dark"
                size="small"
                @click="cancelSuffixChange"
              >
                <ion-icon
                  :icon="trashOutline"
                  :size="'small'"
                />
              </ion-button>
              <ion-button
                v-if="editSuffixEnabled && isEditMode"
                size="small"
                fill="clear"
                color="dark"
                @click="submitSuffixChange"
              >
                <ion-icon
                  :icon="checkmarkOutline"
                  :size="'small'"
                />
              </ion-button>
            </div>
          </div>
          <slot
            v-if="!isMaxSmall"
            name="cta"
          />
        </div>
      </component>

      <!-- Content Section -->
      <div :slot="foldable ? 'content' : ''">
        <slot />
        <slot
          v-if="isMaxSmall"
          name="cta"
        />
        <slot name="cta-bottom" />
      </div>
    </component>
  </component>
</template>

<script>
import { IonButton, IonIcon, IonInput, IonAccordionGroup, IonAccordion, IonLabel } from "@ionic/vue";
import {ref, watch} from "vue";
import ProgressIndicator from "../../ProgressIndicator";
import { pencilOutline, trashOutline, checkmarkOutline } from "ionicons/icons";
import useScreenSize from "@/composables/useScreenSize";

export default {
  name: "HzbaGroup",
  components: {ProgressIndicator, IonIcon, IonButton, IonInput, IonAccordionGroup, IonAccordion, IonLabel},
  props: {
    title: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: '',
    },
    hideProgress: {
      type: Boolean,
      default: true
    },
    editSuffixEnabled: {
      type: Boolean,
      default: false
    },
    outerMargin: {
      type: Boolean,
      default: true
    },
    smallerTitle: {
      type: Boolean,
      default: false
    },
    foldable: {
      type: Boolean,
      default: false
    }
  }, 
  emits: ['suffixChanged'],
  setup(props, { emit }) {
    const currentSuffix = ref(props.suffix);
    const isEditMode = ref(false);
    const { isMaxSmall } = useScreenSize();
    
    // currentSuffix.value = props.suffix;

    watch(() => props.suffix, (newVal, oldVal) => {
      if (newVal !== currentSuffix.value) {
        currentSuffix.value = newVal;
      }
    })
    
    const startSuffixChange = () => {
      isEditMode.value = true;
    }

    const cancelSuffixChange = () => {

      isEditMode.value = false;
      currentSuffix.value = props.suffix;
    }

    const submitSuffixChange = () => {
      isEditMode.value = false;

      console.log("submit", currentSuffix.value);

      emit('suffixChanged', currentSuffix.value)
    }

    return {
      isEditMode,
      currentSuffix,
      submitSuffixChange,
      startSuffixChange,
      cancelSuffixChange,
      trashOutline,
      pencilOutline,
      checkmarkOutline,
      isMaxSmall
    }
  }
}
</script>

<style scoped lang="scss">

.hzba-group-wrapper {
  padding-bottom: 24px;
  border: 1px solid #dfdfdf;
  background: var(--white100);
  border-radius: 10px;
}

ion-accordion {
  border: 1px solid #dfdfdf;
  border-radius: 10px;
}



ion-input {
  background: $grey-200;
}

</style>