import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a6c165b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  style: {"background":"white"},
  class: "pt-8"
}
const _hoisted_2 = { class: "container pb-4 px-4 lg:px-4" }
const _hoisted_3 = { class: "mt-8 mb-4 flex align-center" }
const _hoisted_4 = { class: "flex-1 text-2xl md:text-3xl lg:mx-auto" }
const _hoisted_5 = { class: "flex flex-col items-end" }
const _hoisted_6 = { class: "mb-4 flex flex-row items-center" }
const _hoisted_7 = {
  key: 0,
  class: "createSurveyButtonHolder"
}
const _hoisted_8 = {
  key: 1,
  class: "ml-3"
}
const _hoisted_9 = { class: "flex" }
const _hoisted_10 = { class: "container pb-4 px-4 lg:px-4" }
const _hoisted_11 = { class: "py-4 block md:flex flex-row-reverse text-xl items-center" }
const _hoisted_12 = { class: "flex-1 md:text-right" }
const _hoisted_13 = {
  key: 2,
  class: "flex flex-row items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDialogButton = _resolveComponent("UserDialogButton")!
  const _component_toolbar = _resolveComponent("toolbar")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ExportSurveyAsExcelButton = _resolveComponent("ExportSurveyAsExcelButton")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_Searchbar = _resolveComponent("Searchbar")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_sort_by = _resolveComponent("sort-by")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_offline_hint = _resolveComponent("offline-hint")!
  const _component_socket_disconnected_hint = _resolveComponent("socket-disconnected-hint")!
  const _component_hzba_list = _resolveComponent("hzba-list")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_toolbar, {
        title: $setup.t(`${$setup.organisationPrefix}hzba.bestandsaufnahmen`)
      }, {
        trailing: _withCtx(() => [
          _createVNode(_component_UserDialogButton)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => ($setup.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h1", _hoisted_4, _toDisplayString($setup.t(`${$setup.organisationPrefix}hzba.bestandsaufnahmen`)), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    ($setup.canCreateSurvey && $setup.featureFlags?.createSurvey)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_AButton, {
                            "btn-primary": true,
                            class: "px-16 py-6 text-lg",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($options.openAufnahmenModal()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($setup.t('hzba.create')), 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    ($setup.featureFlags?.survey?.exportxls)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_ExportSurveyAsExcelButton, {
                            "is-exporting": $setup.isExportingExcel,
                            onClick: $setup.exportExcel,
                            onAbort: $setup.abortExcelExport
                          }, null, 8, ["is-exporting", "onClick", "onAbort"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    ($setup.displayCountLabel)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 0,
                          class: "text-lg text-right"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.displayCountLabel), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.totalCount === null)
                      ? (_openBlock(), _createBlock(_component_ion_spinner, {
                          key: 1,
                          class: "ml-2 h-5 w-5",
                          color: "light"
                        }))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]),
              _createVNode(_component_Searchbar, {
                "search-term": $setup.searchTerm,
                "onUpdate:searchTerm": _cache[2] || (_cache[2] = ($event: any) => (($setup.searchTerm) = $event)),
                "sort-term": $setup.sortTerm,
                "onUpdate:sortTerm": _cache[3] || (_cache[3] = ($event: any) => (($setup.sortTerm) = $event))
              }, null, 8, ["search-term", "sort-term"]),
              _createVNode(_component_Filters, {
                "selected-filters": $setup.selectedFilters,
                "onUpdate:selectedFilters": _cache[4] || (_cache[4] = ($event: any) => (($setup.selectedFilters) = $event)),
                "filter-options": $setup.filterOptions,
                "onUpdate:dateFilter": _cache[5] || (_cache[5] = ($event: any) => ($setup.dateFilter = $event))
              }, null, 8, ["selected-filters", "filter-options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_sort_by, {
                  "sort-term": $setup.sortTerm,
                  "onUpdate:sortTerm": _cache[6] || (_cache[6] = ($event: any) => (($setup.sortTerm) = $event)),
                  "sort-terms": $setup.supportedSortTerms
                }, null, 8, ["sort-term", "sort-terms"])
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString($setup.t("hzba.zuletztAktualisiertAm")) + ": " + _toDisplayString($setup.lastFetch) + " ", 1),
                _createVNode(_component_a_button, {
                  onClick: _cache[7] || (_cache[7] = () => $setup.doRefresh(_ctx.$event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Refresh ")
                  ]),
                  _: 1
                })
              ])
            ]),
            _createVNode(_component_offline_hint, {
              class: "mb-4",
              style: {"margin-top":"0"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t(`${$setup.organisationPrefix}hzba.noInternetConnectionInfo`)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_socket_disconnected_hint, { class: "mb-4" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t(`${$setup.organisationPrefix}hzba.noSocketConnectionInfo`)), 1)
              ]),
              _: 1
            }),
            ($setup.loaded)
              ? (_openBlock(), _createBlock(_component_hzba_list, {
                  key: 0,
                  hzbas: $setup.bas
                }, null, 8, ["hzbas"]))
              : (_openBlock(), _createBlock(_component_Skeleton, { key: 1 })),
            ($setup.showLoadMore)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_AButton, {
                    type: "submit",
                    "btn-primary": true,
                    class: "px-16 py-6 mb-2 text-lg",
                    "data-cy": "button-login",
                    style: {"margin-top":"32px !important","margin-left":"0"},
                    onClick: _cache[8] || (_cache[8] = ($event: any) => ($setup.loadMore()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t("buttons.loadMore")), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}