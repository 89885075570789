import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-65b396ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_unit_chip = _resolveComponent("unit-chip")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_input_error = _resolveComponent("input-error")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    lines: "none",
    "data-cy": "base-input-item"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, {
        class: _normalizeClass(["p-0", _ctx.separator ? 'border-bottom-1-light' : ''])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, {
            class: "ion-wrap d-flex items-center mb-1 px-0",
            "data-cy": `input-row-${_ctx.label && _ctx.label.replace(/\s+/g, '-').toLowerCase()}`
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_col, { class: "md" }, {
                default: _withCtx(() => [
                  _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_col, { class: "ion-float-right p-0 pl-0 flex justify-end" }, {
                default: _withCtx(() => [
                  (!_ctx.type || (_ctx.type.toLowerCase() !== 'date' && _ctx.type.toLowerCase() !== 'time'))
                    ? (_openBlock(), _createBlock(_component_ion_input, {
                        ref: "templateRefInputField",
                        key: _ctx.key,
                        value: _ctx.newlyEnteredValue,
                        "data-cy": "base-input",
                        placeholder: _ctx.placeholder ? _ctx.placeholder : (_ctx.type === 'number' ? '0' : _ctx.t('hzba.wertPlatzhalter')),
                        class: _normalizeClass(["inline-block text-right", [
              _ctx.flatMode ? 'flat' : '',
              _ctx.type === 'integer' || _ctx.type === 'number' ? 'number-input' : '',
            ]]),
                        mode: "md",
                        style: {"min-width":"80px"},
                        type: _ctx.inputType,
                        inputmode: _ctx.inputMode,
                        pattern: _ctx.type === 'integer' ? '[0-9]*' : '',
                        disabled: _ctx.readonly,
                        maxlength: _ctx.limit,
                        min: _ctx.min,
                        autocomplete: "off",
                        onIonBlur: _ctx.onBlur,
                        onInput: _ctx.onChanged,
                        onKeypress: _ctx.bouncer
                      }, null, 8, ["value", "placeholder", "class", "type", "inputmode", "pattern", "disabled", "maxlength", "min", "onIonBlur", "onInput", "onKeypress"]))
                    : (_openBlock(), _createBlock(_component_ion_input, {
                        key: 1,
                        type: _ctx.type.toLowerCase() === 'date' ? 'date' : 'time',
                        class: _normalizeClass(["inline-block text-right border-none", [ _ctx.flatMode ? 'flat' : '', !_ctx.modelValue ? 'empty-content' : '' ]]),
                        style: {"padding-bottom":"8px","padding-top":"8px","min-width":"80px"},
                        value: _ctx.modelValue,
                        placeholder: _ctx.type.toLowerCase() === 'date' ? 'TT.MM.YYYY' : 'HH:MM',
                        disabled: _ctx.readonly,
                        onIonBlur: _ctx.onBlur,
                        onInput: _cache[0] || (_cache[0] = (e) => _ctx.onChanged(e)),
                        onKeypress: _ctx.bouncer
                      }, null, 8, ["type", "class", "value", "placeholder", "disabled", "onIonBlur", "onKeypress"]))
                ]),
                _: 1
              }),
              (_ctx.unit)
                ? (_openBlock(), _createBlock(_component_unit_chip, {
                    key: 0,
                    "show-unit": true,
                    unit: _ctx.unit,
                    style: {"text-align":"right"}
                  }, null, 8, ["unit"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["data-cy"]),
          (_ctx.dependentReason)
            ? (_openBlock(), _createBlock(_component_ion_row, {
                key: 0,
                class: "ion-wrap d-flex items-center"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, { class: "text-gray-600 text-sm" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.dependentReason), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_input_error, { errors: _ctx.errors }, null, 8, ["errors"])
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}