import User from '@/models/user';
import { setCache } from '@/store/storage/ionicStorage';
import { Monitoring } from '@/utilities/monitoring';

export const user = {
  namespaced: true,
  state: () => ({
    currentUserProject: {},
    organizationUsers: [] as User[]
  }),
  mutations: {
    async storeCurrentUserProject(state: any, project: any) {
      state.currentUserProject = project;
      Monitoring.setTag("project", project.name);
      Monitoring.setGlobalContext("Project", { id: project.id, name: project.name });
      await setCache('cachedUserProject', JSON.stringify(project));
    },
    setOrganizationUsers(state: any, users: User[]) {
      state.organizationUsers = users;
    }
  }
};
