<template>
  <div
    v-show="isLoaded"
    id="pageContainer"
    ref="pageContainer"
  >
    <div
      id="viewer"
      ref="viewer"
      class="pdfViewer"
      :class="!isZoomEnabled ? 'horizontal-scroll' : ''"
    />
  </div>

  <div
    v-if="isLoaded"
    class="fixed block ml-2 mt-2 z-50 top-24"
  >
    <div>
      <ion-button
        color="dark"
        class="w-10 h-10"
        @click="zoomIn"
      >
        +
      </ion-button>
    </div>
    <div>
      <ion-button
        color="dark"
        class="w-10 h-10"
        :disabled="currentScale <= 0.7"
        @click="zoomOut"
      >
        -
      </ion-button>
    </div>
  </div>

  <div v-show="!isLoaded">
    <div
      class="flex m-4 items-center justify-center"
      style="height: 80vh"
    >
      <div class="text-center">
        <ion-spinner
          name="dots"
          duration="1000"
          class="inline-block m-auto mt-2"
          size="large"
        />
        <div>wird geladen...</div>
      </div>
    </div>
  </div>
</template>

<script>

import { IonButton, IonSpinner, isPlatform } from "@ionic/vue";
import { EventBus, PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";
import { nextTick, onMounted, onUnmounted, ref } from "vue";
const pdfjsLib = require("pdfjs-dist/build/pdf");
// const pdfjsLib = require("pdfjs-dist");
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { computed } from "vue";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

/**
 *
 * Important links
 *
 * Pinch zoom:
 * https://gist.github.com/larsneo/bb75616e9426ae589f50e8c8411020f6
 *
 * PDF Viewer:
 * https://www.anycodings.com/1questions/1407993/how-to-import-mozilla-pdfjs-in-vue-project
 *
 */
export default {
  name: "PdfReader",
  components: {
    IonSpinner,
    IonButton,
  },
  props: {
    url: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    }

  },
  setup(props) {
    /**
     * Pdf-js viewer
     */
    let pdfViewer;

    /**
     * Viewer and Container reference objects
     */
    const viewer = ref();
    const pageContainer = ref();

    /**
     * Others
     */
    const isLoaded = ref(false);
    const numPages = ref(-1);
    const currentScale = ref(1);

    /**
     * Use this for debugging on a laptop without touch. "Simulates" a second touch gesture.
     * Works only with "requireMinTouches" set to 1.
     */
    let t1xStart, t1yStart;
    const requireMinTouches = ref(2);

    /**
     * Pinch zoom variables
     */
    let startX = 0, startY = 0;
    let initialPinchDistance = 0;
    let pinchScale = 1;

    /**
     * Debugging listener
     */
    const keyDownListener = (e) => {
      if (e.code === 'Enter') {
        console.log("Tab pressed. Switching mode")
        requireMinTouches.value = requireMinTouches.value === 1 ? 2 : 1
      }
    }

    /**
     * (2) Touch gestures start.
     */
    const onTouchStart = (e) => {
      if (e.touches.length >= requireMinTouches.value) {
        t1xStart = e.touches.length > 1 ? e.touches[1].pageX : e.touches[0].pageX+ 100;
        t1yStart = e.touches.length > 1 ? e.touches[1].pageY : e.touches[0].pageY+ 100;

        startX = (e.touches[0].pageX + t1xStart) / 2;
        startY = (e.touches[0].pageY + t1yStart) / 2;

        initialPinchDistance = Math.hypot(
            t1xStart - e.touches[0].pageX,
            t1yStart - e.touches[0].pageY
        );
      } else {
        initialPinchDistance = 0;
      }
    }


    /**
     * (2) Touch gestures move.
     */
    const onTouchMove = (e) => {
      if (initialPinchDistance <= 0 || e.touches.length < requireMinTouches.value) {
        return;
      }
      if (e.scale !== 1) {
        e.preventDefault();
      }

      const t1xMove = e.touches.length > 1 ? e.touches[1].pageX : t1xStart;
      const t1yMove = e.touches.length > 1 ? e.touches[1].pageY : t1yStart;

      const pinchDistance = Math.hypot(
          t1xMove - e.touches[0].pageX,
          t1yMove - e.touches[0].pageY
      );
      const originX = startX + pageContainer.value.scrollLeft;
      const originY = startY + pageContainer.value.scrollTop;
      pinchScale = pinchDistance / initialPinchDistance;
      viewer.value.style.transform = `scale(${pinchScale})`;
      viewer.value.style.transformOrigin = `${originX}px ${originY}px`;
    }

    /**
     * (2) Touch gestures end.
     */
    const onTouchEnd = (e) => {
        if (initialPinchDistance <= 0) {
          return;
        }
        viewer.value.style.transform = `none`;
        viewer.value.style.transformOrigin = `unset`;
        pdfViewer.currentScale *= pinchScale;
        currentScale.value = pdfViewer.currentScale;

        const rect = pageContainer.value.getBoundingClientRect();
        const dx = startX - rect.left;
        const dy = startY - rect.top;
        pageContainer.value.scrollLeft += dx * (pinchScale - 1);
        pageContainer.value.scrollTop += dy * (pinchScale - 1);

    //   console.log("pageContainer scroll left to be set...", dx * (pinchScale - 1));
    //   console.log("pageContainer scroll left, top", pageContainer.value.scrollLeft, pageContainer.value.scrollTop);

        startX = startY = initialPinchDistance = 0;
        pinchScale = 1;
    }


    /**
     * Load the pdf
     */
    const getPdf = async () => {
      isLoaded.value = false;
      await nextTick();

      const container = document.getElementById("pageContainer");
      const eventBus = new EventBus();

      pdfViewer = new PDFViewer({
        container: container,
        eventBus: eventBus,
      });

      // console.log("HI", pdfViewer.scrollMode)
      pdfViewer.scrollMode = 1;

      // console.log('props.url: ', props.url);
      const pdfDoc = pdfjsLib.getDocument(props.url).promise.then(async function(pdf) {
        console.log("[PDF] pdf loaded");
        isLoaded.value = true;
        numPages.value = pdf.numPages;
        pdfViewer.setDocument(pdf);


        // Tried to scale the pdf pages to the actual size of the window in the lines below.
        // Unfortunately, this is not that easy.
        // You just cannot scale the whole reader via pdfViewer.currentScale... I did not get what size the scale is based on

        // console.log("pdf pdf", pdf)
        // pdf.getPage(1).then(page => {
        //
        //   console.log("container", container.offsetWidth)
        //   console.log("pdf page width", page.view[2])
        //   console.log("scale 1", container.offsetWidth / page.view[2]);
        //
        //   const viewport = page.getViewport({ scale: 1, })
        //   const scale = container.offsetWidth / viewport.width;
        //
        //
        //   console.log("viewport", viewport)
        //   const scaledViewport = page.getViewport({ scale: scale, });
        //   console.log("viewport x2", scaledViewport);
        //   console.log("viewport x2", scale);
        //   console.log("viewport currentScale", pdfViewer.currentScale);
        //
        //   // pdfViewer.currentScale = 2;// / container.offsetWidth * page.view[2];
        //
        //   // console.log("asdf", page.getViewport(canvas.width / page.getViewport(1.0).width))
        // });

        // const doc = await pdfDoc;

        // console.log("pdfjsLib", pdfjsLib, pdfDoc, doc)
      });

      // const test = await pdfjsLib.getDocument(props.url)
      //
      // test.getPage(0).then((page) => {
      //   console.log("testpage", page);
      // })
    }


    /**
     * On Mounted. Executed every time we open this modal
     */
    onMounted(() => {
      // Prevent native iOS page zoom
      //document.addEventListener("touchmove", (e) => { if (e.scale !== 1) { e.preventDefault(); } }, { passive: false });
      getPdf()
      document.addEventListener("keydown", keyDownListener);
      document.addEventListener("touchstart", onTouchStart);
      document.addEventListener("touchmove", onTouchMove,{ passive: false });
      document.addEventListener("touchend", onTouchEnd);

    })

    /**
     * On Unmounted. Executed every time we close this modal
     */
    onUnmounted(() => {
      document.removeEventListener("keydown", keyDownListener);
      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    })


    const zoomIn = () => {
      // options.value.scale = options.value.scale + 0.2;
      pdfViewer.currentScale += 0.2;
    }

    const zoomOut = () => {

      pdfViewer.currentScale -= 0.2;
      // if (options.value.scale > 0.6) {
      //   options.value.scale = options.value.scale - 0.2;
      // }
    }

    const isZoomEnabled = computed(() => {
      return (isPlatform("ios") || isPlatform("ipad") || isPlatform("android") || isPlatform("mobile"));
    });

    return {
      numPages,
      requireMinTouches,
      pageContainer,
      pinchScale,
      viewer,
      isLoaded,
      currentScale,
      isZoomEnabled,
      
      zoomIn,
      zoomOut,
    }
  }
};
</script>

<style lang="scss">

div.page {
  display: block !important;
  margin: 0 !important;
}

.horizontal-scroll {
  //overflow-x: auto;
}


#pageContainer {
  position: relative !important;
  //margin: auto;
  overflow-x: auto;
  overflow: auto;
 height: 100%;
 width: 100%;
}

#viewer {
  overflow-y: hidden;
  overflow-x: hidden;
  width: max-content;
  margin: 0 auto !important;
}

</style>



