<template>
  <ion-button
    :class="`${isPreloadVisible ? 'pos-two' : 'pos-one'} layer-button`"
    size="small"
    color="light"
    @click="isLayerSwitcherOpen = !isLayerSwitcherOpen"
  >
    <ion-icon
      slot="icon-only"
      :icon="layersOutline"
    />
  </ion-button>
  <ion-accordion-group
    v-show="isLayerSwitcherOpen"
    :class="`${isPreloadVisible ? 'pos-three' : 'pos-two'} layer-switcher`"
  >
    <ion-accordion  
      v-for="groupId in Object.keys(displayedLayerStructure)"
      :key="groupId"
      :value="groupId"
    >
      <ion-item
        slot="header"
        color="light"
      >
        <ion-label>{{ groupId }}</ion-label>
      </ion-item>
      <div
        slot="content"
      >
        <ion-item
          v-for="source in displayedLayerStructure[groupId]"
          :key="source.id"
        >
          <ion-checkbox
            :checked="source.checked"
            @ion-change="toggleLayer(source)"
          />
          <ion-label>{{ source.id }}</ion-label>
        </ion-item>
      </div>
    </ion-accordion>
  </ion-accordion-group>
</template>

<script setup lang="ts">
import { IonAccordion, IonAccordionGroup, IonButton, IonCheckbox, IonIcon, IonItem, IonLabel } from "@ionic/vue";
import { layersOutline } from 'ionicons/icons';
import { Map } from "maplibre-gl";
import { ref } from 'vue';
import { Monitoring } from "@/utilities/monitoring";

const props = defineProps({
  map: {
    type: Map,
    required: true
  },
  isPreloadVisible: {
    type: Boolean,
    required: true
  },
  mapStyle: {
    type: Object,
    required: true
  }
});

const isLayerSwitcherOpen = ref(false);
const mapStyleMultilayer = ref(props.mapStyle);
const displayedLayerStructure: any = ref({});

function initDisplayedLayerStructure() {
  try {
    Monitoring.setContext("mapStyle", { name: mapStyleMultilayer.value.name });

    if (!mapStyleMultilayer.value?.layer_toggle_groups) {
      //TODO: check if this an error or a possible case
      //At some pointed the code expected that layer_toggle_groups is not empty
      Monitoring.log("No layer_toggle_groups found in map style");
    }

    Object.keys(mapStyleMultilayer.value?.layer_toggle_groups ?? []).forEach(( groupId: string ) => {
      displayedLayerStructure.value[groupId] = [];
      Object.keys(mapStyleMultilayer.value.sources).forEach((sourceId: string) => {
        //Monitoring.setContext("sources", { sourceId }); // This would be helpful for unhandled exceptions, but it does slow down the loop slightly
        if ( 
          mapStyleMultilayer.value.layer_toggle_groups[groupId].includes( sourceId)
        ) {
          const layers = mapStyleMultilayer.value.layers.filter((layer: any) => {
            return layer.source === sourceId;
          });

          if (layers.length > 0) {
            displayedLayerStructure.value[groupId].push({ id: sourceId, checked: layers[0].layout?.visibility === "none" ? false : true, layers });
          } else {
            Monitoring.withScope((scope) => {
              const context = { sourceId };
              scope.setContext("sources", context);
              Monitoring.error("No layers found for given source id", context);
            });
          }
        }
      });
    });
  } catch (error: any) {
    Monitoring.withScope((scope) => {
      //scope.setContext("sources", context);
      Monitoring.chainError("Error while initializing displayedLayerStructure", error);
    });
  } finally {
    Monitoring.clearContexts();
  }
}

function toggleLayer(source: any) {
  source.checked = !source.checked;  
  mapStyleMultilayer.value.layers.forEach((layer: any) => {
    if (layer.source === source.id) {
      props.map.setLayoutProperty(layer.id, 'visibility', source.checked ? 'visible' : 'none');
    }
  });
}

initDisplayedLayerStructure();

props.map?.on("click", () => {
  if ( isLayerSwitcherOpen.value ) (
    isLayerSwitcherOpen.value = false
  )
});

</script>
<style scoped lang="scss">

.layer-switcher {
  position: absolute;
  left: 8px;
  z-index: 4;
  width: 250px;
  max-height: 300px;
  overflow: scroll;
  border-radius: 4px;
  margin-left: 2px;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
}

ion-checkbox {
  margin-top: 0px;
  margin-bottom: 0px;
}

ion-item {
  --min-height: 36px;
}

ion-label {
  padding-left: 5px;
  font-size: 14px;
  margin: 0px;
}

.pos-one{
  top: 142px;
}

.pos-two{
  top: 180px;
}

.pos-three{
  top: 220px;
}

 .layer-button {
  position: absolute;
  left: 8px;
  z-index: 4;
  width: 30px;
  color: var(--black100);
  background-color: var(--white100);
  border-radius: 4px;;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
  --padding-bottom: 3px;
  --padding-top: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
}

ion-icon {
  color: #282622;
}
</style>