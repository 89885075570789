import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "documentItem" }
const _hoisted_2 = { class: "file-upload-container" }
const _hoisted_3 = {
  class: "file-label flex",
  for: "fileInput"
}
const _hoisted_4 = {
  key: 0,
  class: "flex px-4"
}
const _hoisted_5 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_6 = {
  key: 1,
  class: "flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "dropzone-container rounded-lg flex items-center justify-center",
        style: _normalizeStyle(_ctx.isDragging ? 'background:rgb(239, 239, 239)' : ''),
        onDragover: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.dragover && _ctx.dragover(...args)), ["prevent"])),
        onDragleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.dragleave && _ctx.dragleave(...args))),
        onDrop: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.drop && _ctx.drop(...args)))
      }, [
        (!_ctx.filesDropped)
          ? (_openBlock(), _createElementBlock("input", {
              key: 0,
              type: "file",
              multiple: "",
              name: "file",
              id: "fileInput",
              class: "hidden-input",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
            }, null, 32))
          : _createCommentVNode("", true),
        _createElementVNode("label", _hoisted_3, [
          (!_ctx.filesDropped)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.isDragging)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Release to drop files here."))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, "Drop files or click here to upload."))
              ]))
            : _createCommentVNode("", true)
        ])
      ], 36)
    ])
  ]))
}