<template>
  <div class="map-overlay">
    <div class="relative flex items-center justify-center py-5">
      <ion-button 
        v-if="geometryType"
        fill="clear"
        class="absolute left-2"
        @click="goBack"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="arrowBack"
        />
      </ion-button>
      <div class="headline3">
        {{ t('hzba.selectGeometry') }}
      </div>
      <ion-button
        fill="clear"
        class="absolute right-2"
        @click="closeMenu"
      >
        <ion-icon
          size="large"
          :icon="closeCircleOutline"
        />
      </ion-button>
    </div>
    <div
      v-if="!geometryType"
      class="flex justify-around pb-5"
    >
      <ion-button
        fill="clear"
        @click="setGeometryType('point')"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="locationOutline"
        />
      </ion-button>
      <ion-button
        fill="clear"
        :disabled="true"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="analyticsOutline"
        />
      </ion-button>
      <ion-button
        fill="clear"
        :disabled="true"
      >
        <ion-icon
          slot="icon-only"
          size="large"
          :icon="tabletLandscapeOutline"
        />
      </ion-button>
    </div>
    <div
      v-if="geometryType"
      class="max-h-[20vh] pb-5"
    >
      <ion-grid>
        <ion-row>
          <ion-col
            size="4"
            class="flex items-center justify-center flex-col"
          >
            <AButton
              class="mb-5"
              :btn-primary="true"
              :disabled="isRecordDisabled"
              @click="recordGeometry"
            >
              <ion-label>{{ t('hzba.buttons.recordPoint') }}</ion-label> 
            </AButton>
            <AButton
              class="mb-5"
              :btn-primary="true"
              :disabled="!isRecordDisabled"
              @click="finishRecording"
            >
              <ion-label>{{ t('hzba.buttons.abschliessen') }}</ion-label> 
            </AButton>
          </ion-col>
          <ion-col
            size="8"
            class="pr-4"
          >
            <ion-list class="coordList">
              <ion-item
                v-for="(position, index) in recordedPositions"
                :key="index"
              >
                <ion-label>
                  {{ position.geometry.coordinates[1] }}, {{ position.geometry.coordinates[0] }}
                </ion-label>
                <ion-button
                  fill="clear"
                  @click="handleDelete(position, index)"
                >
                  <ion-icon
                    slot="icon-only"
                    size="medium"
                    :icon="closeCircleOutline"
                  />
                </ion-button>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>
<script setup lang="ts">
import AButton from "@/components/Base/AButton.vue";
import { useStore } from "@/composables/useTypedStore";
import { generateUUID } from "@ionic/cli/lib/utils/uuid";
import { IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonList, IonRow } from "@ionic/vue";
import { analyticsOutline, arrowBack, closeCircleOutline, locationOutline, tabletLandscapeOutline } from 'ionicons/icons';
import { computed, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
    currentPosition: {
        type: Object,
        required: true
    }
});
const emit = defineEmits([
    "recordedGeometry",
    "deleteGeometry"
]);
const store = useStore();
const { t } = useI18n({ useScope: 'global' });
const recordedPositions: Ref<any[]> = ref([]);
const geometryType = ref("");

function setGeometryType(type: string) {
  geometryType.value = type;
}

const isRecordDisabled = computed(() => {
    switch (geometryType.value) {
        case "point":
            return recordedPositions.value.length > 0;
        default:
            return true;
    }   
});

function recordGeometry() {
    const position = { lat: props.currentPosition.lat, lon: props.currentPosition.lon };
    const feature = {
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [position.lon, position.lat],
        },
        properties: {},
        id: generateUUID()
    };
    recordedPositions.value.push(feature);
}

function finishRecording() {
    emit("recordedGeometry", recordedPositions.value[0]); // For now, only point is supported
}

function closeMenu() {
    recordedPositions.value.forEach((position) => {
        emit("deleteGeometry", position?.id);
    });
    recordedPositions.value = [];
    store.dispatch("app/setRecordGeometryOpen", false);
}

function goBack() {
    geometryType.value = "";
}

function handleDelete( position: any, index: number ) {
    recordedPositions.value.splice(index, 1);
    emit("deleteGeometry", position?.id);
}
</script>
<style scoped lang="scss">
.map-overlay {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 1.0);
  border-top: 1px solid var(--grey300);
  max-height: 500px;
}
.coordList {
    max-height: 30vh;
    overflow: scroll;
}
</style>