<template>
  <pdf-a4-page no-margin :pdf-key="lang">
    <div class="report-background w-screen h-screen">
      <page-header :ba ="ba" :immobilie="immobilie" :Title="'Meldungen'"/>
      <div class="flex flex-col justify-between">
        <span class="pageHeader flex justify-between" data-cy="damage-title">Schaden - {{ meldung.frages?.[1].eingabeText || 'Unbekannt' }}</span>
        <hr class="trenner-m"/>
        <div class="content w-100">
          <div class="title-container">
            <div class="text-4xl midHeader">
              Allgemein
            </div>
            <div class="mt-1 flex">
              <span class="w-60">Datum der Meldung:</span>
              <span class="mt-0 w-60" data-cy="pdf-report-date">
                {{ moment(meldung.frages?.[0].eingabeText).isValid() ? moment(meldung.frages?.[0].eingabeText).format("DD.MM.YYYY") : 'Ungültiges Datum' }}
              </span>
            </div>
            <div class="mt-1 flex">
              <span class="w-60">Beschreibung der Fehlermeldung:</span>
              <span  class="mt-0 w-80 textBlock" data-cy="pdf-report-description">
                {{ meldung.frages?.[2].eingabeText }}
              </span>
            </div>
            <div class="mt-1 flex">
              <span class="w-60">Priorität:</span>
              <span class="mt-0 w-80" data-cy="pdf-report-priority">
                {{ meldung.frages?.[1].eingabeText }}
              </span>
            </div>
          </div>
          <div class="title-container">
            <hr class="trenner"/>
            <div class="text-4xl midHeader">
                Schaden - Info
            </div>
            <div class="mt-1 flex">
              <span class="w-60">Schwere des Schadens:</span>
              <span class="mt-0 w-80" data-cy="pdf-damage-severity">
                {{ meldung.frages?.[4].eingabeAuswahlWert }}
              </span>
            </div>
            <div class="mt-3 flex" v-if="meldung?.frages?.[5].eingabeMedien?.[0] != null" >
              <span class="w-20">Fotos:</span>
              <div class="mt-0" data-cy="pdf-picture-action">
                <PdfImageRow :imageObjects="getImageObjects(meldung, 5)"/>
              </div>
            </div>
            <div class="mt-1 flex" data-cy="pdf-picture-action" v-else>
              <span class="w-35">Fotos:</span>
              <div class="mt-0 w-80">
                Keine Fotos
              </div>
            </div>
            <div class="mt-1 flex section">
              <span class="w-60">Schaden behoben:</span>
              <span class="mt-0" data-cy="pdf-damage-repaired" v-if="meldung.frages?.[6].eingabeBoolean == true">
                  Ja
              </span>
              <span v-else class="mt-0" data-cy="pdf-damage-repaired">
                  Nein
              </span>
            </div>
          </div> 
          <div class="title-container">
            <hr class="trenner"/>
            <div class="text-4xl midHeader">
                Maßnahme - Info
            </div>
            <div class="section" v-if="meldung.frages?.[6].eingabeBoolean == true">
              <div class="mt-1 flex">
                <span class="w-60">Reparaturdatum:</span>
                <span class="mt-0 w-40" data-cy="pdf-repair-date">
                  {{ moment(meldung.frages?.[9]?.eingabeText, "YYYY-MM-DD", true).isValid() ? moment(meldung.frages?.[9].eingabeText).format("DD.MM.YYYY") : 'Ungültiges Datum' }}
                </span>
              </div>
              <div class="mt-1 flex">
                <span class="w-60">Beschreibung Maßnahme:</span>
                <span class="mt-0 w-80 textBlock" data-cy="pdf-description-action">
                {{ meldung.frages?.[10].eingabeText }}
                </span>
              </div>
              <div class="mt-3 flex" v-if="meldung?.frages?.[11].eingabeMedien?.[0] != null" >
                <span class="w-20">Fotos:</span>
                <div class="mt-0" data-cy="pdf-picture-action">
                  <PdfImageRow :imageObjects="getImageObjects(meldung, 11)"/>
                </div>
              </div>
              <div class="mt-1 flex" data-cy="pdf-picture-action" v-else>
                <span class="w-35">Fotos:</span>
                <div class="mt-0 w-80">
                  Keine Fotos
                </div>
              </div>
              <div class="mt-1 flex">
                <span class="w-60">Dauer:</span>
                <span class="mt-0" data-cy="pdf-repair-duration">
                  {{ meldung.frages?.[12].eingabeText + "h"}}
                </span>
              </div>
              <div class="mt-1 flex">
                <span class="w-60">Netzeinschaltung möglich:</span>
                <span class="mt-0" data-cy="pdf-switch-on" v-if="meldung.frages?.[13].eingabeBoolean == true">
                  Ja
                </span>
                <span v-else>
                  Nein
                </span>
              </div>
            </div>
            <div class="section" v-else>
              <div class="mt-1 flex">
                <span class="w-60">Bestehender Schaden</span>
                <span class="mt-0 w-80 textBlock" data-cy="pdf-existing-damage">
                  {{ meldung.frages?.[7].eingabeText }}
                </span>
              </div>
              <div class="mt-1 flex">
                <span class="w-60">Notwendige Maßnahme:</span>
                <span class="mt-0 w-80 textBlock" data-cy="pdf-necessary-action">
                  {{ meldung.frages?.[8].eingabeText }}
                </span>
              </div>
            </div> 
          </div>
        </div>
      </div>
      <page-bottom/>
    </div>
  </pdf-a4-page>
</template>
  
<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import moment from 'moment';
  import { useI18n } from 'vue-i18n';
  import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
  import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
  import PdfImageRow from './NewVationImageRow.vue';
  import { Fragenblock } from '@/models/ba/Fragenblock';
  import PageHeader from './PageHeader.vue'
  import Immobilie from "../../../models/immobilie.model";
  import PageBottom from './PageBottom.vue';

  export default defineComponent({
    name: 'MastMeldung',
    components: {
      PdfA4Page,
      PdfImageRow,
      PageHeader,
      PageBottom,
    },
    props: {
      meldung: {
        type: Object as PropType<Fragenblock>,
        required : true,
      },
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required : true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
      lang: {
        type: String,
        default: 'de',
      },
      index: {
        type: Number,
        default: 0,
      }
    },
    methods: {
      getImageObjects(meldung: any, index : number) {
        return (
          meldung?.frages[index].eingabeMedien ?? []
        );
      }
    },
    setup(props: any) {
      const { t } = useI18n({ useScope: 'global' });
      return {
        t,
        moment,
      };
    },
  });
</script>
  
<style scoped lang="scss">
  * {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  .pdf-content { 
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
    height: 1100px;
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 1050px;
    color: var(--black100);
  }
  .pageHeader{
    font-weight: bold;
    font-size: 20px;
  }
  .midHeader{
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
  }
  .smallHeader{
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
  .content {
    height: 830px;
  }
  .content-long {
    height: 895px;
  }
  span{
    padding-left: 10px;
    /*font-size: 10px;*/
  }
  .stackpanel {
    display: flex;
    flex-direction: row; 
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important; 
    width: 740px; 
  }
  .trenner-m {
    @extend .trenner;
    height: 1.5px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    width: 720px; 
  }
  .textBlock {
    padding-right: -20px;
    width: 450px;
  }
  .w-35{
    width: 35.7%;
  }
</style>
  