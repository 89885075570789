import { APhotoUploadTask, uploadFile, uploadPhoto } from "@/api/UploadApi";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Frage } from "@/models/ba/Frage";
import { Mangelzuordnung } from "@/models/ba/Mangelzuordnung";
import Unterschrift from "@/models/hzba/unterschrift.model";
import Immobilie from "@/models/immobilie.model";
import CachedPhoto from "@/models/photo/cached-photo.model";
import StrapiMedia from "@/models/photo/strapi-media.model";
import { instanceOfPhoto } from "@/utilities/get-media-url";
import { logger } from "@/utilities/logging";
import { Monitoring } from "@/utilities/monitoring";
import { b64toBlob, deletePhotoLocal } from "@/utilities/photo-storage";
import { Filesystem } from "@capacitor/filesystem";


export class UploadBaPhotos {

    public uploadFrageMediaTasks: APhotoUploadTask[] = [];
    public uploadMangelMediaTasks: APhotoUploadTask[] = [];
    public uploadSignatureMediaTasks: APhotoUploadTask[] = [];

    /**
     * Get to be reassigned photos from Ba.
     * @param bestandsaufnahme to be uploaded.
     */
    public getPhotoUploadTasks(bestandsaufnahme: Bestandsaufnahme, immobilie: Immobilie) {
        this.uploadFrageMediaTasks = [];
        this.uploadMangelMediaTasks = [];
        this.uploadSignatureMediaTasks = [];

        /** Screen every Frage for images **/
        bestandsaufnahme.getFrages().forEach(frage => {
            this.uploadFrageMediaTasks.push(...frage.getMediaUploadTasks(immobilie, bestandsaufnahme.identifier, true));
        })

        /** Screen every mangel for images **/
        bestandsaufnahme.getFiredMaengel().forEach(mangel => {
            this.uploadMangelMediaTasks.push(...mangel.getMediaUploadTasks(immobilie, bestandsaufnahme.identifier, true));
        });

        /** Screen Unterschrifts for images **/ // todo enable localOnly?
        this.uploadSignatureMediaTasks.push(...bestandsaufnahme.getMediaUploadTasks(immobilie))

        // console.log("[Sync/Photo] Photo upload Tasks, Question media:", this.uploadFrageMediaTasks);
        // console.log("[Sync/Photo] Photo upload Tasks, Mangel media:", this.uploadMangelMediaTasks);
        // console.log("[Sync/Photo] Photo upload Tasks, Signature media:", this.uploadSignatureMediaTasks);
    }

    /**
     * Find instance that belongs to task.uid via uid
     */
    static findInstanceByUidAndRef(objects: { frages: Frage[], mangels: Mangelzuordnung[], unterschrifts: Unterschrift[] }, ref: string, uid: string){
        if (ref === "frages") {
            const frage = objects.frages.find(f => { return f.config?.photoUploadUid && f.config?.photoUploadUid == uid; });
            return frage;
        }

        if (ref === "mangels") {
            const mangel = objects.mangels.find(m => m.config?.photoUploadUid && m.config?.photoUploadUid == uid);
            return mangel;
        }

        const unter = objects.unterschrifts?.find(u => u.uid == uid);
        return unter;
    }

    static getInstances(ba: Bestandsaufnahme) {
        const frages = ba.getFrages();
        const mangels = ba.getFiredMaengel();
        const unterschrifts = ba.unterschrifts || [];
        return { frages, mangels, unterschrifts }
    }
    /**
     * Reassign photos that we've extracted from Ba via getPhotoUploadTasks
     * Assign them directly to the freshly updated ba that only has already uploaded images yet.
     * We do this to ensure that we never loose a local image.
     * After calling this you should call store to local.
     */
    public reassignPhotosToBa(updatedBa: Bestandsaufnahme) {
        // console.log("[Sync/Photo] Pre ReassignedReassign photos", updatedBa,
        //     useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'))

        const instances = UploadBaPhotos.getInstances(updatedBa);

        this.uploadFrageMediaTasks.forEach(task => {
            const frage = UploadBaPhotos.findInstanceByUidAndRef(instances, task.ref, task.uid) as Frage;
            if (frage) {
                frage.eingabeMedien.push(task.image);
                task.instanceId = frage.id;
            }
        })

        this.uploadMangelMediaTasks.forEach(task => {
            const mangel = UploadBaPhotos.findInstanceByUidAndRef(instances, task.ref, task.uid) as Mangelzuordnung;
            if (mangel) {
                mangel.eingabeMedien.push(task.image);
                task.instanceId = mangel.id;
            }
        })

        this.uploadSignatureMediaTasks.find(task => {
            const unter = UploadBaPhotos.findInstanceByUidAndRef(instances, task.ref, task.uid) as Unterschrift;
            if (unter) {
                unter.bild = task.image;
                task.instanceId = unter.id;
            }
        })

        // console.log("[Sync/Photo] Post Reassigned Photo after JSON Updated",
        //     updatedBa, useIdentifierFrageFinder(updatedBa, 'hzba.uebersicht-tab.anlagenschema-group.anlagenschema'));

    }


    public async uploadPhotos(updatedBa: Bestandsaufnahme, store: any): Promise<{ success: ('success' | 'paused' | 'failed'), message?: string }> {

        // this.getPhotoUploadTasks();
        const uploadTasks = [...this.uploadMangelMediaTasks, ...this.uploadFrageMediaTasks, ...this.uploadSignatureMediaTasks];

        uploadTasks.forEach(el => {
            if (instanceOfPhoto(el.image)) {
                el.status = "uploading"
            }
        })

        console.log("[Sync/Photo] Upload photos", uploadTasks)

        for (const task of uploadTasks) {
            if (store.state.currentHzba.pleasePauseSync) {
                console.log("[Sync/Photo] Pausing image sync.")

                store.commit('currentHzba/setPleasePauseSync', false);
                return {
                    success: 'paused',
                    message: 'Upload paused.'
                }
            }
            try {
                console.log("[Sync/Photo] Upload photo...");
                if (task.status !== 'uploaded' && task.status !== 'success') {
                    await UploadBaPhotos.uploadPhotoUploadTask(task, updatedBa, store);
                }
            } catch (err: any) {
                Monitoring.chainError("uploadPhotos failed", err);

                return {
                    success: 'failed',
                    message: `images failed to upload.`
                }
            } finally {
                Monitoring.clearContexts();
            }
        }

        console.log(`Photo upload for BA ${updatedBa.id} successful!`);
        return {
            success: 'success'
        }

    }

    static async uploadPhotoUploadTask(task: APhotoUploadTask, ba: Bestandsaufnahme, store: any) {
        Monitoring.addContext("Task info", { "Task": task, "Survey Id": ba.id });
        try {
            const localImage = task.image as CachedPhoto;

            task.status = "uploading";
            let upload;
            if (localImage.format === 'pdf' || localImage.format === 'gsi') {
                if (!localImage.path) {
                    Monitoring.addContext("Task info", { "Path": localImage.path });
                    Monitoring.error("Uploading pdf not successful: could not find path", localImage);
                    return false;
                }

                const file = await Filesystem.readFile({
                    path: localImage.path
                });
                const base64Data = file.data;
                if (localImage.webPath) {
                    const response = await fetch(localImage.webPath);
                } else {
                    console.log('ERROR fetching pdf/gsi');
                }

                // console.log('uploadPhotoUploadTasks: response:', response);
                const blob = b64toBlob(base64Data as string);
                upload = await uploadFile(blob, task.api, task.instanceId!.toString()!, task.field, task.fileName || localImage.format, undefined, `application/${localImage.format}`);
            } else {
                upload = await uploadPhoto(localImage, task.api, task.instanceId!.toString()!, task.field, task.fileName || 'photo');
            }
            task.status = "success";

            // console.log("uploadPhotoUploadTask", upload);
            const image = upload.data && upload.data.length > 0 && upload.data[0];

            UploadBaPhotos.replaceLocalWithStrapiMedia(image, task, ba)

            await store.dispatch("currentHzba/saveHzbaLocally");

            await deletePhotoLocal(localImage);
        } catch (error: any) {
            task.status = "error";
            throw Monitoring.toBubbleUp("Image upload failed", error);
        }
        return true;    
    }

    static replaceLocalWithStrapiMedia(image: StrapiMedia, task: APhotoUploadTask, ba: Bestandsaufnahme) {
        const instance = UploadBaPhotos.findInstanceByUidAndRef(this.getInstances(ba), task.ref, task.uid)

        if (!instance) {
            Monitoring.addContext("Task info", { "Image": image });
            throw new Error('Could not replace local with strapi photo, instance not found.');
        }

        if (task.ref === 'unterschrift') {
            const unterschrift = instance as Unterschrift;
            // console.log("REPLACE UNTERSCHRIFT", unterschrift, image);
            unterschrift.bild = image;
            return;
        }

        const frageOrMangel = instance as (Frage | Mangelzuordnung)

        const cachedIndex = frageOrMangel.eingabeMedien.findIndex(el => {
            const cachedPhoto = el as CachedPhoto;
            const uploadTaskImage = task.image as CachedPhoto;
            return cachedPhoto.uid === uploadTaskImage.uid && cachedPhoto.uid;
        })

        if (cachedIndex >= 0) {
            console.log("Replace eingabMeiden")
            frageOrMangel.eingabeMedien[cachedIndex] = image;
        } else {
            console.log("Push eingabMeiden")
            frageOrMangel.eingabeMedien.push(image);
        }
    }

    toJson() {
        return {
            uploadFrageMediaTasks: this.uploadFrageMediaTasks,
            uploadMangelMediaTasks: this.uploadMangelMediaTasks,
            uploadSignatureMediaTasks: this.uploadSignatureMediaTasks,
        }
    }

    public countTotalToBeUploadedPhotos() {
        return this.uploadFrageMediaTasks.length + this.uploadMangelMediaTasks.length + this.uploadSignatureMediaTasks.length;
    }

    public getTotalTasks(): APhotoUploadTask[] {
        return [...this.uploadFrageMediaTasks, ...this.uploadMangelMediaTasks, ...this.uploadSignatureMediaTasks];
    }

}