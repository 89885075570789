<template>
  <div
    v-bind="$attrs"
    :class="buttonText !== noFilterLabel ? 'active' : ''"
    @click="openPopover"
  >
    {{ buttonText }}
  </div>
  <ion-popover
    class="ion-datetime-button-overlay"
    :is-open="isOpen"
    :event="popoverEvent"
    :keep-contents-mounted="true"
    @did-dismiss="cancel()"
  >
    <ion-datetime
      multiple
      presentation="date"
      :prefer-wheel="false"
      :locale="locale"
      :value="range"
      :first-day-of-week="locale === 'de' ? 1 : 0"
      :highlighted-dates="defineHighlightStyle"
      @ion-change="handleDateChange"
    />
    <div
      v-show="helpText"
      class="text-sm"
    >
      <ul>
        <li>{{ helpText }}</li>
      </ul>
    </div>
    <div class="flex justify-end">
      <AButton
        class="p-3 bg-transparent"
        @click="cancel()"
      >
        {{ $t("hzba.buttons.abbrechen") }}
      </AButton>
      <AButton
        v-show="props.start"
        class="p-3 bg-transparent"
        @click="removeFilter()"
      >
        {{ $t("hzba.buttons.removeFilter") }}
      </AButton>
      <AButton
        v-show="range.length > 0"
        class="p-3 mr-2 bg-transparent"
        @click="confirm()"
      >
        {{ $t("hzba.buttons.bestaetigen") }}
      </AButton>
    </div>
  </ion-popover>
</template>

<script
  setup
  lang="ts"
>
import AButton from "@/components/Base/AButton";
import { DatetimeHighlightCallback, IonDatetimeCustomEvent } from '@ionic/core';
import { DatetimeChangeEventDetail, IonDatetime, IonPopover } from '@ionic/vue';
import moment from 'moment';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

interface DateRangePickerProps {
  start: string;
  end: string;
}

const props = defineProps<DateRangePickerProps>();
const emit = defineEmits<{
  (e: 'update:range', date: string[]): void;
  (e: 'update:single', date: string): void;
}>();

const { t, locale } = useI18n({ useScope: 'global' });

const startDate = ref<string>(props.start ?? new Date().toISOString());
const endDate = ref<string>(props.end ?? new Date().toISOString());
const isStartDatesTurn = ref(true);

const isOpen = ref(false);
const popoverEvent = ref();

const noFilterLabel = t("hzba.buttons.datefilter");

const format = (date: string) => {
  return date === "" || !moment(date).isValid() ? "" : moment(date).format("YYYY-MM-DD");
};

const range = computed(() => {
  return [startDate.value, endDate.value].filter(x => x !== "");
});

const buttonText = computed(() => {
  const formatting = locale.value === 'de' ? 'DD.MM.YYYY' : 'MM-DD-YYYY';
  if (startDate.value && endDate.value) {
    return `${moment(startDate.value).format(formatting)} - ${moment(endDate.value).format(formatting)}`;
  } else if (startDate.value) {
    return `${moment(startDate.value).format(formatting)}`;
  } else {
    return noFilterLabel;
  }
});

const helpText = computed(() => {
  if (!startDate.value || !endDate.value) {
    return isStartDatesTurn.value ? t("hzba.datefilter.selectStartDate") : t("hzba.datefilter.selectEndDate");
  } else {
    return "";
  }
});

watch(() => props.start, (start) => {
  if (!start) {
    startDate.value = "";
    endDate.value = "";
  }
});

const emitEvents = () => {
  if (startDate.value && endDate.value) {
    emit('update:range', [format(startDate.value), format(endDate.value)]);
  } else {
    emit('update:single', format(startDate.value));
  }
};

const openPopover = (e: Event) => {
  popoverEvent.value = e;
  isOpen.value = true;
};

const cancel = () => {
  startDate.value = props.start;
  endDate.value = props.end;
  isOpen.value = false;
};

const confirm = () => {
  emitEvents();
  isOpen.value = false;
};

const removeFilter = () => {
  startDate.value = "";
  endDate.value = "";
  emitEvents();
  isOpen.value = false;
};

const defineHighlightStyle: DatetimeHighlightCallback = (date: string) => {
  let isDateWithinRange = false;
  if (moment(date).isAfter(startDate.value) && moment(date).isBefore(endDate.value)) {
    isDateWithinRange = true;
  }

  // TODO: get theme colors
  if (isDateWithinRange)
    return {
      backgroundColor: '#4818f717',
    };
};

const handleDateChange = (
  e: IonDatetimeCustomEvent<DatetimeChangeEventDetail>
) => {
  const selectedValues = e.detail.value as string[] | undefined;
  const length = selectedValues?.length ?? 0;
  const isNewSelection = !startDate.value && length > 0 || !endDate.value && length > 1 || length > 2;
  let isAlreadySetThisTurn = false;
  let newDate;
  if (isNewSelection && selectedValues) {
    newDate = selectedValues[length - 1];
  }

  // selecting startDate, if selected date is after endDate, switch them
  if (isNewSelection && newDate && isStartDatesTurn.value) {
    if (endDate.value && moment(newDate).isAfter(endDate.value)) {
      startDate.value = endDate.value; // switch
      endDate.value = newDate;
    } else {
      startDate.value = newDate;
    }
    isAlreadySetThisTurn = true;
    isStartDatesTurn.value = false;
  }

  // selecting endDate, if selected date is before startDate, switch them
  else if (isNewSelection && newDate && !isStartDatesTurn.value && !isAlreadySetThisTurn) {
    if (moment(newDate).isAfter(startDate.value)) {
      isStartDatesTurn.value = true;
      endDate.value = newDate;
    } else {
      endDate.value = startDate.value; // switch
      startDate.value = newDate;
    }
  }

  // if only one date is selected. needs to be inferred because selectedValues are missing the actually clicked date
  else if (startDate.value && endDate.value) {
    const foundStart = selectedValues?.find(x => x === startDate.value);
    const foundEnd = selectedValues?.find(x => x === endDate.value);

    if (!foundStart || !foundEnd) {
      startDate.value = foundStart ? endDate.value : startDate.value; // infer the clicked date
      endDate.value = "";
      isStartDatesTurn.value = false;
    }
  }
  // if all dates are removed
  else if (length === 0) {
    startDate.value = "";
    endDate.value = "";
    isStartDatesTurn.value = true;
  }
};
</script>
<style scoped>
ion-datetime {
  --ion-color-base: var(--primary) !important;
}
</style>
