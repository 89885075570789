import moment from "moment";
import apiClient from "./api";
import axios from 'axios';
import { Monitoring } from "@/utilities/monitoring";

export const generateExcel = (organizationId: number, projectId: number, prefix = "aufnahmen", locale = "de") => {
    const filename = `${prefix}-export-${moment().format('YYYY_MM_DD_HHmmss')}`;
    // TODO: use AbortController when available with axios ^0.22.0
    const cancelToken = axios.CancelToken.source();
    const call = apiClient.post("/generate-excel", { organizationId, projectId, filename, locale }, { responseType: "blob", cancelToken: cancelToken.token })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
        }).catch((error: any) => {
            Monitoring.chainError("Excel export failed", error);
        });
    return { call, abort: cancelToken.cancel };
};