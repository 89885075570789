<template>
  <div class="text-xs" data-cy="pdf-page-header" style="margin-top: 2.5px">
    <div class="flex justify-between py-1 header">
      <div data-cy="header-objNum" style="width: 30%; text-align: start; margin-left: 20px;">
        {{ "Mast: " + immobilie.externeObjektNr }}
      </div>
      <div data-cy="header-title" style="width: 39%; text-align: center;">
        {{ "Schadensmeldungen - " +  Title}}
      </div>
      <div data-cy="header-pages" style="width: 30%; text-align: end; margin-right: 20px;">
        <PageCounter></PageCounter>
      </div>
    </div>
    <hr class="trenner-l"/>
  </div>
</template>
  
<script lang="ts">
  import { useI18n } from "vue-i18n";
  import moment from "moment";
  import { computed, defineComponent, PropType } from "vue";
  import Immobilie from "../../../models/immobilie.model";
  import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
  import PageCounter from "../Components/PageCounter.vue";
  export default defineComponent({
    name: "GeneralPdfPageHeader",
    props: {
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      },
      Title: {
        type: String,
        default: "Zusammenfassung"
      },
      lang: {
        type: String,
        default: "de",
      },
    },
    components: {
      PageCounter
    },
    setup(props) {
      const { t } = useI18n({ useScope: "global" });    
      return {
        moment,
        t,
      };
    },
  });
</script>
  
<style scoped lang="scss">
   * {
    color: black;
    background-color: white;
  }
  .pdf-content {
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    color: var(--black100);
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l{
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important; 
    width: 740px; 
  }
  .pageHeader{
    font-weight: bold;
    font-size: 40px;   
  }
  .midHeader{
    font-weight: bold;
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 5px;
  }
  .smallHeader{
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    margin-left: 10px;
  }
  .content {
    height: 800px;
  }
  span{
    padding-left: 10px;
  }
  .stackpanel {
    display: flex;
    flex-direction: row; 
  }
  .h-15{
    height: 1.5px !important;
  }
  .textBlock{
    margin-left: 115px;
  }
  .header{
    font-weight: lighter;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: -20px;
  }
</style>