<template>
  <pdf-a4-page no-margin :pdf-key="lang" class="pdf-content">
    <div class="landingpage-background w-screen h-screen">
      <div class="flex flex-col justify-between" style="height: 1020px">
        <div class="flex justify-between">
          <span class="landing_text_header text-5xl md:text-7xl">NewVation</span>
          <span>
            <img
              src="../../../../public/assets/img/pdf/newvation/newvation-logo.png"
              width="100"
            />
          </span>
        </div>
        <div class="content">
          <div class="title-container">
            <div class="text-3xl md:text-3xl" data-cy="pdf-coverpage-header">
              Bericht zum Masten
            </div>
            <div class="text-4xl">
            </div>
            <div class="mt-2 text-xl flex">
              <span class="mt-0 w-40">Mast:</span>
              <span class="mt-0" data-cy="pdf-coverpage-street">
              {{immobilie.name}}
            </span>
            </div>
            <div class="text-xl flex mt-2">
              <span class="mt-0 w-40">Anlage:</span>
              <span class="mt-0" data-cy="pdf-coverpage-begehungsdatum">
                {{ immobilie.stadt}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </pdf-a4-page>
</template>


<script lang="ts">
import useUser from '@/composables/useUser';
import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
import Immobilie from '@/models/immobilie.model';
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import {
  useIdentifierFrageFinder,
  getAuswahlLabel,
} from '@/composables/Bestandsaufnahme/useIdentifierFinder';
import { getPhotoUrl } from '@/utilities/get-media-url';
import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';

import PdfChip from '../Components/PdfChip.vue';


export default defineComponent({
  name: 'CoverPage',
  components: {
    PdfA4Page,
    PdfChip,
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: 'de',
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: 'global' });
    const uUser = useUser();

    return {
      user: uUser.user,
      t,
      useIdentifierFrageFinder,
      moment,
      getPhotoUrl,
      getAuswahlLabel,
    };
  },
});
</script>

<style scoped>
* {
  color: white;
}
.pdf-content {
  width: 800px;
  margin: 0 auto;
  color: var(--white100);
  height: 1123px; 
  width: 801px; 
  max-height: 1236px; 
  max-width: 801px;
}

.landingpage-background {
  position: absolute;
  padding: 40px;
  height: 1123px; 
  width: 801px; 
  max-height: 1236px; 
  max-width: 801px;
  background:  #005452;

}
.trenner {
  height: 1px;
  border-width: 0;
  color: gray;
  background-color: gray;
}
.content {
  height: 800px;
}
</style>