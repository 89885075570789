import { BundleInfo, CapacitorUpdater, latestVersion } from '@capgo/capacitor-updater';
import { isPlatform } from "@ionic/vue";
import axios from 'axios';
import { ref } from 'vue';
import { environment } from '../../environments/environment';
import { Monitoring } from '@/utilities/monitoring';

export function useOTA() {
  let latestVersion: latestVersion = {
    version: "",
    url: ""
  };
  
  const isUpdating = ref(false);

  const getCurrentVersion = async () => {
    try {
      const current = await CapacitorUpdater.current();
      console.log('[OVER THE AIR UPDATE] Current version', current);
      return current.bundle.version && current.bundle.version !== "builtin" ? current.bundle.version : environment.BUILD_VERSION;
    } catch (error: any) {
      Monitoring.chainError('[OVER THE AIR UPDATE] Error getting current version:', error);
      return environment.BUILD_VERSION;
    }
  };

  const getLatestVersion = async () => {
    if (!environment.OTA_VERSION_URL || environment.OTA_VERSION_URL === 'OTA') {
      console.info('[OVER THE AIR UPDATE] No env set for OTA_VERSION_URL');
      
      return false;
    }
    console.log('[OVER THE AIR UPDATE] before getlatest');
    try {
      const otaAxios = axios.create();

      otaAxios.defaults.headers = {
        'Cache-Control': 'no-cache',
      };
      const res = await otaAxios({
        //@ts-ignore
        url: environment.OTA_VERSION_URL,
        method: 'GET',
      });

      console.log('[OVER THE AIR UPDATE] latest version response', res.data);
      
      latestVersion = res.data;

      return latestVersion.version;
    } catch (error: any) {
      Monitoring.chainError('[OVER THE AIR UPDATE] Error getting latest version:', error);
      return false;
    }
    
  };

  const updateAvailable = async () => {
    const current = await getCurrentVersion();
    const latest = await getLatestVersion();

    console.log(
      '[OVER THE AIR UPDATE] updateAvailable?',
      current !== latest,
      current,
      latest,
    );

    if (isPlatform('mobileweb') || isPlatform('desktop')) {
      console.log('[OVER THE AIR UPDATE] not supported on web so we return false');
      return false;
    }

    return current !== latest;
  };

  const updateNow = async () => {
    console.log('[OVER THE AIR UPDATE] found an update! updating from url...', latestVersion.url);

    if (!latestVersion?.url) {
      return;
    }

    try {
      isUpdating.value = true;
      const version: BundleInfo = await CapacitorUpdater.download({
        url: latestVersion?.url,
        version: latestVersion?.version || ""
      });
      console.log('[OVER THE AIR UPDATE] latest version, id:', version, version.id);

      await CapacitorUpdater.set({ id: version.id });
      
      console.log('[OVER THE AIR UPDATE] Post update');
    } catch (error: any) {
      Monitoring.withScope((scope, scrub) => {
        scope.setContext('OTAUpdate', { "New Version": scrub(latestVersion.url ?? "") });
        Monitoring.chainError('[OVER THE AIR UPDATE] Error during update:', error);
      });
    } finally {
      isUpdating.value = false;
    }
  };

  return {
    updateNow,
    updateAvailable,
    isUpdating,
  };
}
