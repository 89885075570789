import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a7e9c6e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "spinner-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_AppLinkBanner = _resolveComponent("AppLinkBanner")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ImmobilienIcon = _resolveComponent("ImmobilienIcon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")!
  const _component_ToolbarListIcon = _resolveComponent("ToolbarListIcon")!
  const _component_SettingsIcon = _resolveComponent("SettingsIcon")!
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")!
  const _component_ion_tabs = _resolveComponent("ion-tabs")!
  const _component_dev_box = _resolveComponent("dev-box")!
  const _component_update_screen = _resolveComponent("update-screen")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["loadingSpinnerContainer", { displaySpinner : _ctx.isLoadingSpinner }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_spinner, {
            name: "crescent",
            duration: 650
          })
        ])
      ], 2),
      (_ctx.showAppLink)
        ? (_openBlock(), _createBlock(_component_AppLinkBanner, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_ion_tabs, {
        class: _normalizeClass(_ctx.showAppLink ? 'app-link-shown' : '')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_router_outlet, {
            ref: "ionRouter",
            animated: true
          }, null, 512),
          _withDirectives(_createVNode(_component_ion_tab_bar, {
            slot: "bottom",
            class: _normalizeClass(["ml-background ml-white-text",  _ctx.isDesktopToolbarForbidden ? 'bottomNavigation' : ''])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "tab_properties",
                href: "/properties",
                "data-cy": "toolbar-immobilien",
                class: "ml-background"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ImmobilienIcon, {
                    "is-active": _ctx.routeName === 'propertiesList'
                  }, null, 8, ["is-active"]),
                  _createVNode(_component_ion_label, {
                    class: _normalizeClass(["ml-white-text", { 'ml-forest-text': _ctx.routeName === 'propertiesList' }])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.organisationPrefix}toolbar.immobilien`)), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              }),
              (!_ctx.user.isImmobilienverwalter())
                ? (_openBlock(), _createBlock(_component_ion_tab_button, {
                    key: 0,
                    tab: "tab_main",
                    href: "/bas",
                    class: "ml-background"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ToolbarListIcon, {
                        "is-active": _ctx.routeName === 'bas'
                      }, null, 8, ["is-active"]),
                      _createVNode(_component_ion_label, {
                        class: _normalizeClass(["ml-white-text", { 'ml-forest-text': _ctx.routeName === 'bas' }])
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t(`${_ctx.organisationPrefix}toolbar.bestandsaufnahmen`)), 1)
                        ]),
                        _: 1
                      }, 8, ["class"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_tab_button, {
                tab: "tab_setting",
                href: "/settings",
                "data-cy": "toolbar-einstellungen",
                class: "ml-background"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_SettingsIcon, {
                    "is-active": _ctx.routeName === 'settings'
                  }, null, 8, ["is-active"]),
                  _createVNode(_component_ion_label, {
                    class: _normalizeClass(["ml-white-text", { 'ml-forest-text': _ctx.routeName === 'settings' }])
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("toolbar.einstellungen")), 1)
                    ]),
                    _: 1
                  }, 8, ["class"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]), [
            [_vShow, _ctx.showBottomNavigation]
          ])
        ]),
        _: 1
      }, 8, ["class"]),
      (_ctx.isDev)
        ? (_openBlock(), _createBlock(_component_dev_box, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.isUpdating)
        ? (_openBlock(), _createBlock(_component_update_screen, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}