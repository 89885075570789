import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "propertyNotes" }
const _hoisted_2 = { class: "formContainer" }
const _hoisted_3 = { class: "flex items-end pb-4" }
const _hoisted_4 = { class: "text-center my-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_AButton = _resolveComponent("AButton")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_item, {
        class: "mb-8",
        lines: "none"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_textarea, {
            modelValue: _ctx.currentNote.content,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentNote.content) = $event)),
            autoGrow: true,
            placeholder: _ctx.t('propertyNotes.enterNotePlaceholder'),
            value: _ctx.currentNote.content
          }, null, 8, ["modelValue", "placeholder", "value"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AButton, {
              disabled: _ctx.currentNote.content === '' ? true : false,
              "btn-primary": true,
              onClick: _ctx.postNote
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('propertyNotes.sendButton')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_ion_list, {
      lines: "full",
      class: "listContainer"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedNotes, (item) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            key: item.id,
            class: "listedNote"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_textarea, {
                readonly: true,
                value: item.content
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, {
                    position: "stacked",
                    class: "font-bold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(new Date(item.createdAt).toLocaleDateString('de-DE')), 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_label, {
                    position: "stacked",
                    class: "font-bold"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.creator), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["value"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.canLoadMore)
        ? (_openBlock(), _createBlock(_component_AButton, {
            key: 0,
            "btn-primary": true,
            class: "px-8 py-3 text-md",
            onClick: _ctx.loadMore
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('propertyNotes.loadMoreButton')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}