import { handleOfflineSurveys } from "@/utilities/offlineSurveys";
import { handleOfflineProperties } from "@/utilities/offlineProperties";
import { useProperties } from "@/composables/Property/useProperties";
import { useStore } from "@/composables/useTypedStore";
import { Monitoring } from "./monitoring";

const maxRetries = 5;
const baseDelay = 2000;
let retryCount = 0;
let isPhotoSendingInProgress = false;

async function sendImageData() {
    const { sendPersistedImages } = useProperties();
    try {
        isPhotoSendingInProgress = true;
        console.log("Attempting to send image data.");

        await sendPersistedImages();
        console.log("Image data sent successfully.");

        isPhotoSendingInProgress = false;
        retryCount = 0;
    } catch (error) {
        Monitoring.setContext("RetryCount", { retryCount, baseDelay });
        if (retryCount < maxRetries) {
            retryCount++;
            const delay = baseDelay + retryCount * 1000; // Exponential backoff

            Monitoring.chainError(`Error sending image data. Retrying.`, error);
            setTimeout(() => sendImageData(), delay);
        } else {
            Monitoring.chainError('Max retries reached. Giving up.', error);
            retryCount = 0;
            isPhotoSendingInProgress = false;
        }
    } finally {
        Monitoring.clearContexts();
    }
}

const isOnline = () => {
    const store = useStore();
    return store.getters["app/isOnline"] && store.getters["app/isSocketConnected"];
}

export async function handleOfflineData() {
    await handleOfflineProperties();
    await handleOfflineSurveys();

    if (isOnline() && !isPhotoSendingInProgress) {
        setTimeout(() => {
            if (isOnline() && !isPhotoSendingInProgress) {
                sendImageData()
            }
        }, baseDelay);
    }
}
