<template>
  <ion-page
    v-if="!animationDone"
    style="background: white"
  >
    <WelcomeAnimation @done="() => animationDone = true" />
  </ion-page>
  <ion-page
    v-else
    style="background: white"
  >
    <toolbar />
    <ion-content>
      <div class="login m-4 lg:m-0">
        <div class="form_group">
          <h1 class="ml-title">
            {{ t("einloggen.loginTitel") }}
          </h1>
          <form
            novalidate
            class="flex flex-col"
            @submit.prevent="onSubmit"
          >
            <AInput
              v-model="loginForm.emailAddress.$model"
              data-cy="email"
              :errors="loginForm.emailAddress.$errors"
              :label="t('registrierung.email')"
              :placeholder="'demo@movinglayers.eu'"
              input-type="email"
            />

            <AInput
              v-model="loginForm.password.$model"
              :errors="loginForm.password.$errors"
              class="mt-4"
              :label="t('registrierung.passwort')"
              :placeholder="'moverssurvey23!'"
              input-type="password"
              data-cy="passwort"
            />

            <AButton
              type="submit"
              :btn-primary="true"
              class="py-6 text-lg"
              data-cy="button-login"
              style="margin-top: 32px !important; margin-left: 0"
              :disabled="loading"
            >
              <span v-if="!loading">{{ t("buttons.login") }}</span>
              <ion-spinner
                v-if="loading"
                color="light"
                size="small"
              />
            </AButton>

            <p class="text-sm">
              {{ t("einloggen.nurFuerRegistrierteNutzerInfo") }}
            </p>

            <offline-hint>{{ t("alerts.keinInternet") }}</offline-hint>
          </form>
        </div>
      </div>

      <FooterComp />
    </ion-content>
  </ion-page>
</template>

<script>
  import AButton from "@/components/Base/AButton";
import AInput from "@/components/Base/AInput";
import FooterComp from "@/components/FooterComp";
import Toolbar from "@/components/Navigation/Toolbar";
import OfflineHint from "@/components/Other/OfflineHint";
import WelcomeAnimation from "@/components/Other/WelcomeAnimation";
import useAlert from "@/composables/useAlert";
import useAuth from "@/composables/useAuth";
import useFetchData from "@/composables/useFetchData";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import { logger } from "@/utilities/logging";
import { Monitoring } from "@/utilities/monitoring";
import {
IonButton,
IonContent,
IonPage,
IonSpinner,
isPlatform,
onIonViewWillEnter,
useIonRouter
} from "@ionic/vue";
import { useVuelidate } from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { defineComponent, reactive, ref, toRef, watch } from "vue";
import { useI18n } from 'vue-i18n';

  export default defineComponent ({
    name: "Login",
    components: {
      OfflineHint,
      WelcomeAnimation,
      Toolbar,
      FooterComp,
      AButton,
      AInput,
      IonButton,
      IonPage,
      IonSpinner,
      IonContent
    },

    setup() {
      const { t } = useI18n({ useScope: 'global' })
      const router = useIonRouter();
      const auth = useAuth();
      const alert = useAlert();
      const store = useStore();
      const animationDone = ref(!isPlatform('hybrid'));
      const useData = useFetchData();
      const loading = ref(false);

      const loginData = reactive({
        emailAddress: '',
        password: '',
        save: true,
      });

      const rules = {
        emailAddress: {
          required: helpers.withMessage('Email erforderlich', required),
          email: helpers.withMessage('Email ungültig', email),
        },
        password: {
          required: helpers.withMessage('Passwort erforderlich', required),
        },
        save: {},
      };

      const loginForm = useVuelidate(rules, {
        emailAddress: toRef(loginData, 'emailAddress'),
        password: toRef(loginData, 'password'),
        save: toRef(loginData, 'save'),
      });


      const onSubmit = async () => {
        loginForm.value.$touch();

        if (loginForm.value.$invalid) {
          console.log('Login form invalid:');
          return;
        }

        try {
          loading.value = true;
          await auth.login(loginData.emailAddress, loginData.password)
        
          loading.value = false;
          await handleUserRedirect()
        } catch (err) {
          loading.value = false;
          if (err.status === 'Network Error' || err.message === 'Network Error') {
            await alert.show(t("alerts.keinInternetHead"),t("alerts.keinInternetText"));
          } else if (err.response?.status.toString().startsWith("4")) {
            await alert.show(t("alerts.loginFehlgeschlagenHead"), t("alerts.loginFehlgeschlagenText"));
            loginData.password = '';
          } else {
            await alert.show(t("alerts.unerwarteterFehlerHead"), t("alerts.unerwarteterFehlerText"));
            Monitoring.chainError('Login fehler', err);
          }
        }
      };

      const handleUserRedirect = async () => {
        const user = useUser().user.value
        if(user.organisation.projects.length > 1) {
          router.navigate('/project-selection', 'forward', 'pop')
        } else {
          await store.commit('user/storeCurrentUserProject', user.organisation.projects[0])
          await useData.doRefresh();
          router.navigate('/', 'forward', 'pop')
        }
      }

      const debugSubmit = async () => {
        loginData.emailAddress = 'mac@gisolutions.at';
        loginData.password = 'Testpasswort01!';
        await onSubmit();
      };

      onIonViewWillEnter(() => {
        store.commit('app/hideBottomNavigation')
      });

      watch(() => animationDone.value, (newValue, oldValue) => {
        store.commit('app/setWelcomeAnimationDone', newValue)
      }, { immediate: true })

      return {
        loginForm,
        loginData,
        onSubmit,
        debugSubmit,
        animationDone,
        t,
        loading
      }
    }
  })
</script>

<style scoped lang="scss">

ion-page {
  --background: var(--white100);
}

  ion-content {
    --background: var(--white100);
    @media (max-width: 728px) {
      --padding-top: 24px !important;
      --padding-top: unquote('constant(safe-area-inset-top))') !important;
      --padding-top: env(safe-area-inset-top) !important;

    }

    --padding-top: unquote('constant(safe-area-inset-top))') !important;
    --padding-top: env(safe-area-inset-top) !important;

  }

  .login {
    min-height: 90vh;
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white100);

    @media (max-height: 650px) {
      margin-top: 0;
      min-height: 75vh;
    }
  }

  .form_group {
    top: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .ml-title {
    font-size: 64px;
  }

</style>