import { io, Socket } from 'socket.io-client';
import { computed } from 'vue';
//Api
import { strapiSocketUrl } from "@/api/api";
//Composables
import { useStore } from "@/composables/useTypedStore";
//Models
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
//Helpers
import useToasts from "@/composables/useToasts";
import User from '@/models/user';
import { retryHelper } from '@/utilities/api-helpers';
import { updateNestedObject } from "@/utilities/helper";
import { Network } from '@capacitor/network';
import { Monitoring } from '@/utilities/monitoring';

let socket: Socket | null = null;

export default function useBestandsaufnahmeSockets() {
  const store = useStore();
  const currentBa = computed(() => store.state.currentHzba.currentBa);
  const user = User.query().first();
  let networkStatus = null as any;
  const currentProject = computed(() => store.state.user.currentUserProject);
  const networkConnected = computed(() => store.state.app.networkConnected);
  const isSocketConnected = computed(() => store.state.app.socketConnected); // to distinguish between disconnected and not-connected-initially
  const retryConnection = retryHelper({ infinite: true });
  const retryAfterError = retryHelper({ retries: 3 });
  const toast = useToasts();

  Network.addListener("networkStatusChange", async (status: any) => {
    if (status.connected) {
      await connect();
    } else {
      disconnect();
    }
  });

  async function checkNetworkStatus() {
    networkStatus = (await (Network.getStatus())).connected;
    return networkStatus;
  }

  async function reconnect() {
    socket = null;
    // eslint-disable-next-line @typescript-eslint/no-use-before-define -- connect and reconnect call each other
    connect();
  }

  async function connect() {
    if (await checkNetworkStatus() === false) return;
    if (!socket) {
      socket = io(strapiSocketUrl);
      socket.connect();

      socket.on("connect", () => {
        console.log('Socket connected');
        store.commit("app/setSocketConnectionState", socket?.connected ?? false);
      });

      socket.on("connect_error", () => {
        if (!socket?.active) {
          retryConnection(reconnect).catch(() => console.log("Not able to create initial socket connection."));
        } // else socketio will try to reconnect automatically
      });

      socket.on("disconnect", (reason) => {
        console.log('Socket disconnected because of', reason);
        store.commit("app/setSocketConnectionState", false);

        if (reason === "io server disconnect") {
          // the disconnection was initiated by the server, you need to reconnect manually
          retryConnection(reconnect).catch(() => console.log("Not able to reconnect to socket."));
        }
        // else the socket will automatically try to reconnect
      });
    }
  }

  function disconnect() {
    if (socket && networkConnected.value) {
      socket.disconnect();
      store.commit("app/setSocketConnectionState", false);
      socket = null;
    }
  }

  function subscribeToBestandsaufnahme() {
    if (socket && socket.connected && networkConnected.value) {
      if (currentBa?.value?.id) {
        socket.emit('subscribeToBestandsaufnahme', { baId: currentBa.value.id, userId: user?.id, projectId: currentProject.value.id });
        console.log('subscribed to', currentBa.value.id, user?.id);
      } else {
        Monitoring.error("No current survey set but trying to subscribe to survey");
      }
    }
  }

  function unsubscribeFromBestandsaufnahme() {
    if (socket && socket.connected && networkConnected.value) {
      if (currentBa?.value?.id) {
        socket.emit('unsubscribeFromBestandsaufnahme', currentBa.value.id);
        console.log('unsubscribed from', currentBa.value.id);
      } else {
        Monitoring.error("No current survey set but trying to unsubscribe from survey");
      }
    }
  }

  if (socket && networkConnected.value) {
    socket.on('bestandsaufnahmeUpdated', (data: { frageData: any, baId: number }) => {
      handleSocketEvent(data);
      console.log('bestandsaufnahme updated', socket?.id);
    });
  }

  const handleSocketEmit = (data: any) => {
    if(socket && networkConnected.value) {
      const frageData = {
        id: data.id,
        identifier: data.identifier,
        title: data.getTitle(),
        eingabeJson: data.eingabeJson,
        eingabeMehrfachAuswahlWerts: data.eingabeMehrfachAuswahlWerts,
        eingabeText: data.eingabeText,
        eingabeZahl: data.eingabeZahl,
        eingabeBoolean: data.eingabeBoolean,
        eingabeAuswahlWert: data.eingabeAuswahlWert
      };

      // should we assume correct types when communicating with backend?
      const errorHandler = (error: any) => {
        retryAfterError(handleSocketEmit, data).catch(() => {
          error && error.frage && toast.updateErrorBestandsaufnahme(error.frage.id, error.frage.title);
          console.log('bestandsaufnahme updated error', error);
        });
      };
      socket.emit('updateBestandsaufnahme', { frageData, baId: currentBa.value.id, userId: user?.id }, errorHandler);
      console.log('bestandsaufnahme frage sent to backend', currentBa.value.id);
    }
  };

  async function handleSocketEvent(data: { frageData: any, baId: number }) {
    // check if the updated bestandsaufnahme is the current one, maybe the un-subscription was not successful
    if(data.baId === currentBa.value.id) {
      const frageData = data.frageData;
      const updatedBa = updateNestedObject(currentBa.value, frageData.id, frageData.identifier, frageData);

      if (updatedBa) {
        store.commit('currentHzba/setCurrentBa', updatedBa);
        const updatedBaJson = await updatedBa.toClassJson();
        await BestandsaufnahmeModel.update({ where: updatedBa.id, data: updatedBaJson });
        await BestandsaufnahmeModel.dispatch('$updateLocally', { data: updatedBaJson });
        // const modelBa = await BestandsaufnahmeModel.find(updatedBa.id)
        // await BestandsaufnahmeModel.dispatch('addToPersistedBestandsaufnahmes', modelBa);
      }
    }
  }

  return {
    connect,
    disconnect,
    subscribeToBestandsaufnahme,
    unsubscribeFromBestandsaufnahme,
    handleSocketEmit,
    isSocketConnected
  };
}