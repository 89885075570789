<template>
  <div>
    <div class="boxed-container text-center mt-16">
      <ion-button
        fill="clear"
        color="danger"
        class="d-button"
        @click="displayAlert()"
      >
        {{ t("buttons.loeschen") }}
      </ion-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import useAlert from '@/composables/useAlert';
import { IonButton } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

const emit = defineEmits(['delete', 'save'])
const { t } = useI18n({ useScope: 'global' })
const alert = useAlert();

function displayAlert() {
  alert.showAdv(
    t("alerts.deleteTitle"),
    t("alerts.deleteDescription"),
    [
      { text: t("alerts.deleteCancel"), },
      { text: t("alerts.deleteConfirm"), handler: () => { emit('delete')} },
    ]
  )
}
</script>

<style scoped lang="scss">
.d-button {
  width: 100%;
  white-space: break-spaces;
  height: unset;
  min-height: 48px;
  display: flex;
  align-items: center;
}
</style>
