import { Environments } from "@/types/app/environment";
import {
    captureException, captureMessage, getCurrentScope, getGlobalScope, init,
    Scope, setContext, setTag, setUser, withScope
} from "@sentry/capacitor";
import { captureConsoleIntegration, init as initVue, vueIntegration } from "@sentry/vue";
import { App } from "vue";
import { chainError, isCauseSupported, MSError } from "./custom-error";
import { scrub, scrubSensitiveFields } from "./scrub-sensitive-fields";

/* *************************************

Introduction to the usage of this class:
https://movinglayers.atlassian.net/wiki/spaces/MS/pages/460324870/Sentry+utilization

************************************** */

type SentryInit = {
    app: App;
    dsn?: string;
    environment: Environments;
    release: string;
    enabled?: boolean;
};

type MonitoringConfiguration = {
    consoleErrorEnabled?: boolean;
    consoleLogEnabled?: boolean;
    sentryErrorEnabled?: boolean;
    sentryLogEnabled?: boolean;
    captureConsoleEnabled?: boolean;
    reuseSentryContextInConsole?: boolean;
};

type AllowedTags = "project" | "network.status" | "debugging";

/**
 * Monitoring class is a static wrapper for Sentry and console logging.
 * @class Monitoring
 */
export class Monitoring {
    private static consoleErrorEnabled = true;
    private static consoleLogEnabled = true;
    private static sentryErrorEnabled = true;
    private static sentryLogEnabled = true;
    private static captureConsoleEnabled = false; //TODO: does not work with newest sentry npm versions
    private static reuseSentryContextInConsole = true;

    private static sentryInitialized = false;
    private static currentContexts: string[] = [];
    private static rememberedKeys: Record<string, any> = {};

    static configure(configuration: MonitoringConfiguration) {
        Monitoring.consoleErrorEnabled = configuration.consoleErrorEnabled ?? Monitoring.consoleErrorEnabled;
        Monitoring.consoleLogEnabled = configuration.consoleLogEnabled ?? Monitoring.consoleLogEnabled;
        Monitoring.sentryErrorEnabled = configuration.sentryErrorEnabled ?? Monitoring.sentryErrorEnabled;
        Monitoring.sentryLogEnabled = configuration.sentryLogEnabled ?? Monitoring.sentryLogEnabled;
        Monitoring.captureConsoleEnabled = configuration.captureConsoleEnabled ?? Monitoring.captureConsoleEnabled;
        Monitoring.reuseSentryContextInConsole = configuration.reuseSentryContextInConsole ?? Monitoring.reuseSentryContextInConsole;
    }

    /**
     * Initialize Sentry with the provided configuration. Optionally, pass a monitoring configuration to override defaults.
     * @param sentryConfiguration DSN, environment, release, etc.
     * @param monitoringConfiguration Optional configuration for console and Sentry logging
     */
    static initializeSentry(
        { app, dsn, environment, release, enabled }: SentryInit,
        monitoringConfiguration?: MonitoringConfiguration
    ) {
        monitoringConfiguration && Monitoring.configure(monitoringConfiguration);

        if (Monitoring.sentryErrorEnabled && !Monitoring.sentryInitialized) {
            try {
                const initOptions = {
                    enabled: enabled ?? true, // TODO: set from environment variable development/production
                    app,
                    dsn: dsn || "https://b7db3548c5e59380a024ee3c242c2345@o4507222280044544.ingest.de.sentry.io/4507222438969424",
                    integrations: [
                        //replayIntegration({ maskAllText: true, blockAllMedia: true }),
                        vueIntegration({ app, tracingOptions: { trackComponents: true }, attachProps: true }), //TODO: does not work with newest sentry npm versions
                        //browserTracingIntegration()
                    ],
                    tracesSampleRate: 1.0,
                    tracePropagationTargets: ["localhost"],
                    replaysSessionSampleRate: 0.0,
                    replaysOnErrorSampleRate: 0.0,
                    environment,
                    release
                };
                if (Monitoring.captureConsoleEnabled) {
                    initOptions.integrations.push(captureConsoleIntegration({ levels: ["error"] }));
                }

                init(initOptions, initVue);
                Monitoring.sentryInitialized = true;

                if (!isCauseSupported) {
                    Monitoring.log("Error 'cause' option is not supported");
                }
            } catch (error: any) {
                console.error("Error initializing Sentry", error);
            }
        }
    }

    /**
     * Logs to console and/or sends a "captureMessage" to Sentry depending on configuration
     * @param message The message to be logged equivalently to console.log
     * @param optionalParams 
     */
    static log(message: string, ...optionalParams: any) {
        if (Monitoring.consoleLogEnabled) {
            console.log(message, ...optionalParams);
        }
        if (Monitoring.sentryLogEnabled && Monitoring.sentryInitialized) {
            // TODO: send optionalParams?
            captureMessage(message); //TODO: Severity second parameter or context object, set some traceId or sessionId?
        }
    }

    /**
     * Logs error to console and/or sends a "captureException" to Sentry depending on configuration.
     * @param error The error to be logged equivalently to console.error. If string, it will be converted to an Error object before sending to Sentry
     * @param optionalParams Optional parameters to log to console. For Sentry context utilities like setContext or withScope are required to use.
     */
    static error(error: Error | string, ...optionalParams: any) {
        if (Monitoring.consoleErrorEnabled) {
            let params;
            if (Monitoring.reuseSentryContextInConsole && optionalParams.length === 0) {
                const sentryContext = getCurrentScope?.()?.getScopeData?.()?.contexts ?? {};
                params = [sentryContext];
            } else {
                params = optionalParams;
            }
            console.error(Monitoring.captureConsoleEnabled && typeof error === "string" ? new Error(error) : error, ...params);
        }
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            captureException(typeof error === "string" ? new Error(error) : error);
        }
    }

    /**
     * Chains a custom message and an existing error together to a new error object and logs it to Sentry and/or console.
     * @param customErrorMessage A custom error message
     * @param error A caught error or an error constructed with `new Error()`
     */
    static chainError(customErrorMessage: string, error: any) {
        if ((Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) || Monitoring.consoleErrorEnabled) {
            Monitoring.error(chainError(customErrorMessage, error), error);
        }
    }

    /**
     * A static wrapper method for chaining errors if the errors are supposed to "bubble up" (or to be re-thrown respectively) 
     * Chains together a custom error message and an existing error to a new error object similar as `Monitoring.chainError`.
     * Use if the `Monitoring` class is already available/imported, otherwise there is also a stateless `chainError` function as alternative.
     * Note: This function is returning a new error object instead of logging it to Sentry or console.
     * @param customErrorMessage A custom error message
     * @param error A caught error or an error constructed with `new Error()`
     * @returns The new error object
     */
    static toBubbleUp(customErrorMessage: string, error: any) {
        return chainError(customErrorMessage, error);
    }

   /**
    * Use withScope to set Sentry context only for the insides of the callback.
    * If Sentry is not enabled but console is, the error will still be logged to console.
    * @param callback The callback function
    * @param callback.scope The Sentry scope object. Unlike our `Monitoring` class this offers the complete Sentry API
    * @param callback.scrubFunc A scrub function to use for sensitive fields (Optional)
    * @throws an exception if an error occurs in the callback e.g. while accessing object properties
    */
    static withScope = (callback: ((scope: Scope, scrubFunc: typeof scrub) => any)) => {
        try {
            return withScope(scope => {
                callback(scope, scrub);
            });
        } catch (error: any) {
            // setting contexts often requires accessing object properties, which is error prone
            Monitoring.chainError("Error in withScope callback", error);
        }
    };

    /**
     * Sets a context for Sentry if enabled. Works similarly to Sentry's native `setContext`.
     * This will overwrite the context for the given key.
     * See an example here: https://docs.sentry.io/platforms/javascript/enriching-events/context/#structured-context
     * @param key Context key. This becomes a section in the Sentry detail page of an issue.
     * @param context Sentry context object. The keys/values will be listed in the above section.
     * @param scrubContext If scrubContext is true, sensitive fields will be scrubbed.
     * @returns A function to clear the context but only for the given key
     */
    static setContext(key: string, context: any, scrubContext = false) {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            setContext(key, scrubContext ? scrubSensitiveFields(context) : context);
            Monitoring.currentContexts.push(key);
        }
        // Return a function to clear the context
        return () => setContext(key, null);
    }

    /**
     * An alternative to `setContext` that merges the given context with the existing context for the given key.
     * @param key Context key. This becomes a section in the Sentry detail page of an issue.
     * @param context Sentry context object. The keys/values will be listed in the above section.
     * @param scrubContext If scrubContext is true, sensitive fields will be scrubbed.
     * @returns A function to clear the context but only for the given key
     */
    static addContext(key: string, context: any, scrubContext = false) {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            if (Monitoring.rememberedKeys[key]) {
                Monitoring.rememberedKeys[key] = { ...Monitoring.rememberedKeys[key], ...context };
            } else {
                Monitoring.rememberedKeys[key] = context;
            }
            return Monitoring.setContext(key, Monitoring.rememberedKeys[key], scrubContext);
        }
        // Return a function to clear the context
        return () => setContext(key, null);
    }

    /**
     * Clears all contexts set with `setContext` or `addContext`.
     */
    static clearContexts() {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            Monitoring.currentContexts.forEach(context => {
                setContext(context, null);
            });
            Monitoring.currentContexts = [];
        }
    }

    /**
     * Global context is a context that is sent with every event to Sentry.
     * Will be active until the key is cleared manually with value `null`
     * @param key Context key. This becomes a section in the Sentry detail page of an issue.
     * @param context Sentry context object. The keys/values will be listed in the above section.
     * @param scrubContext If scrubContext is true, sensitive fields will be scrubbed.
     */
    static setGlobalContext(key: string, context: any, scrubContext = false) {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            getGlobalScope?.()?.setContext(key, scrubContext ? scrubSensitiveFields(context) : context);
        }
    }

    /**
     * Sets a tag and a value for Sentry if enabled. Works similarly to Sentry's native `setTag`.
     * In contrast to contexts, tags are indexed and searchable in Sentry.
     * Add new tags to `AllowedTags` type if needed, but try to consolidate tags whenenver possible to enable better searching.
     * See an example here: https://docs.sentry.io/platforms/javascript/enriching-events/tags/
     * @param key Tag key. This becomes a section in the Sentry detail page of an issue. See `AllowedTags` for possible values.
     * @param value Tag value. The values will be listed in the above section.
     * @param scrubValue If scrubValue is true, the value will be scrubbed.
     */
    static setTag(key: AllowedTags, value: string, scrubValue = false) {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            setTag(key, scrubValue ? scrub(value) : value);
        }
    }

    /**
     * Sets a user for Sentry if enabled. Works similarly to Sentry's native `setUser`.
     * @param user An user with `id` and `username` properties.
     * @throws an exception if the user object is not valid
     */
    static setUser(user: any) {
        if (Monitoring.sentryErrorEnabled && Monitoring.sentryInitialized) {
            if (!Array.isArray(user) && user?.id) {
                setUser({ id: user.id, username: user.username });
            } else {
                Monitoring.error("User object is not valid, cannot set Sentry user data", user);
            }
        }
    }

    /**
     * Decorates a stateless/pure function that should not be polluted with side effects like `Monitoring` utilities.
     * The return value of the stateless function must be a `MSError` object.
     * Should be used mostly for functions that have a more complex internal try/catch error handling.
     * Each `catch` can return a specific `MSError` object.
     * @param fn The stateless function that returns a `MSError` object
     * @returns The new function that will execute the stateless function and, if an error occurs, 
     * will handle the Sentry/console logging and optionally return a fallback value
     */
    static decorate<K extends any[], T extends MSError | any>(fn: (...args: K) => T) {
        return (...args: K): T | MSError["fallback"] => {
            const value = fn(...args);

            if (value && typeof value === "object") {
                //@ts-ignore It is ensured that value is an object but TypeScript throws an error when compiling
                if ("custom" in value && "error" in value && "fallback" in value) {
                    const errorValue = value as MSError;
                    Monitoring.chainError(errorValue.custom, errorValue.error);
                    return errorValue.fallback as MSError["fallback"];
                }
            }
            return value;
        };
    }

    /**
     * Decorates a stateless/pure function that should not be polluted with side effects like `Monitoring` utilities.
     * An alternative to `Monitoring.decorate` if the stateless function does not return a `MSError` object.
     * `decorateWithErrorHandling` is probably more suitable than `decorate` for stateless utility functions,
     * that don't have try/catch handling but leave this to the outside app/caller.
     * @param fn The stateless function
     * @param errorMessage The error message to log to Sentry/console
     * @param fallback A fallback value if an error occurs
     * @returns The new function that will execute the stateless function and, if an error occurs, 
     * will handle the Sentry/console logging and return a fallback value
     */
    static decorateWithErrorHandling<K extends any[], T, Z>(fn: (...args: K) => T, errorMessage: string, fallback: Z) {
        return (...args: K): T | Z => {
            try {
                return fn(...args);
            } catch (error: any) {
                Monitoring.chainError(errorMessage, error);
                return fallback;
            }
        };
    }

    /**
     * A helper function to be used in classes when a default context comes in handy no matter where an error occurs in the class.
     * @param defaultContextKey The default context key for the class. This will become a separate section in the Sentry detail page of an issue.
     * @param getCurrentDefaultContext A callback that returns the current default context at the time of the error occurence.
     * @returns A monitoring utility similar to `Monitoring` with some of the same methods, but with the addition of setting the default context in case of an error. 
     * The API of the utility can be extended as needed.
     */
    static classHelper = (defaultContextKey: string, getCurrentDefaultContext: () => { [key: string]: any; } | null) => {
        return {
            withScope: (callback: ((scope: Scope, scrubFunc: typeof scrub) => any)) => {
                try {
                    return withScope(scope => {
                        scope.setContext(defaultContextKey, getCurrentDefaultContext());
                        callback(scope, scrub);
                    });
                } catch (error: any) {
                    // setting contexts often requires accessing object properties, which is error prone
                    Monitoring.chainError("Error in withScope callback", error);
                }
            },
            error: (error: Error | string, ...optionalParams: any) => Monitoring.withScope((scope) => {
                scope.setContext(defaultContextKey, getCurrentDefaultContext());
                Monitoring.error(error, ...optionalParams);
            }),
            chainError: (customErrorMessage: string, error: any) => Monitoring.withScope((scope) => {
                scope.setContext(defaultContextKey, getCurrentDefaultContext());
                Monitoring.chainError(customErrorMessage, error);
            }),
            // add other methods as needed, but set the default context in each method
        };
    };

    /**
     * Disables all console and Sentry functionalities.
     */
    static disableAll() {
        Monitoring.consoleLogEnabled = false;
        Monitoring.consoleErrorEnabled = false;
        Monitoring.sentryLogEnabled = false;
        Monitoring.sentryErrorEnabled = false;
    }

    /**
     * Disables console.error logging.
     */
    static disableConsoleError() {
        Monitoring.consoleErrorEnabled = false;
    }

    /**
     * Disables Sentry error logging.
     */
    static disableSentryError() {
        Monitoring.sentryErrorEnabled = false;
    }

    /**
     * Disables console.log logging.
     */
    static disableConsoleLog() {
        Monitoring.consoleLogEnabled = false;
    }

    /**
     * Disables Sentry message logging.
     */
    static disableSentryLog() {
        Monitoring.sentryLogEnabled = false;
    }

    /**
     * Enables all console and Sentry functionalities.
     */
    static enableAll() {
        Monitoring.consoleLogEnabled = true;
        Monitoring.consoleErrorEnabled = true;
        Monitoring.sentryLogEnabled = true;
        Monitoring.sentryErrorEnabled = true;
    }

    /**
     * Enables console.error logging.
     */
    static enableConsoleError() {
        Monitoring.consoleErrorEnabled = true;
    }

    /**
     * Enables Sentry error logging.
     */
    static enableSentryError() {
        Monitoring.sentryErrorEnabled = true;
    }

    /**
     * Enables console.log logging.
     */
    static enableConsoleLog() {
        Monitoring.consoleLogEnabled = true;
    }

    /**
     * Enables Sentry message logging.
     */
    static enableSentryLog() {
        Monitoring.sentryLogEnabled = true;
    }
}