export function visibilityWatcher(element: any, callback: any, onlyOnce = false) {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                callback();
                if (onlyOnce) {
                    observer.unobserve(element);
                }
            }
        });
    });

    observer.observe(element);
}