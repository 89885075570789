import AuthApi from "@/api/AuthApi";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import Immobilie from "@/models/immobilie.model";
import User from "@/models/user";
import { removeCache } from "@/store/storage/ionicStorage";
import { store } from '@/store/store';
import { logger } from "@/utilities/logging";
import { Monitoring } from "@/utilities/monitoring";

export const saveJwt = (jwt: string) => {
    localStorage.setItem("token", jwt);
    //logger.defaultMeta.jwt = jwt;
};

export const loadJwt = (): (string | null) => {
    return localStorage.getItem("token");
};

export const deleteJwt = () => {
    return localStorage.removeItem("token")
};

export function parseJwt (token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isTokenExpired (token: string) {
    const tok = parseJwt(token);
    return tok.exp < (Date.now() / 1000);
}

export default function useAuth() {

    function hasToken() {
        const token = loadJwt();

        if (token && useAuth().isTokenExpired(token)) {
            localStorage.removeItem("token")
        }
        return !!loadJwt();
    }

    async function login(email: string, pass: string) {
        const res = await AuthApi.loginByEmailPassword(email, pass);

        const {jwt, user} = res.data;
        // logger.defaultMeta.jwt = jwt;
        // logger.defaultMeta.userId = user.id;
 
        saveJwt(jwt);

        await User.create({data: user});

        Monitoring.setUser(user);
        console.log(`User ${user.id} (${user.role.name}) logged in`);
        // logger.info(`User ${user.id} (${user.role.name}) logged in`);

        return;
    }

    async function signup(userData: any) {
        const res = await AuthApi.signup(userData);

        const {jwt, user} = res.data;
        // logger.defaultMeta.userId = user.id;
        // logger.defaultMeta.jwt = jwt;

        saveJwt(jwt);

        await User.create({data: user});
        //logger.info(`User ${user.id} (${user.role.name}) signed up`);
        console.log(`User ${user.id} (${user.role.name}) signed up`);
        return;
    }

    async function changePassword(data: any) {
        const res = await AuthApi.changePassword(data);

        const {jwt, user} = res.data;
        // logger.defaultMeta.userId = user.id;
        // logger.defaultMeta.jwt = jwt;

        saveJwt(jwt);
        // logger.info(`User ${user.id} (${user.role.name}) changed password`);
        console.log(`User ${user.id} (${user.role.name}) changed password`);

        return;
    }

    const logout = async () => {
        const user = User.query().first();
        const userInfo = `User ${user?.id} (${user?.role.name})`;
        console.log(`${userInfo} logging out`);

        deleteJwt();
        await removeCache("cachedUserProject")
        // await BestandsaufnahmeModel.deleteAll();
        await Immobilie.deleteAll();
        await store.dispatch('entities/deleteAll')

        await BestandsaufnahmeModel.dispatch("$deleteFromLocal");

        const req = await indexedDB.deleteDatabase("aconApp");
        req.onsuccess = async function () {
            //...

            // await BestandsaufnahmeModel.deleteAll();
            // await Immobilie.deleteAll();
        };
        req.onerror = function () {
            Monitoring.error("Couldn't reset database after logout");
        };
        req.onblocked = function () {
            console.log("Couldn't reset database after logout due to the operation being blocked");
        };

        //logger.info(`User logged out`);
        console.log(`${userInfo} logged out`);
        window.location.reload();
    };


    return {
        login,
        logout,
        signup,
        hasToken,
        loadJwt,
        parseJwt,
        isTokenExpired,
        changePassword
    };
}