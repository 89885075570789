<template>
  <section class="pdf-content">
    <div v-if="detailPages">
      <!--
    * Cover Page (Logo)
    -->

      <pdf-a4-page
        class="logo_page"
        no-margin
        :pdf-key="lang"
      >
        <div style="height: 1123px; width: 801px; max-height: 1236px; max-width: 801px; ">
          <!-- old style: needed when no spacing between pdf pages is required <img

            src="../../../../public/assets/img/pdf/initial-screen.jpg"
            alt="Deckblatt mit Acon-Logo"
            style="height: 1236px; width: 801px; max-height: 1236px; max-width: 801px;"
          /> -->
          <img
            src="../../../../public/assets/img/pdf/initial-screen.jpg"
            alt="Deckblatt mit Acon-Logo"
            style="height: 1123px; width: 801px; max-height: 1236px; max-width: 801px;"
          >
        </div>

        <div class="landing_page">
          <!-- <img
            src="../../../../public/assets/img/svg/acon_logo_white.svg"
            alt="acon_text"
            class="acon_logo_white m-4 mt-8 lg:m-16"
          /> -->
          <div class="title-container">
            <div
              class="landing_text_header text-5xl md:text-7xl"
              data-cy="pdf-coverpage-header"
            >
              ACON 360°
            </div>
            <div class="text-4xl">
              Inventory_Heizzentrale
            </div>
            <div class="mt-2 text-xl flex">
              <span class="mt-0 w-40">Objekt:</span>
              <span
                class="mt-0"
                data-cy="pdf-coverpage-street"
              >{{
                immobilie.strasse
              }}</span>
            </div>
            <div class="text-xl flex">
              <span class="mt-0 w-40" />
              <span
                class="mt-0"
                data-cy="pdf-coverpage-city"
              >
                {{ immobilie.plz }} {{ immobilie.stadt }}
              </span>
            </div>
            <div class="text-xl flex mt-2">
              <span class="mt-0 w-40">Berichtnummer:</span>
              <span
                class="mt-0"
                data-cy="pdf-coverpage-begehungsdatum"
              >
                {{ moment(ba.begehungsdatum).format("MM-YYYY") }}
              </span>
            </div>
            <!-- <div class="text-xl flex">
              <span class="mt-0 w-40">Reihenfolge:</span>
              <span v-if="ba.reihenfolge" class="mt-0">
                {{ ba.reihenfolge }}
              </span>
            </div> -->
          </div>
        </div>
      </pdf-a4-page>

      <!-- Overview Page -->
      <PdfHzbaOverviewPage
        v-if="tableOfContentMSEData"
        :lang="lang"
        :ba="ba"
        :immobilie="immobilie"
        :table-of-content-m-s-e-data="tableOfContentMSEData"
      />
      <!--
     * Generic Content Pages
     -->
      <pdf-a4-page
        v-for="(detailPage, key) in detailPages"
        :key="key"
        :pdf-key="lang"
        data-cy="generic-content-page"
      >
        <pdf-page-header
          :immobilie="immobilie"
          :hzba="ba"
          :tech-data="tableOfContentFragenblocks"
          :page="pagecounterStartDetailPage + key"
          :max-pages="pagecounterTotalPages"
          :active="detailPage.fragenblock?.getTitle()"
          :lang="lang"
        />

        <h2
          class="mt-2 mb-0 text-base font-bold"
          data-cy="genericContent-header"
        >
          {{
            detailPage.fragenblock.getTitle(lang) +
              (detailPage.titleSuffix || "")
          }}
        </h2>

        <PdfMSEDataTable
          v-if="detailPage.fragenblockMSERows"
          :data="detailPage.fragenblockMSERows"
          :show-title="true"
          :value-title="null"
          :lang="lang"
        />

        <!-- <h2
          v-if="detailPage?.freieMaengelsMSERows?.length > 0"
          class="mt-2 mb-0 text-base font-bold"
        >
          Zusätzliche Mängel
        </h2> -->
        <PdfMSEDataTable
          v-if="detailPage.freieMaengelsMSERows?.length > 0"
          :data="detailPage.freieMaengelsMSERows"
          :max-shown-items="detailPage.freieMaengelsMaxShownItems"
          :show-title="false"
          :short-title="true"
          :hide-mangel-anzahl="true"
          :value-title="null"
          :lang="lang"
        >
          <div
            class="text-xxs"
            data-cy="zusaetzliche-maengel"
          >
            <span
              v-if="
                detailPage.freieMaengelsMSERows.length -
                  detailPage.freieMaengelsMaxShownItems ===
                  1
              "
            >
              1 weiterer Mangel ist in der Übersicht der Mängel aufgeführt
            </span>
            <span
              v-if="
                detailPage.freieMaengelsMSERows.length -
                  detailPage.freieMaengelsMaxShownItems >
                  1
              "
            >
              {{
                detailPage.freieMaengelsMSERows.length -
                  detailPage.freieMaengelsMaxShownItems
              }}
              weitere Mängel sind in der Übersicht der Mängel aufgeführt
            </span>
          </div>
        </PdfMSEDataTable>

        <div class="mt-2">
          <pdf-image-row
            v-if="detailPage?.title === 'Nachspeisung' && detailPage.bilder"
            :image-objects="detailPage.bilder"
            hide-titles
            :max-images="3"
          />

          <pdf-image-row
            v-if="detailPage?.title !== 'Nachspeisung' && detailPage.bilder"
            :image-objects="detailPage.bilder"
            hide-titles
          />
        </div>
        <div
          v-if="key === 0"
          data-cy="heizzentrale-zugang"
        >
          <h2 class="mt-2 mb-0">
            Zugang
          </h2>
          <div class="text-xs my-0 overflow-ellipsis">
            {{
              useIdentifierFrageFinder(
                ba,
                "hzba.heizzentrale-tab.allgemein-group.allgemein-modal.allgemein.zugang"
              )?.getCurrentInput() || "-"
            }}
          </div>
        </div>
        <div data-cy="contentpage-fazit">
          <h2 class="mt-2 mb-0">
            {{ t("hzba.pdf.fazit", 1, { locale: lang }) }}
          </h2>
          <p class="text-xs my-0">
            {{ detailPage.zusammenfassung ? detailPage.zusammenfassung : "-" }}
          </p>
        </div>

        <pdf-data-section-summary
          :title="detailPage.fragenblock?.getTitle(lang)"
          :data="detailPage.fragenblock?.maengelReport()?.getMSESummary()"
        />

        <pdf-color-codes
          class="ml-1"
          :lang="lang"
        />
      </pdf-a4-page>


      <!--
      * Mangelübersicht
      -->
      <PdfHzbaMaengelPage
        v-model:mangelPages="pagecounterMaengel"
        :pages-options="{
          currentStartPage: pagecounterStartMaengelPage,
          maxPages: pagecounterTotalPages,
        }"
        :lang="lang"
        :ba="ba"
        :immobilie="immobilie"
        :tech-data="tableOfContentFragenblocks"
      />

      <!--
      * Custom Input / Weitere Hinweise
      -->
      <pdf-custom-input-page 
        v-model:customInputPageCount="pagecounterCustomInput"
        :immobilie="immobilie"
        :ba="ba"
        :tech-data="tableOfContentFragenblocks"
        :lang="lang"
        :custom-inputs="customInputs"
        :pages-options="{
          currentStartPage: pagecounterStartCustomInput,
          maxPages: pagecounterTotalPages,
        }"
      />


      <!-- * Appointment table -->
      <pdf-a4-page :pdf-key="lang">
        <pdf-page-header
          :immobilie="immobilie"
          :hzba="ba"
          :tech-data="[]"
          :page="pagecounterStartAppointments"
          :max-pages="pagecounterTotalPages"
          :lang="lang"
          :show-chapters="false"
        />
        <br>

        <!-- <PdfAppointmentTable
          v-if="appointments?.length > 0"
          :appointments="appointments"
          :lang="lang"
        /> -->

        <h3
          class="mt-8 text-base"
          data-cy="final-page-header"
        >
          Digitale Dokumentationsdaten
        </h3>

        <img
          data-cy="usb-card"
          src="../../../../public/assets/img/pdf/usb-card-pdf.png"
          style="height: 300px; width: 440px"
        >

        <h3
          class="mt-8 mb-0 text-base"
          data-cy="hinweis-title"
        >
          Hinweis
        </h3>
        <div
          class="flex flex-col"
          data-cy="hinweis-text"
        >
          <p class="mt-2 ml-0 text-xxs text-justify">
            Den dargelegten Ausführungen und Bewertungen liegt die Beauftragung
            einer Heizzentralen-Bestandsaufnahme inkl. Funktionsprüfung
            zugrunde. Im Rahmen der Heizzentralen-Bestandsaufnahme inkl.
            Funktionsprüfung erfolgt eine Vor-Ort-Bestandsaufnahme durch zwei
            technische Projektmanager inkl. Daten- sowie Mängelerfassung wie
            folgt: Visuelle Bestandsaufnahme sowie funktionale Zustandserfassung
            und -überprüfung aller Komponenten der Heizzentrale. Bewertung der
            Anlage hinsichtlich Anlagenbetrieb (Auslegung, Dimensionierung,
            Effizienz und Wartungszustand) anhand der ermittelten Einstell- und
            Messwerte sowie unter Berücksichtigung gesetzlicher Vorschriften und
            sicherheitstechnischer Empfehlungen. Die Bewertung der Anlage
            erfolgt über die systematische Vergabe von Maluspunkten je erfasstem
            Mangel, deren Summe als Indikator für den Gesamtzustand der
            Heizzentrale dient. Darüber hinausgehend ist dem zuständigen
            Projektleiter die Möglichkeit gegeben, durch eine übergeordnete
            Vergabe von Maluspunkten (negativ sowie positiv) Korrekturen bzw.
            Anpassungen vorzunehmen.
          </p>
          <div class="ml-0 text-xxs text-justify flex flex-wrap">
            <p style="margin: 4px 0px 0px 0px">
              Die Klassifizierung der einzelnen Mängel erfolgt nach Art
            </p>
            <!-- <span> -->
            <span class="flex items-center ml-1">
              <PdfChip
                color="green"
                pdf-mode
              />
              {{ t("hzba.pdf.keineMaengel", 1, { locale: lang }) }},
              &nbsp;&nbsp;
            </span>
            <span class="flex items-center mx-0">
              <PdfChip
                color="blue"
                pdf-mode
              />
              {{ t("hzba.pdf.keineRelevantenMaengel", 1, { locale: lang }) }},
              &nbsp;&nbsp;
            </span>

            <span class="flex items-center mx-0">
              <PdfChip
                color="yellow"
                pdf-mode
              />
              {{
                t("hzba.pdf.sicherheitsrelevanteMaengel", 1, {
                  locale: lang,
                })
              }}, &nbsp;&nbsp;
            </span>
            <span class="flex items-center mx-0">
              <PdfChip
                color="red"
                pdf-mode
              />
              {{
                t("hzba.pdf.versorgungsrelevanteMaengel", 1, {
                  locale: lang,
                })
              }}&nbsp;&nbsp;
            </span>
            <!-- </span> -->
            <p
              class="text-xxs text-justify"
              style="margin: 0px 0px 0px 0px"
            >
              sowie relevantem Gewerk. Auf Seite 1 des Dokumentes wird die
              Maluspunkte-Gesamtsumme sowie die Maluspunkte-
            </p>
            <p
              class="text-xxs text-justify"
              style="margin: 0px 0px 0px 0px"
            >
              <!-- Versorgungsrelevante Mängel -->
              Summe je Unterkategorie (Heizzentrale, Wäremeerzeuger,
              Anlagenkomponenten, Nachspeisung) aufgezeigt. Auf den
              darauffolgenden Seiten sind die erfassten Daten und Mängel je
              Unterkategorie (Heizzentrale, Wäremeerzeuger, Anlagenkomponenten,
              Nachspeisung) detailliert ausgewiesen. Danach werden alle Mängel
              gegebenenfalls mit Beschreibung und Bilddokumentation tabellarisch
              aufgelistet.
            </p>
          </div>
        </div>

        <div
          class="mt-8"
          data-cy="ansprechpartner"
        >
          {{ t("hzba.pdf.ansprechpartner", 1, { locale: lang }) }}

          <div class="flex mt-4">
            <div
              class="flex flex-col w-1/3"
              data-cy="signatur-1"
            >
              <div style="height: 70px">
                <img
                  v-if="ba.unterschrifts.length > 0"
                  :src="getPhotoUrl(ba.unterschrifts[0].bild)"
                  class="object-cover mt-1"
                  style="height: 70px"
                >
              </div>
              <div class="border-top-dotted-1 ml-4">
                <div class="text-xxs mt-2">
                  {{
                    ba.unterschrifts.length > 0 && ba.unterschrifts[0]?.position
                      ? ba.unterschrifts[0].position
                      : "Projektmanager"
                  }}:
                  {{
                    ba.unterschrifts.length > 0 && ba.unterschrifts[0]?.name
                      ? ba.unterschrifts[0].name
                      : "_________"
                  }}
                  am {{ moment(ba.begehungsdatum).format("DD.MM.YYYY") }}<br>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col w-1/3"
              data-cy="signatur-2"
            >
              <div style="height: 70px">
                <img
                  v-if="ba.unterschrifts.length > 1"
                  :src="getPhotoUrl(ba.unterschrifts[1].bild)"
                  class="object-cover mt-1"
                  style="height: 70px"
                >
              </div>
              <div class="border-top-dotted-1 ml-4">
                <div class="text-xxs mt-2">
                  {{
                    ba.unterschrifts.length > 1 && ba.unterschrifts[1]?.position
                      ? ba.unterschrifts[1]?.position
                      : "Projektmanager"
                  }}:
                  {{
                    ba.unterschrifts.length > 1 && ba.unterschrifts[1]?.name
                      ? ba.unterschrifts[1]?.name
                      : "_________"
                  }}
                  am {{ moment(ba.begehungsdatum).format("DD.MM.YYYY") }}<br>
                </div>
              </div>
            </div>
            <div
              class="flex flex-col w-1/3"
              data-cy="signatur-3"
            >
              <div style="height: 70px">
                <img
                  v-if="ba.technProjektsteuerungUnterschrift"
                  :src="getPhotoUrl(ba.technProjektsteuerungUnterschrift)"
                  class="object-cover mt-1"
                  style="height: 70px"
                >
              </div>
              <div class="border-top-dotted-1 ml-4">
                <div class="mt-2">
                  <p class="text-xxs my-0">
                    freigegeben am
                    <span>
                      {{
                        ba.freigabeDatum
                          ? moment(ba.freigabeDatum).format("DD.MM.YYYY")
                          : "_________"
                      }}
                    </span>
                  </p>
                  <p class="text-xxs my-0">
                    Technische Projektsteuerung
                    <span v-if="ba.technProjektsteuerungName" />
                    {{ ba.technProjektsteuerungName }}
                  </p>
                  <p class="text-xxs my-0">
                    ACON Energie Management GmbH
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pdf-a4-page>
    </div>
  </section>
</template>

<script lang="ts">
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from "moment";
import { useI18n } from "vue-i18n";

import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import Immobilie from "@/models/immobilie.model";
import { computed, defineComponent, PropType, ref } from "vue";
// import PdfHzbaCustomInputPage from "@components/Pdf/Components/PdfHzbaCustomInputPage.vue";
import PdfHzbaMaengelPage from "@/components/Pdf/Bericht_Heizung/PdfHzbaMaengelPage.vue";
import PdfHzbaOverviewPage from "@/components/Pdf/Bericht_Heizung/PdfHzbaOverviewPage.vue";
import useHzbaPdfReport from "@/components/Pdf/Bericht_Heizung/useHzbaPdfReport";
import PdfChip from "@/components/Pdf/Components/PdfChip";
import PdfCustomInputPage from "@/components/Pdf/Components/PdfCustomInputPage.vue";
import PdfFMDataTable from "@/components/Pdf/Components/PdfFMDataTable.vue";
import PdfMSEDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfPageBreak from "@/components/Pdf/Components/PdfPageBreak.vue";
import {
useIdentifierFrageFinder,
useIdentifierFragenblockFinder
} from "@/composables/Bestandsaufnahme/useIdentifierFinder";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
ExtendedMSERow
} from "@/models/ba/interfaces/IGenericPdfDataRow";
import PdfGeneralInfo from "./PdfHzbaOverviewGeneralInfo.vue";

export default defineComponent({
  name: "PdfHzbaTemplate",
  components: {
    PdfCustomInputPage,
    PdfHzbaMaengelPage,
    PdfHzbaOverviewPage,
    PdfPageBreak,
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    PdfMSEDataTable,
    PdfFMDataTable,
    PdfColorCodes,
    PdfMSEValues,
    PdfImageRow,
    PdfDataSectionSummary,
    PdfA4Page,
    PdfPageHeader,
    PdfAconLogo,
    PdfGeneralInfo,
    PdfChip,
    // PdfHzbaCustomInputPage
    // PdfMangelsOverview
  },
  props: {
    ba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true,
    },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });

    const useHzbaPdf = useHzbaPdfReport(props.lang);
    const uUser = useUser();

    const pagecounterFirstPages = 1;
    const pagecounterMaengel = ref(0);
    const pagecounterCustomInput = ref(0);
    const pagecounterDetailPages = computed(() => {
      return useHzbaPdf.detailPages.value?.length || 0;
    });
    const pagecounterAppointments = 1;
    const pagecounterTotalPages = computed(() => {
      return (
          pagecounterFirstPages +
          pagecounterDetailPages.value +
          pagecounterMaengel.value +
          pagecounterCustomInput.value +
          pagecounterAppointments
      );
    });

    const pagecounterStartDetailPage = computed(() => {
      return pagecounterFirstPages + 1;
    });
    const pagecounterStartMaengelPage = computed(() => {
      return pagecounterFirstPages + pagecounterDetailPages.value + 1;
    });
    const pagecounterStartCustomInput = computed(() => {
      return pagecounterStartMaengelPage.value + pagecounterMaengel.value;
    })
    const pagecounterStartAppointments = computed(() => {
      return (
          pagecounterFirstPages +
          pagecounterDetailPages.value +
          pagecounterMaengel.value +
          pagecounterCustomInput.value +
          1
      );
    });

    const tableOfContentMSEData = computed<ExtendedMSERow[] | undefined>(() => {
      const overviewTable = useHzbaPdf.tableOfContentFragenblocks.value
          // .filter(el => el.identifier !== 'uebersicht-tab')
          .map(
          (el) => {
            let page = pagecounterStartDetailPage.value;

            const waermeerzeugerPageAdd =
                useHzbaPdf.waermeerzeugers.value?.length || 0;
            const unterstationPageAdd = useHzbaPdf.unterstation.value ? 1 : 0;
            const anlagenKomponentenPageAdd = useHzbaPdf.anlagenKomponenten.value
                ? 1
                : 0;
            const customInputPageAdd = useHzbaPdf.customInputs.value ? 1 : 0;

            switch (el.identifier) {
              case "heizzentrale-tab":
                page += 0;
                break;
              case "waermeerzeuger-tab":
                page += 1;
                break;
              case "anlagenkomponenten-tab":
                page += waermeerzeugerPageAdd + anlagenKomponentenPageAdd;
                break;
              case "unterstation":
                page +=
                    waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd;
                break;
              case "nachspeisung-tab":
                page +=
                    waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd +
                    1;
                break;
                case "uebersicht-tab":
                waermeerzeugerPageAdd +
                    anlagenKomponentenPageAdd +
                    unterstationPageAdd +
                    customInputPageAdd +
                    1;
                  break;
              default:
                page = 0;
            }

            return el.toPdfDataRow(props.lang, page.toString());
          }
      );

      overviewTable?.push({
        title: "Mangelübersicht",
        description1: pagecounterStartMaengelPage.value,
        description2: undefined,
      });

      return overviewTable;
    });

    return {
      getPhotoUrl,
      tableOfContentMSEData,
      pagecounterMaengel,
      pagecounterTotalPages,
      appointments: useHzbaPdf.termine,
      moment,
      tableOfContentFragenblocks: useHzbaPdf.tableOfContentFragenblocks,
      detailPages: useHzbaPdf.detailPages,
      customInputs: useHzbaPdf.customInputs,
      // getCustomInputBilder: useHzbaPdf.getCustomInputBilder,
      user: uUser.user,
      useIdentifierFragenblockFinder,
      useIdentifierFrageFinder,
      t,
      pagecounterStartDetailPage,
      pagecounterStartMaengelPage,
      pagecounterStartAppointments,
      pagecounterStartCustomInput,
      pagecounterCustomInput,
    };
  },
});
</script>

<style scoped>
.pdf-content {
  width: 800px;
  margin: 0 auto;
}
h2 {
  font-size: 14px;
  font-weight: 900;
}
/*h2 {*/
/*  font-size: 1.1rem;*/
/*  font-weight: bold;*/
/*  margin-top: 30px;*/
/*  margin-bottom: 15px;*/
/*}*/
.fazit {
  font-size: 1.25em;
}
/*img {*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*  object-fit: cover;*/
/*}*/

.logo_page {
  padding: 0;
  margin: 0;
}

.acon_logo_white {
  position: absolute;
  top: 10px;
  width: 210px;
  height: 70px;
}

.title-container {
  position: absolute;
  top: 200px;
  color: var(--white100);
  margin: 0;
  padding: 4rem;
  width: 800px;
}
.overflow-ellipsis {
  white-space: wrap;
  width: 100%;
  max-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
}
td {
  vertical-align: top;
  font-size: 12px;
  padding: 4px 4px 4px 0px;
}
tr {
  border: none;
}

/* .container {
  position: absolute;
} */
/* Not working */
/* .page-break {
  display: block;
  page-break-after: always;
}
.pos-rel {
  position: relative;
} */
</style>
