<template>
  <section
    class="pdf-content"
  >
    <pdf-a4-page :pdf-key="lang">
      <pdf-acon-logo />

      <h1 class="pdf-title">
        {{ t("immobilie.pdf.kopfzeile", 1, { locale: lang}) }}
      </h1>

      <pdf-general-data
        :hzba="hzba"
        :immobilie="immobilie"
        :lang="lang"
      />

      <div class="mt-4 border-top-1">
        <h2 class="my-8">
          {{ t("immobilie.pdf.managementSummary", 1, { locale: lang}) }}
        </h2>

        <generic-pdf-mgt-summary-table
          :data="mangelDummyData"
          :lang="lang"
        />
      </div>

      <div class="my-8">
        <p class="text-xs">
          Folgende technische Anlagen wurden im Rahmen einer visuellen Bestandserfassung der Technikzentralen vorgefunden:
        </p>

        <p class="mb-0 font-bold text-xs">
          Abwasser- Wasser- Gasanlagen Mischentwässerung von Abwasse
        </p>
        <p class="my-1 text-xs">
          Mischentwässerung von Abwasser und Regenwasser inkl. Rückstauschutz über 5 Hebeanlage. Trinkwasserversorgung über 2 Bereiche. Eine Niederdruckzone und ab dem 2.OG eine Hochdruckzone über eine Druckerhöhungsanlage. Es existiert eine Enthärtungsanlage, Sprinklerwasser wird über eine Trennstation bereitgestellt. Keine Gasanlagen.
        </p>

        <p class="mb-0 font-bold text-xs">
          Titel
        </p>
        <p class="my-1 text-xs">
          Beschreibung
        </p>

        <p class="mb-0 font-bold text-xs">
          Titel
        </p>
        <p class="my-1 text-xs">
          Beschreibung
        </p>
      </div>

      <div class="my-16 border-top-1">
        <div class="text-xs my-8 flex flex-wrap">
          <div class="flex flex-nowrap items-center">
            <span class="font-bold text-center">Mangelkategorien:</span>
          </div>
          <div class="flex flex-nowrap items-center mx-2">
            <PdfChip
              color="green"
              pdf-mode
            />
            Keine Mängel
          </div>

          <div class="flex flex-nowrap items-center mx-2">
            <PdfChip
              color="blue"
              pdf-mode
            />
            Keine relevanten Mängel
          </div>

          <div class="flex flex-nowrap items-center mx-2">
            <PdfChip
              color="yellow"
              pdf-mode
            />
            Sicherheitsrelevante Mängel
          </div>

          <div class="flex flex-nowrap items-center">
            <PdfChip
              color="red"
              pdf-mode
            />
            Versorgungsrelevante Mängel
          </div>
        </div>
      </div>

      <!-- <div>
        <div
          class="flex mt-4 border-top-1 border-bottom-1"
          style="max-height: 210px"
        >
          <pdf-image-row
              v-if="heizraumDataImages"
            :image-objects="heizraumDataImages"
            hide-titles
            stretch
          />

          <div class="m-2 flex-shrink-0">
            <h3 class="mt-0 text-base ml-2">
              {{ t("hzba.pdf.bestandsaufnahmeGesamt", 1, { locale: lang}) }}
            </h3>
            <pdf-m-s-e-values
              :data="pageOverviewData.getSummary()"
              :lang="lang"
            />

            <div class="mt-2 ml-2 text-xs">
              {{ t("hzba.pdf.farbcodierung", 1, { locale: lang}) }}
            </div>
            <pdf-color-codes
            class="ml-1"
            :lang="lang"
            />
          </div>
        </div>
      </div> -->

      <!-- <div class="mt-2 border-bottom-1 pb-8 h-12">
        {{ t("hzba.pdf.fazit", 1, { locale: lang}) }} {{ hzba.fazit }}
      </div> -->

      <!-- <div class="mt-4">
        {{ t("hzba.pdf.ansprechpartner", 1, { locale: lang}) }}

        <div class="flex text-xs mt-12">
          <div class="w-full border-top-dotted-1">
            <div class="text-sm">
             {{ t("hzba.pdf.ansprechpartnerName", 1, { locale: lang}) }}
            </div>
            <div>{{ t("hzba.pdf.ansprechpartnerPosition", 1, { locale: lang}) }}</div>
          </div>

          <div class="w-full border-top-dotted-1 ml-4">
            <div class="text-sm">
              {{ t("hzba.pdf.iV", 1, { locale: lang}) }} {{ user.name }}
            </div>
            <div>
              {{ user.position }} <br />
              {{ user.jobbeschreibung }}
            </div>
          </div>
        </div>
      </div> -->
    </pdf-a4-page>

    <!-- <pdf-a4-page v-for="index in techData()" :key="index" :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="genericHzbaFromStore"
        :techData="techData()"
        :page="techData().indexOf(index)+2"
        :max-pages="maxPages"
        :active="index.getTitle()"
        :lang="lang"
      />

      <h1 class="mt-4">
        H1
      </h1>
      <GenericPdfDataTable
        :data="index.fragenblocks"
        :value-title="null"
        :lang="lang"
      />
    </pdf-a4-page> -->

    <!-- <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="2"
        :max-pages="maxPages"
        active="heizraum"
        :lang="lang"
      />

      <h1 class="mt-4">
        1. {{ t("hzba.pdf.heizraumTitel", 1, { locale: lang}) }}
      </h1>

      <pdf-data-table
        :data="pageHeizzentraleData.getData()"
        :value-title="null"
        :lang="lang"
      />

      <pdf-image-row
          v-if="heizraumDataImages"
        :image-objects="heizraumDataImages"
        class="mt-4"
      />

      <h3 class="text-sm mb-0 mt-4">
        {{ t("hzba.pdf.zusammenfassung", 1, { locale: lang}) }}
      </h3>
      <div class="text-xs">
        {{ hzba.heizraum.zusammenfassung }}
      </div>

      <pdf-data-section-summary
        :title="t('hzba.pdf.heizraumGesamt', 1, { locale: lang})"
        :data="pageHeizzentraleData.getSummary()"
      />

    </pdf-a4-page>

    <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="3"
        :max-pages="maxPages"
        active="waermeerzeugung"
      />

      <h1 class="mt-4">
        2. {{ t("hzba.pdf.waermeerzeugungTitel", 1, { locale: lang}) }}
      </h1>

      <pdf-data-table
        :data="pageWaermeerzeugerData.getData()"
        :value-title="null"
      />

      <pdf-data-section-summary
        :title="t('hzba.pdf.waermeerzeugungGesamt', 1, { locale: lang})"
        :data="pageWaermeerzeugerData.getSummary()"
      />
    </pdf-a4-page>

    <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="3"
        :max-pages="maxPages"
        active="unterstation"
      />

      <h1 class="mt-4">
        3. {{ t("hzba.pdf.unterstationTitel", 1, { locale: lang}) }}
      </h1>

      <pdf-data-table
        :data="pageUnterstationData.getData()"
        :value-title="null"
        :lang="lang"
      />

      <pdf-data-section-summary
        :title="t('hzba.pdf.unterstationGesamt', 1, { locale: lang})"
        :data="pageUnterstationData.getSummary()"
      />
    </pdf-a4-page>

    <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="3"
        :max-pages="maxPages"
        active="nachspeisung"
      />

      <h1 class="mt-4">
        4. {{ t("hzba.pdf.nachspeisungTitel", 1, { locale: lang}) }}
      </h1>

      <pdf-data-table
        :data="pageNachspeisungData.getData()"
        :value-title="null"
        :lang="lang"
      />

      <pdf-data-section-summary
        :title="t('hzba.pdf.nachspeisungGesamt', 1, { locale: lang})"
        :data="pageNachspeisungData.getSummary()"
      />
    </pdf-a4-page>


    <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="7"
        :max-pages="maxPages"
        :show-chapters="false"
      />

      <h1 class="mt-8">
        5. {{ t("hzba.pdf.mangelUebersicht", 1, { locale: lang}) }}
      </h1>

      <pdf-data-table
        :data="mangelData"
        :value-title="t('hzba.pdf.gewerk', 1, { locale: lang})"
        :lang="lang"
      />
    </pdf-a4-page>

    <pdf-a4-page :pdf-key="lang">
      <pdf-page-header
        :immobilie="immobilie"
        :hzba="hzba"
        :page="7"
        :max-pages="maxPages"
        :show-chapters="false"
      />

      <h1 class="mt-8">
        6. {{ t("hzba.pdf.termine", 1, { locale: lang}) }}
      </h1>

      <PdfAppointmentTable
        :appointments="appointments"
        :lang="lang"
      />

      <h1 class="mt-8">
        7. {{ t("hzba.pdf.dokumentation", 1, { locale: lang}) }}
      </h1>

      <div style="height: 150px; width: 300px; border: 1px dotted #343434" />
    </pdf-a4-page> -->
  </section>
</template>

<script lang="ts">
import { getPhotoUrl } from "@/utilities/get-media-url";
import moment from "moment";
import { useI18n } from 'vue-i18n';

import PdfA4Page from "@/components/Pdf/Components/PdfA4Page.vue";
import PdfAconLogo from "@/components/Pdf/Components/PdfAconLogo.vue";
import PdfAppointmentTable from "@/components/Pdf/Components/PdfAppointmentTable.vue";
import PdfChip from "@/components/Pdf/Components/PdfChip.vue";
import PdfColorCodes from "@/components/Pdf/Components/PdfColorCodes.vue";
import PdfDataSectionSummary from "@/components/Pdf/Components/PdfDataSectionSummary.vue";
import PdfDataTable from "@/components/Pdf/Components/PdfDataTable.vue";
import PdfGeneralData from "@/components/Pdf/Components/PdfGeneralData.vue";
import PdfImageRow from "@/components/Pdf/Components/PdfImageRow.vue";
import GenericPdfDataTable from "@/components/Pdf/Components/PdfMSEDataTable.vue";
import PdfMSEValues from "@/components/Pdf/Components/PdfMSEValues.vue";
import PdfPageHeader from "@/components/Pdf/Components/PdfPageHeader.vue";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { Fragenblock } from "@/models/ba/Fragenblock";
import Immobilie from "@/models/immobilie.model";
import { computed, defineComponent, PropType } from "vue";
import GenericPdfMgtSummaryTable from '../Components/GenericPdfMgtSummaryTable.vue';



export default defineComponent({
  name: "PdfMgtSummaryTemplate",
  components: {
    PdfAppointmentTable,
    PdfGeneralData,
    PdfDataTable,
    GenericPdfDataTable,
    PdfColorCodes,
    PdfMSEValues, PdfImageRow, PdfDataSectionSummary, PdfA4Page, PdfPageHeader, PdfAconLogo,
    GenericPdfMgtSummaryTable,
    PdfChip,
},
  props: {
    hzba: {
      type: Object as PropType<Bestandsaufnahme>,
      required: true
    },
    // genericHzba: {
    //   type: Object as PropType<Bestandsaufnahme>,
    //   required: true
    // },
    immobilie: {
      type: Object as PropType<Immobilie>,
      required: true
    },
    lang: {
      type: String,
      default: 'de'
    }
  },
  setup(props: any) {
    const store = useStore();
    const { t } = useI18n({ useScope: 'global' })
    const maxPages = 7;

    const uUser = useUser();
    const genericHzbaFromStore = computed<Bestandsaufnahme | undefined>(() => {
      return store.state.currentHzba.currentBa;
    });

    const fragenblocks = computed<Fragenblock[] | undefined>(() => {
      return genericHzbaFromStore.value?.fragenblocks
    })

    function techData(){
      if(!fragenblocks.value) return
      const copyOfFragenBlocks = [...fragenblocks.value]
      copyOfFragenBlocks.shift()
    return copyOfFragenBlocks
    }

    return {
      // hzba,
      // immobilie,
      getPhotoUrl,
      techData,
      fragenblocks,
      maxPages,
      moment,
      user: uUser.user,
      genericHzbaFromStore,
      t
    }
  }
})
</script>

<style scoped>

.pdf-content {
  width: 800px;
  margin: 0 auto;
}
</style>
