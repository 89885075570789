<template>
  <div class="propertyNotes">
    <div class="formContainer">
      <ion-item
        class="mb-8"
        lines="none"
      >
        <ion-textarea
          v-model="currentNote.content"
          :autoGrow="true"
          :placeholder="t('propertyNotes.enterNotePlaceholder')"
          :value="currentNote.content"
        />
        <div class="flex items-end pb-4">
          <AButton
            :disabled="currentNote.content === '' ? true : false"
            :btn-primary="true"
            @click="postNote"
          >
            {{ t('propertyNotes.sendButton') }}
          </AButton>
        </div>
      </ion-item>
    </div>

    <ion-list
      lines="full"
      class="listContainer"
    >
      <ion-item
        v-for="(item) in displayedNotes"
        :key="item.id"
        class="listedNote"
      >
        <ion-textarea
          :readonly="true"
          :value="item.content"
        >
          <ion-label
            position="stacked"
            class="font-bold"
          >{{ new Date(item.createdAt).toLocaleDateString('de-DE') }}</ion-label>
          <ion-label
            position="stacked"
            class="font-bold"
          >{{ item.creator }}</ion-label>
        </ion-textarea>
      </ion-item>
    </ion-list>

    <div class="text-center my-8">
      <AButton
        :btn-primary="true"
        class="px-8 py-3 text-md"
        v-if="canLoadMore"
        @click="loadMore"
      >
        {{ t('propertyNotes.loadMoreButton') }}
      </AButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonTextarea, IonItem, IonList, IonLabel } from '@ionic/vue';
import { useI18n } from "vue-i18n";
//Composables
import useUser from "@/composables/useUser";
// Components 
import AButton from "@/components/Base/AButton";
import apiClient, { strapiApiUrl } from "@/api/api";

interface Note {
  id: string,
  content: string,
  creator: string,
  createdAt: string
}

export default defineComponent({
  name: 'PropertyNotes',
  components: {
    IonTextarea,
    IonItem,
    IonList,
    IonLabel,
    AButton
  },
  props: {
    notes: {
      type: Array,
      required: true
    },
    propertyId: {
      type: Number,
      required: true
    }
  },
  watch: {
    notes: {
      handler() {
        this.localNotes = this.notes
      },
      immediate: true,
      deep: true
    },
  },
  data() {
    return {
      currentNote: {
        creator: '',
        content: ''
      },
      localNotes: [] as Array<Note>,
      notesToDisplay: 3,
    }
  },
  setup() {
    const { user } = useUser()
    const { t } = useI18n({ useScope: 'global' })

    return {
      user,
      t
    }
  },
  computed: {
    displayedNotes(): Note[] {
      const notes = this.localNotes
      //@ts-ignore
      notes.sort((a: any, b: any) => new Date(b.createdAt) - new Date(a.createdAt))
      return notes.slice(0, this.notesToDisplay)
    },
    canLoadMore(): boolean {
      return this.notesToDisplay < this.notes.length
    }
  },
  created() {
    this.localNotes = this.notes
  },
  methods: {
    loadMore() {
      this.notesToDisplay += 3
    },

    async postNote() {
      try {
        this.currentNote.creator = this.user.username
        const payload = {
          content: this.currentNote.content,
          creator: this.currentNote.creator,
          immobilie: {
            connect: [
              {
                id: this.propertyId,
              }
            ]
          }
        }
        await apiClient.post(`${strapiApiUrl}/notes`, { data: payload });
        this.currentNote.createdAt = new Date()
        this.localNotes = [this.currentNote].concat(this.localNotes)
        this.notesToDisplay += 1;
        this.currentNote = {
          content: '',
          creator: ''
        }
      } catch (err: any) {
        console.log(err)
      }
    }
  }
});

</script>

<style lang="scss">
.propertyNotes {
  .listContainer {
    background: var(--white100);
    max-height: 500px;
    overflow-y: scroll;

    .listedNote {
      cursor: pointer;
    }

    ion-textarea textarea {
      --padding-bottom: 0;
      --padding-top: 0;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .formContainer {
    ion-item.item-has-focus::part(native) {
      border-bottom-color: var(--primary);
      border-bottom-style: solid;
      border-bottom-width: 4px;
      caret-color: var(--primary);
    }
  }
}
</style>