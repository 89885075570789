<template>
  <pdf-a4-page no-margin :pdf-key="lang" v-for="(zugaenglichkeit , index) in (splitIntoChunks(zugaenglichkeiten))" :key="index">
    <div class="report-background w-screen h-screen">
      <page-header :ba="ba" :immobilie="immobilie" :Title="'Zugänglichkeit'"/>
      <div class="flex flex-col justify-between">
        <div v-if="index === 0">
          <span class="pageHeader flex justify-between"  style="margin-top: 5px;">Schwierigkeiten bei der Zugänglichkeit</span>
          <hr class="trenner-m" />
        </div>
        <div :class="[index === 0  ? 'content' : 'content-long']">
          <div class="title-container">
            <span v-if="zugaenglichkeit?.[0] == null && index == 0" class="smallHeader pt-10">Es sind keine Schwierigkeiten bei der Zugänglichkeit vorhanden</span><!--not in use for page saving purposes-->
            <div v-else v-for="(zugang, index2) in zugaenglichkeit" :key="index2">
              <div class="fragenBlock">
                <div class="smallHeader" data-cy="accessibility-title">
                  {{ "Schwierigkeit bei der Zugänglichkeit - " + (index * 3 + 1 + index2) }}
                </div>
                <div class="mt-1 flex">
                  <span class="ml-2 mr-2 mt-1 w-60">Zugänglichkeit:</span>
                  <span class="mt-0 w-80 textBlock" data-cy="pdf-accessibility">
                    {{ zugang.fragenblocks?.[0].frages?.[0].eingabeAuswahlWert }}
                  </span>
                </div>
                <div class="ml-2 mr-2 mt-1 w-60 flex">
                  Beschreibung:
                </div>
                <div class="textBlock" data-cy="pdf-accessibility-description"  v-if="zugang.fragenblocks?.[0].frages?.[1].eingabeText !== ''">
                  {{ zugang.fragenblocks?.[0].frages?.[1].eingabeText }}
                </div>
                <div class="textBlock mt-2" v-else>
                  {{ 'Keine Beschreibung vorhanden' }}
                </div>
                <div class="mt-5 flex">
                  <div class="ml-2 mr-2 mt-3">Fotos:</div>
                  <div class="mt-0" data-cy="pdf-accessibility-photos" v-if="zugang?.fragenblocks?.[0].frages?.[3].eingabeMedien?.[0] != null">
                    <PdfImageRow :imageObjects="getImageObjects(zugang, 3)"/>
                  </div> 
                  <div class="mt-3" data-cy="pdf-accessibility-photos" v-else>
                    Keine Fotos
                  </div>          
                </div>
                <hr class="trenner" v-if="index2 != zugaenglichkeit.length - 1"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <page-bottom/>
    </div>
  </pdf-a4-page>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { Fragenblock } from '@/models/ba/Fragenblock';
  import PdfA4Page from '@/components/Pdf/Components/PdfA4Page.vue';
  import PageHeader from './PageHeader.vue';
  import Immobilie from "../../../models/immobilie.model";
  import Bestandsaufnahme from '@/models/ba/Bestandsaufnahme';
  import PdfImageRow from './NewVationImageRow.vue';
  import PageBottom from './PageBottom.vue';

  export default defineComponent({
    name: 'ReportPage',
    components: {
      PdfA4Page,
      PageHeader,
      PdfImageRow,
      PageBottom,
    },
    props: {
      zugaenglichkeiten: {
        type: Array as PropType<Fragenblock[]>,
        required: true,
      },
      lang: {
        type: String,
        default: 'de',
      },
      ba: {
        type: Object as PropType<Bestandsaufnahme>,
        required: true,
      },
      immobilie: {
        type: Object as PropType<Immobilie>,
        required: true,
      }
    },
    methods: {
      getImageObjects(zugang: any, index : number) {
        return zugang?.fragenblocks?.[0].frages?.[index].eingabeMedien ?? [];
      },
      splitIntoChunks(zugangs: any[]) {
        zugangs = zugangs.slice(1, zugangs.length);
        const result = [];
          
        for (let i = 0; i < zugangs.length; i += 3)
            result.push(zugangs.slice(i, i + 3));
        return result;
      }
    },
    data() {
      return {
        index: 0,
      };
    },
  });
</script>

<style scoped lang="scss">
  * {
    color: black;
    background-color: white;
    font-size: 12px;
  }
  .pdf-content {
    width: 800px;
    margin: 0 auto;
    color: var(--white100);
    height: 1100px;
  }
  .report-background {
    position: absolute;
    top: 50px;
    left: 50px;
    width: 700px;
    height: 1050px;
    color: var(--black100);
    margin-bottom: -20px;
  }
  .pageHeader {
    font-weight: bold;
    font-size: 20px;
    margin-top: -45px;
  }
  .midHeader {
    font-weight: bold;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .smallHeader {
    font-weight: bold;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 10px;
  }
  .content {
    height: 830px;
  }
  .content-long {
    height: 895px; 
  }
  .stackpanel {
    display: flex;
    flex-direction: row;
  }
  .textBlock {
    margin-left: 20px;
    margin-right: 20px;
  }
  .trenner {
    position: sticky;
    border: none;
    height: 1px;
    color: black;
    background: black;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    width: 690px;
  }
  .trenner-l {
    @extend .trenner;
    height: 2px !important;
    margin-left: -20px !important;
    margin-right: -20px !important;
    width: 740px;
  }
  .trenner-m {
    @extend .trenner;
    height: 1.5px !important;
    margin-left: -10px !important;
    margin-right: -10px !important;
    width: 720px;
  }
  .icon {
    margin-right: 20px;
    display: flex;
    padding-top: -20px;
  }
  img {
    border-radius: 5px;
  }
  .fragenBlock {
    height: auto;
    max-height: 340px;
    overflow: hidden;
  }
</style>
