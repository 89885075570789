import { useStore } from "@/composables/useTypedStore";
import { handleOfflineData } from "@/utilities/handle-offline-data";
import { Network } from "@capacitor/network";

export const SCREEN_XL_START = 1400;
export const SCREEN_LG_START = 1024;

export default function useNetworkState() {

    const store = useStore();

    /**
     * Call this function once, e.g. in App.vue
     */
    async function mountMe() {
        const networkStatus = await Network.getStatus();
        store.commit("app/setNetworkState", networkStatus.connected);

        Network.addListener("networkStatusChange", (status: any) => {
            store.commit("app/setNetworkState", status.connected);
            // Following should work after we update the sentry package:
            //Sentry.getGlobalScope().setContext("Network", { status: status.connected ? "Online" : "Offline" })
            handleOfflineData();
        });
    }

    async function unmountMe() {
        await Network.removeAllListeners();
    }

    return {
        mountMe,
        unmountMe,
    }
}