import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "boxed-container p-4 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DebugFragenblock = _resolveComponent("DebugFragenblock")!
  const _component_hzba_group_ctas = _resolveComponent("hzba-group-ctas")!
  const _component_hzba_modal_item_block = _resolveComponent("hzba-modal-item-block")!
  const _component_hzba_separator = _resolveComponent("hzba-separator")!
  const _component_hzba_form = _resolveComponent("hzba-form")!
  const _component_AdditionalMaengel = _resolveComponent("AdditionalMaengel")!
  const _component_hzba_group = _resolveComponent("hzba-group")!
  const _component_IonButton = _resolveComponent("IonButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fragenblocks, (fragenblock, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: fragenblock.uid ? fragenblock.uid : `outer-${index}`
      }, [
        (!fragenblock.config?.isTemplate && fragenblock.isShown())
          ? (_openBlock(), _createBlock(_component_hzba_group, {
              key: 0,
              title: fragenblock.getTitle(),
              suffix: fragenblock.getIndexPosition(_ctx.parentFragenblock),
              "hide-progress": "",
              "data-cy": `group-`+fragenblock.identifier,
              class: "relative",
              foldable: fragenblock.config?.foldable
            }, _createSlots({
              default: _withCtx(() => [
                (_ctx.enabledDevMode)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "absolute top-0 right-2 z-10",
                      onClick: ($event: any) => (_ctx.activeDebug = _ctx.activeDebug === index ? undefined : index)
                    }, " o ", 8, _hoisted_1))
                  : _createCommentVNode("", true),
                (_ctx.enabledDevMode && _ctx.activeDebug === index)
                  ? (_openBlock(), _createBlock(_component_DebugFragenblock, {
                      key: 1,
                      fragenblock: fragenblock,
                      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activeDebug = undefined))
                    }, null, 8, ["fragenblock"]))
                  : _createCommentVNode("", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fragenblock.fragenblocks, (fragenB, i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `${i}`,
                    class: "relative"
                  }, [
                    (!fragenB.config?.isTemplate && !fragenB.isInstanceOfMultiple() && fragenB.isShown())
                      ? (_openBlock(), _createBlock(_component_hzba_modal_item_block, {
                          key: 0,
                          "data-cy": `modal-${fragenB.identifier}`,
                          title: fragenB.getTitle(),
                          progress: fragenB.getProgress(),
                          disabled: !fragenB.isUnlocked() || _ctx.isUpdating,
                          "maengel-amount": fragenB.getFiredMaengel().length,
                          errors: fragenB.errors(),
                          onClickItem: 
              () => _ctx.openHzbaModal(
                'fragenblock',
                { ba: _ctx.ba, fragenblockBase: fragenB, modalDepth: _ctx.modalDepth+1 },
                (data) => _ctx.setDataFromCallback(fragenblock, index, i, data) )
            
                        }, null, 8, ["data-cy", "title", "progress", "disabled", "maengel-amount", "errors", "onClickItem"]))
                      : (fragenB.config?.isTemplate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            _createVNode(_component_hzba_separator, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(fragenB.getTitle()) + " ", 1),
                                (fragenB.config?.minCount || fragenB.config?.maxCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, "("))
                                  : _createCommentVNode("", true),
                                (fragenB.config?.minCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "min. " + _toDisplayString(fragenB.config.minCount), 1))
                                  : _createCommentVNode("", true),
                                (fragenB.config?.minCount && fragenB.config?.maxCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, ", "))
                                  : _createCommentVNode("", true),
                                (fragenB.config?.minCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, "max. " + _toDisplayString(fragenB.config.maxCount), 1))
                                  : _createCommentVNode("", true),
                                (fragenB.config?.minCount || fragenB.config?.maxCount)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_7, ")"))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", null, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fragenblock.getMultipleFragenblockInstances(fragenB.identifier), (multiFragenBlock, multiIndex) => {
                                return (_openBlock(), _createBlock(_component_hzba_modal_item_block, {
                                  key: multiFragenBlock.uid,
                                  "data-cy": `modal-${multiFragenBlock.identifier}-${multiIndex}`,
                                  title: multiFragenBlock.getTitle(),
                                  progress: multiFragenBlock.getProgress(),
                                  suffix: `${(multiIndex + 1)}`,
                                  disabled: !multiFragenBlock.isUnlocked() || _ctx.isUpdating,
                                  "maengel-amount": multiFragenBlock.getFiredMaengel().length,
                                  errors: multiFragenBlock.errors(),
                                  "slide-enabled": "",
                                  onClickItem: 
                  () => _ctx.openHzbaModal(
                    'fragenblock',
                    { ba: _ctx.ba, fragenblockBase: multiFragenBlock, modalDepth: _ctx.modalDepth + 1},
                    (data) => _ctx.setDataFromCallback(fragenblock, index, i, data) )
                ,
                                  onDelete: async () => {
                  await fragenblock.deleteFragenblockInstance(multiFragenBlock);
                  await _ctx.updateSurvey();
                },
                                  onDuplicate: async () => {
                  await fragenblock.duplicateFragenblockInstance(multiFragenBlock);
                  await _ctx.updateSurvey();
                }
                                }, null, 8, ["data-cy", "title", "progress", "suffix", "disabled", "maengel-amount", "errors", "onClickItem", "onDelete", "onDuplicate"]))
                              }), 128))
                            ]),
                            (!_ctx.isReadonly)
                              ? (_openBlock(), _createBlock(_component_hzba_modal_item_block, {
                                  key: 0,
                                  title: fragenB.getTitle() + ' ' + _ctx.t('hzba.buttons.hinzufuegen'),
                                  "data-cy": `modal-${fragenB.identifier}-buttons`,
                                  "add-icon": "",
                                  disabled: _ctx.isUpdating,
                                  onClickItem: async () => {
                await fragenblock.addFragenblockInstance(fragenB);
                await _ctx.updateSurvey();
              }
                                }, null, 8, ["title", "data-cy", "disabled", "onClickItem"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                    (_ctx.enabledDevMode)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: "absolute top-0 right-2 z-10",
                          onClick: ($event: any) => (_ctx.activeDebug = _ctx.activeDebug === `sub-${i}` ? undefined : `sub-${i}`)
                        }, " o ", 8, _hoisted_8))
                      : _createCommentVNode("", true),
                    (_ctx.enabledDevMode && _ctx.activeDebug === `sub-${i}`)
                      ? (_openBlock(), _createBlock(_component_DebugFragenblock, {
                          key: 3,
                          fragenblock: fragenB,
                          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeDebug = undefined))
                        }, null, 8, ["fragenblock"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                _createVNode(_component_hzba_form, {
                  "form-frages": fragenblock.frages,
                  onInputChanged: _ctx.handleFormInputChange
                }, null, 8, ["form-frages", "onInputChanged"]),
                (fragenblock.config?.customMaengel?.enabled)
                  ? (_openBlock(), _createBlock(_component_AdditionalMaengel, {
                      key: 2,
                      path: fragenblock.path,
                      mangels: fragenblock.freieMangels,
                      config: fragenblock.config?.customMaengel,
                      fragenblock: fragenblock
                    }, null, 8, ["path", "mangels", "config", "fragenblock"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, [
              (fragenblock.isInstanceOfMultiple())
                ? {
                    name: "cta",
                    fn: _withCtx(() => [
                      _createVNode(_component_hzba_group_ctas, {
                        "hide-add-button": true,
                        onDelete: async () => { 
              await _ctx.parentFragenblock.deleteFragenblockInstance(fragenblock);
              await _ctx.updateSurvey();
            },
                        onDuplicate: async () => { 
              await _ctx.parentFragenblock.duplicateFragenblockInstance(fragenblock);
              await _ctx.updateSurvey();
            }
                      }, null, 8, ["onDelete", "onDuplicate"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1032, ["title", "suffix", "data-cy", "foldable"]))
          : _createCommentVNode("", true)
      ]))
    }), 128)),
    (!_ctx.isReadonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fragenblocks, (fragenblock, index) => {
            return (_openBlock(), _createElementBlock("div", { key: index }, [
              (fragenblock.config?.isTemplate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_IonButton, {
                      fill: "clear",
                      "data-cy": "button-neuer-waermeerzeuger",
                      class: "normal-case",
                      onClick: async () => { 
              await _ctx.parentFragenblock.addFragenblockInstance(fragenblock);
              await _ctx.updateSurvey();
            } 
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(fragenblock.getTitle() + " " + _ctx.t("hzba.buttons.hinzufuegen").toLowerCase()), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}